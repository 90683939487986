import React, { useState, useContext, useEffect } from "react";
import useInputState from "../../hooks/useInputState";
import useToggleState from "../../hooks/useToggleState";
import { AuthContext } from "../../context/AuthContext";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  ArrowDownTrayIcon,
  ChartBarIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";
import InputWithLabel from "../../components/Forms/InputWithLabel";
import Logo from "../../img/logo.png";
import AgreeTermsCheckbox from "../../components/Forms/AgreeTermsCheckbox";
import { useLocation } from "react-router-dom";

const features = [
  {
    name: "Intuitive Lead Evaluation",
    description:
      "InProfiler automatically categorizes your LinkedIn connection requests based on your professional objectives. No more manual sorting.",
    href: "#",
    icon: ChartBarIcon,
  },
  {
    name: "Collaborative Lead Sharing",
    description:
      "Promote teamwork with InProfiler's lead sharing feature. Share potential leads with team members in just a click.",
    href: "#",
    icon: ShareIcon,
  },
  {
    name: "Seamless CRM Integration",
    description:
      "Synchronize InProfiler with your CRM system for effortless transfer and management of high-value leads.",
    href: "#",
    icon: ArrowDownTrayIcon,
  },
];

export default function Register() {
  const location = useLocation();
  const { registerUser, loginUser, user, checkInviteToken } =
    useContext(AuthContext);
  const [firstName, setFirstName] = useInputState("");
  const [email, setEmail, overwriteEmail] = useInputState("");
  const [password1, setPassword1] = useInputState("");
  const [password2, setPassword2] = useInputState("");
  const [termsAccepted, toggleTermsAccepted] = useToggleState(false);
  const [signupErrors, setSignupErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [inviteToken, setInviteToken] = useState();
  const [inviteAccount, setInviteAccount] = useState();
  const [inviteLinkToken, setInviteLinkToken] = useState();
  const [tokenType, setTokenType] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    // Token from email invite
    const emailTokenParam = urlParams.get("token");
    if (emailTokenParam) {
      setInviteToken(emailTokenParam);
      setTokenType("email");
    }
    // Inviting account name
    const account = urlParams.get("accountName");
    setInviteAccount(account);
    // Autofill email from email invite
    const inviteEmail = urlParams.get("email");
    if (inviteEmail) {
      overwriteEmail(inviteEmail);
    }
    // Token from link invite
    const inviteLinkTokenParam = urlParams.get("inviteLinkToken");
    if (inviteLinkTokenParam) {
      setInviteLinkToken(inviteLinkTokenParam);
      setTokenType("link");
    }

    if (user && !emailTokenParam) {
      navigate("/app");
    }
  }, [location]);

  useEffect(() => {
    const checkToken = async () => {
      if (inviteToken) {
        try {
          const response = await checkInviteToken(inviteToken);
          if (response.ok) {
            const data = await response.json();
            const exists = data.exists;

            if (exists) {
              if (user) {
                navigate("/app/accounts");
              } else {
                const accountName = data.account_name;
                navigate(`/login?inviteAccountName=${accountName}`);
              }
            }
          } else {
            console.error("Error:", response.status);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    checkToken();
  }, [inviteToken]);

  const handleSubmit = async () => {
    setLoading(true);
    setSignupErrors(null);
    // Fail if terms are not accepted
    if (!termsAccepted) {
      setSignupErrors({
        terms: ["You need to accept the terms and conditions to continue"],
      });
      setLoading(false);
      return;
    }
    // Fail if there is an email and inviteLinkToken
    if (inviteToken && inviteLinkToken) {
      setSignupErrors({ invite_token: ["Two invite tokens present"] });
      setLoading(false);
      return;
    }

    try {
      const response = await registerUser({
        email,
        password: password1,
        password2,
        firstName,
        inviteToken: inviteToken
          ? inviteToken
          : inviteLinkToken
          ? inviteLinkToken
          : null,
        tokenType,
      });
      if (response.status !== 201) {
        const data = await response.json();
        setSignupErrors(data);
        setLoading(false);
      } else {
        // 3rd argument for first login, 4th argument for coming from invite
        loginUser({
          email,
          password: password1,
          firstLogin: true,
          fromInvite: inviteToken || inviteLinkToken ? inviteAccount : false,
        });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const checkForEnterSubmit = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="flex-0 flex min-h-full max-h-full">
        <div className="flex w-full xl:w-1/2 flex-1 flex-col justify-start lg:justify-center px-10 py-12 sm:px-24 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full sm:w-2/3 lg:w-[28rem]">
            <div>
              <Link to="/" target="_blank" className="flex gap-3 items-center">
                <img className="h-12 w-auto" src={Logo} alt="InProfiler" />
                <span className="text-3xl font-bold text-indigo-600">
                  InProfiler
                </span>
              </Link>

              <h2 className="mt-8 text-3xl font-bold leading-9 tracking-tight text-gray-900">
                {inviteAccount
                  ? `Join ${inviteAccount}`
                  : "Create your account"}
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Go to login
                </Link>
              </p>
            </div>

            {signupErrors &&
              Object.keys(signupErrors).includes("invite_token") && (
                <div className="p-2 bg-red-50 text-red-800 text-sm rounded mt-4">
                  {signupErrors["invite_token"][0]}
                </div>
              )}

            <div className="mt-8">
              <div>
                <div className="space-y-6">
                  <InputWithLabel
                    label="Your Name"
                    type="text"
                    required={true}
                    placeholder="Your Name"
                    value={firstName}
                    onChange={setFirstName}
                    onKeyDown={checkForEnterSubmit}
                    disabled={loading}
                    small
                    error={
                      signupErrors &&
                      Object.keys(signupErrors).includes("first_name")
                    }
                    errorText={
                      signupErrors &&
                      Object.keys(signupErrors).includes("first_name") &&
                      signupErrors["first_name"][0]
                    }
                  />

                  <InputWithLabel
                    label="Email address"
                    type="email"
                    required={true}
                    placeholder="Email address"
                    value={email}
                    onChange={setEmail}
                    onKeyDown={checkForEnterSubmit}
                    disabled={loading}
                    small
                    error={
                      signupErrors &&
                      Object.keys(signupErrors).includes("email")
                    }
                    errorText={
                      signupErrors &&
                      Object.keys(signupErrors).includes("email") &&
                      signupErrors["email"][0]
                    }
                  />
                  <InputWithLabel
                    label="Password"
                    type="password"
                    required={true}
                    placeholder="Password"
                    value={password1}
                    onChange={setPassword1}
                    onKeyDown={checkForEnterSubmit}
                    disabled={loading}
                    small
                    error={
                      signupErrors &&
                      Object.keys(signupErrors).includes("password")
                    }
                    errorText={
                      signupErrors &&
                      Object.keys(signupErrors).includes("password") &&
                      signupErrors["password"][0]
                    }
                  />
                  <InputWithLabel
                    label="Confirm Password"
                    type="password"
                    required={true}
                    placeholder="Confirm Password"
                    value={password2}
                    onChange={setPassword2}
                    onKeyDown={checkForEnterSubmit}
                    disabled={loading}
                    small
                    error={
                      signupErrors &&
                      Object.keys(signupErrors).includes("password2")
                    }
                    errorText={
                      signupErrors &&
                      Object.keys(signupErrors).includes("password2") &&
                      signupErrors["password2"][0]
                    }
                  />
                  <AgreeTermsCheckbox
                    checked={termsAccepted}
                    onChange={toggleTermsAccepted}
                    error={
                      signupErrors &&
                      Object.keys(signupErrors).includes("terms")
                    }
                    errorText={
                      signupErrors &&
                      Object.keys(signupErrors).includes("terms") &&
                      signupErrors["terms"][0]
                    }
                  />

                  <div>
                    <button
                      onClick={handleSubmit}
                      disabled={loading}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-400"
                    >
                      Join
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="align-center w-1/2 flex-1 flex-col justify-center bg-indigo-600 px-4 py-12 shadow-inner sm:px-6 lg:flex-none lg:px-20 xl:px-24 hidden xl:flex">
          <div className="bg-white px-10 pb-14 pt-10 rounded-xl shadow-xl">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              Get started with InProfiler
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              You are one step away from smarter connections
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Start refining your LinkedIn lead management with InProfiler.
              Optimize connections, foster collaborations, and drive success.
              Sign up today and elevate your networking game.
            </p>
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <feature.icon
                      className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature.name}
                  </dt>{" "}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
