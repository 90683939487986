import React from "react";
import Logo from "../../img/logo.png";

export default function LoadingScreen() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex flex-col items-center">
        <img src={Logo} className="h-20 w-20 bounce-out-down" />
        <span className="text-3xl text-gray-800 font-bold mt-8">
          Loading InProfiler
        </span>
      </div>
    </div>
  );
}
