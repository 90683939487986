import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const variables = [
  { name: "lead_message", description: "Message left by the lead" },
  {
    name: "lead_mutual_connections_count",
    description: "Mutual connections between lead and user",
  },
  { name: "lead_profile_data", description: "The leads Linkedin Profile data" },
  { name: "user_job_title", description: "User Job title" },
  { name: "user_company", description: "User Company" },
  { name: "user_objective", description: "User Objective" },
];

export default function VariablesModal({
  open,
  toggleOpen,
  messageIndex,
  message,
  handleMessageChange,
}) {
  const handleClick = (variable) => {
    const newMessage = message + "{" + variable + "}";
    handleMessageChange(messageIndex, newMessage);
    toggleOpen();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Prompt Variables
                    </Dialog.Title>
                    <p className="text-gray-500">Click variable to add</p>

                    <div className="my-3">
                      {variables.map((variable) => (
                        <div
                          className="p-2 rounded shadow-sm border my-3 hover:bg-gray-50 cursor-pointer"
                          key={variable.name}
                          onClick={() => handleClick(variable.name)}
                        >
                          <h5 className="font-semibold">{variable.name}</h5>
                          <p className="text-gray-500">
                            {variable.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
