import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Outlet } from "react-router-dom";
import Topbar from "./Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import SearchBox from "../Search/SearchBox";
import useToggleState from "../../hooks/useToggleState";
import _survser from "survser-js";

export default function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchOpen, toggleSearchOpen] = useToggleState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.REACT_APP_ENVIRONMENT !== "DEVELOPMENT"
    ) {
      _survser("setAPIKey", process.env.REACT_APP_SURVSER_KEY);
      _survser("identifyUser", {
        id: user.user_id,
        email: user.email,
        name: user.fullName,
      });
    }
  }, []);

  return (
    <>
      <div>
        <SearchBox open={searchOpen} setOpen={toggleSearchOpen} />
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="lg:pl-72">
          <Topbar
            setSidebarOpen={setSidebarOpen}
            openSearch={toggleSearchOpen}
          />
          <div className="content-height">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
