import React, { useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import SetupObjectives from "../../components/Authentication/Register/SetupObjectives";
import useInputState from "../../hooks/useInputState";
import Logo from "../../img/logo.png";
import { Navigate, useLocation } from "react-router-dom";
import { useInvitedUserSetup } from "../../api/UserAPI";

export default function SetupInvited() {
  const { account, setAccountUser } = useContext(SubscriptionContext);
  const location = useLocation();
  const [jobTitle, setJobTitle] = useInputState("");
  const [objective, setObjective] = useInputState("");
  const [company, setCompany] = useState();
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState();
  const [redirect, setRedirect] = useState();
  const invitedUserSetup = useInvitedUserSetup();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const urlAccountName = urlParams.get("account");
    if (urlAccountName) {
      setCompany(urlAccountName);
    } else if (account) {
      setCompany(account.name);
    }
  }, []);

  const handleSubmit = () => {
    setErrors(null);
    setLoading(true);
    invitedUserSetup(company, objective, jobTitle)
      .then((data) => {
        setResponseData(data);
        setAccountUser(data);
        localStorage.setItem("accountUser", JSON.stringify(data));
        setRedirect(true);
      })
      .catch((err) => {
        setErrors(err.response.data.message);
        setLoading(false);
      });
  };

  if (responseData && redirect) {
    return <Navigate to="/app/get-extension" />;
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="InProfiler" />
          <h1 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Let's get your account set up
          </h1>
        </div>
        <SetupObjectives
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          objective={objective}
          setObjective={setObjective}
          company={company}
          errors={errors}
          fromInvite={true}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
}
