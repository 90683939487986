import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { classNames, formatDate } from "../../../helpers";
import SubscriptionPlansModal from "../../SubscriptionPlans/SubscriptionPlansModal";
import useToggleState from "../../../hooks/useToggleState";
import { useCreateCustomerPortalSession } from "../../../api/SubscriptionAPI";
import SubscriptionSuccessAlert from "./SubscriptionSuccessAlert";
import SubscriptionErrorAlert from "./SubscriptionErrorAlert";
import { subscriptionPermissions } from "../../../permissions";

export default function SubscriptionSettings({ userRole }) {
  const location = useLocation();
  const [showPlanModal, toggleShowPlanModal] = useToggleState(false);
  const [errors, setErrors] = useState();
  const {
    subscriptionPlan,
    subscriptionAccount,
    getSubscriptionPlan,
    getSubscriptionAccount,
  } = useContext(SubscriptionContext);
  const [portalSessionUrl, setPortalSessionUrl] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [loadingSession, setLoadingSession] = useState(false);
  const createCustomerPortalSession = useCreateCustomerPortalSession();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentParam = urlParams.get("payment_status");
    setPaymentStatus(paymentParam);
  }, []);

  useEffect(() => {
    // Update account and plan data to the latest state
    getSubscriptionAccount();
    getSubscriptionPlan();
  }, []);

  useEffect(() => {
    if (portalSessionUrl) {
      window.location.href = portalSessionUrl;
    }
  }, [portalSessionUrl]);

  const handleManageSubscription = () => {
    setErrors(null);
    setLoadingSession(true);
    createCustomerPortalSession(subscriptionAccount.id)
      .then((data) => {
        setPortalSessionUrl(data.session_url);
        setLoadingSession(false);
      })
      .catch((err) => {
        setErrors(err.response.data.message);
        setLoadingSession(false);
      });
  };

  const ListItem = ({ heading, value }) => {
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          {heading}
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {value}
        </dd>
      </div>
    );
  };

  if (subscriptionPermissions.includes(userRole)) {
    return (
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <SubscriptionPlansModal
          open={showPlanModal}
          toggleOpen={toggleShowPlanModal}
        />
        <div>
          <h2 className="text-base font-semibold leading-7 text-black">
            Your Subscription
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            To manage your subscription and access your invoices, click the
            Manage subscription button.
          </p>
          {paymentStatus && paymentStatus === "success" ? (
            <SubscriptionSuccessAlert
              showInvite={subscriptionPlan.included_users > 1}
            />
          ) : (
            paymentStatus &&
            paymentStatus === "cancel" && <SubscriptionErrorAlert />
          )}
        </div>

        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"></div>
          <div>
            <dl className="divide-y divide-gray-100">
              <ListItem heading="Current Plan" value={subscriptionPlan.name} />
              <ListItem
                heading="Billing Interval"
                value={
                  subscriptionAccount.billing_interval === "y"
                    ? "Yearly"
                    : "Monthly"
                }
              />
              <ListItem
                heading="Included Users"
                value={subscriptionPlan.included_users}
              />
              <ListItem
                heading="Monthly Requests per User"
                value={subscriptionPlan.monthly_user_requests}
              />

              {!subscriptionAccount.subscription_end ? (
                <>
                  <ListItem
                    heading="Next Usage Reset"
                    value={
                      subscriptionAccount.next_usage_reset
                        ? formatDate(subscriptionAccount.next_usage_reset)
                        : "Never"
                    }
                  />
                  <ListItem
                    heading="Next Billing Date"
                    value={
                      subscriptionAccount.next_billing_date
                        ? formatDate(subscriptionAccount.next_billing_date)
                        : "Never"
                    }
                  />
                </>
              ) : (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-red-700">
                    Subscription End
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-red-700 sm:col-span-2 sm:mt-0">
                    {formatDate(subscriptionAccount.subscription_end)}
                  </dd>
                </div>
              )}
            </dl>
          </div>
          <div className="mt-8 flex gap-4 items-center">
            <button
              onClick={toggleShowPlanModal}
              className={classNames(
                subscriptionPlan.paid
                  ? "bg-white text-indigo-600 hover:bg-gray-50 border border-indigo-500"
                  : "bg-indigo-600 text-white hover:bg-indigo-500",
                "rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              )}
            >
              {subscriptionPlan.paid ? "View all plans" : "Select Plan"}
            </button>
            {subscriptionPlan.paid && (
              <button
                onClick={handleManageSubscription}
                disabled={loadingSession}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-300"
              >
                {subscriptionAccount.subscription_end
                  ? "Resume Subscription"
                  : "Manage Subscription"}
              </button>
            )}

            {errors && <p className="text-sm text-red-500">{errors}</p>}
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/app/settings" />;
  }
}
