import React, { useContext, Fragment, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { Menu, Transition } from "@headlessui/react";
import { classNames, getInitials } from "../../../helpers";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function UserDropdown() {
  const { user, logoutUser } = useContext(AuthContext);
  const { subscriptionAccount } = useContext(SubscriptionContext);

  const userNavigation = [
    { name: "Your Profile", to: "/app/settings/profile", action: undefined },
    { name: "Switch Account", to: "/app/accounts", action: undefined },
    { name: "Sign out", to: undefined, action: logoutUser },
  ];

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        {user.profilePicture ? (
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src={user.profilePicture}
            alt=""
          />
        ) : (
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-400">
            <span className="text-xs font-medium leading-none text-white">
              {getInitials(user.fullName)}
            </span>
          </span>
        )}
        <span className="hidden lg:flex lg:items-center">
          <div className="flex flex-col items-start ml-4 max-w-[150px]">
            <span
              className="text-sm font-semibold leading-6 text-gray-900 truncate"
              aria-hidden="true"
            >
              {user && user.fullName?.length > 20
                ? user.fullName.slice(0, 20) + " ..."
                : user.fullName}
            </span>
            <span className="text-xs text-gray-500">
              {subscriptionAccount && subscriptionAccount.name}
            </span>
          </div>
          <ChevronDownIcon
            className="ml-4 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Link
                  to={item.to}
                  onClick={item.action}
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block px-3 py-1 text-sm leading-6 text-gray-900"
                  )}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
