import { Fragment, useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import useToggleState from "../../../hooks/useToggleState";
import useInputState from "../../../hooks/useInputState";
import { useDeleteAccount } from "../../../api/UserAPI";
import { deleteReasons } from "../../../constants";

export default function DeleteProfileModal({ open, toggleOpen }) {
  const { logoutUser } = useContext(AuthContext);
  const [isSure, toggleIsSure] = useToggleState(false);
  const [deleteReason, setDeleteReason] = useInputState("none");
  const [customReason, setCustomReason] = useInputState("");
  const [error, setError] = useState();
  const deleteAccount = useDeleteAccount();

  useEffect(() => {
    setError(null);
  }, [deleteReason]);

  const handleDeleteAccount = () => {
    setError(null);

    if (deleteReason === "none") {
      setError("Please select an option");
    } else {
      if (deleteReason === "other" && !customReason) {
        setError("Please specify your reason");
        return;
      }

      deleteAccount(deleteReason)
        .then((res) => logoutUser())
        .catch((err) => setError(err.response.data.message));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Delete account
                    </Dialog.Title>
                    <div className="mt-2">
                      {isSure ? (
                        <div className="my-3">
                          <label
                            htmlFor="delete-reason"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Why do you want to delete your account?
                          </label>
                          <div className="mt-2">
                            <select
                              id="delete-reason"
                              name="delete-reason"
                              autoComplete="country-name"
                              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                              value={deleteReason ? deleteReason : null}
                              onChange={setDeleteReason}
                            >
                              {deleteReasons.map((reason) => (
                                <option key={reason.key} value={reason.key}>
                                  {reason.label}
                                </option>
                              ))}
                            </select>
                            {deleteReason === "other" && (
                              <input
                                value={customReason}
                                onChange={setCustomReason}
                                autoFocus
                                placeholder="Please specify your reason"
                                className="block w-full mt-2 rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                              />
                            )}
                            {error ? (
                              <p className="text-red-500 text-sm mt-2">
                                {error}
                              </p>
                            ) : (
                              <p className="text-gray-500 text-sm mt-2">
                                This information helps us to improve our service
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="space-y-3 text-sm text-gray-500">
                          <p>
                            Are you sure you want to delete your user account?
                            Your subscription will be canceled immediately and
                            all of your data will be removed from our servers
                            forever. This action cannot be undone.
                          </p>
                          {/* <p>
                            Alternatively, you can{" "}
                            <Link
                              className="text-blue-500"
                              to="/app/settings/subscription"
                            >
                              cancel your subscription
                            </Link>{" "}
                            and change your mind about it until the end of your
                            current billing period.
                          </p> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={!isSure ? toggleIsSure : handleDeleteAccount}
                  >
                    {isSure ? "Delete account" : "Yes, I am sure"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleOpen}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
