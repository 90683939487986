import React, { useState, useEffect } from "react";
import { useGetDashboardStats } from "../../api/AdminAPI";
import { classNames } from "../../helpers";

const secondaryNavigation = [
  { name: "Overview", href: "#", current: true },
  { name: "Accounts", href: "#", current: false },
  { name: "Leads", href: "#", current: false },
  { name: "", href: "#", current: false },
];

export default function AdminDashboard() {
  const getDashboardStats = useGetDashboardStats();
  const [accountStats, setAccountsStats] = useState(0);
  const [usersStats, setUsersStats] = useState(0);
  const [leadsStats, setLeadsStats] = useState(0);
  const [tableStats, setTableStats] = useState();

  useEffect(() => {
    getDashboardStats().then((data) => {
      setAccountsStats(data.accounts);
      setUsersStats(data.users);
      setLeadsStats(data.leads);
      setTableStats(data);
    });
  }, []);

  const statsTable = [
    {
      name: "Lindsay Walton",
      total: "Front-end Developer",
      day: "lindsay.walton@example.com",
      week: "Member",
      month: "Member",
    },
    // More people...
  ];

  return (
    <div className="bg-white content-height">
      <header className="pb-4 pt-6 sm:pb-6 bg-gray-50">
        <div className="mx-auto flex w-full flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            Admin Dashboard
          </h1>
          <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            {secondaryNavigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={item.current ? "text-indigo-600" : "text-gray-700"}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </header>
      <div className="px-6 py-4">
        <div className="flow-root p-2 w-1/3 border rounded-xl shadow-sm">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-indigo-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Today
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Week
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Month
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {tableStats &&
                    Object.keys(tableStats).map((stat) => (
                      <tr key={tableStats[stat].name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {tableStats[stat].name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                          {tableStats[stat].today}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                          {tableStats[stat].this_week}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                          {tableStats[stat].this_month}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                          {tableStats[stat].total}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
