import React from "react";
import Hero from "../../components/LandingPage/Hero";
import Features from "../../components/LandingPage/Features";
import UseCases from "../../components/LandingPage/UseCases";
import Newsletter from "../../components/LandingPage/Newsletter";
import HowItWorks from "../../components/LandingPage/HowItWorks";
import Faq from "../../components/LandingPage/Faq";
import Pricing from "../../components/LandingPage/Pricing";

export default function Landing() {
  return (
    <>
      <Hero />
      <Features />
      <UseCases />
      <HowItWorks />
      <Pricing />
      <Faq />
      {/* <Newsletter /> */}
    </>
  );
}
