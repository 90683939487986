import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useChangeRole } from "../../../api/UserAPI";

export default function ChangeRoleModal({ open, toggleOpen, handleConfirm }) {
  const changeRole = useChangeRole();
  const [selectedRole, setSelectedRole] = useState("AD");
  const [error, setError] = useState();
  const [roleChoices, setRoleChoices] = useState([
    {
      label: "Assign Member Role",
      key: "ME",
      name: "Member",
      description: "Members can only import, view and share leads.",
    },
    {
      label: "Assign Admin Role",
      key: "AD",
      name: "Admin",
      description: "Administrators can manage account settings and users",
    },
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserPlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Change User Role
                    </Dialog.Title>
                    <div className="mt-1">
                      <p className="text-sm text-gray-500">
                        Select the role you want to assign to this user
                      </p>
                    </div>

                    <div className="mt-8 mb-3">
                      <fieldset>
                        <legend className="sr-only">Role</legend>
                        <div className="space-y-5">
                          {roleChoices.map((role) => (
                            <div
                              key={role.key}
                              className="relative flex items-start"
                              onClick={() => setSelectedRole(role.key)}
                            >
                              <div className="flex h-6 items-center">
                                <input
                                  id={role.key}
                                  aria-describedby={`${role.key}-description`}
                                  name="role"
                                  type="radio"
                                  checked={role.key === selectedRole}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor={role.key}
                                  className="font-medium text-gray-900"
                                >
                                  {role.label}
                                </label>
                                <p
                                  id={`${role.id}-description`}
                                  className="text-gray-500"
                                >
                                  {role.description}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={() => handleConfirm(selectedRole)}
                  >
                    Change Role
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleOpen}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
