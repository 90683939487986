import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../helpers";

export default function SubscriptionPlanItem({
  plan,
  planIdx,
  annual,
  loadingCheckout,
  planCount,
  handleStartCheckout,
  currentPlan,
  subscriptionAccount,
  handleStartPlanChange,
}) {
  const [canSelect, setCanSelect] = useState(true);
  const [cantSelectReason, setCantSelectReason] = useState();
  const [isUpgrade, setIsUpgrade] = useState(false);

  useEffect(() => {
    if (subscriptionAccount) {
      if (subscriptionAccount.has_paid_subscription) {
        setIsUpgrade(true);
      }
      if (plan.name === currentPlan.name && subscriptionAccount.active) {
        setCanSelect(false);
        setCantSelectReason("Current Plan");
      } else if (plan.included_users < subscriptionAccount.number_users) {
        setCanSelect(false);
        setCantSelectReason("Too many account users");
      }
    }
  }, [subscriptionAccount]);

  const getPlanActionText = () => {
    if (loadingCheckout) {
      return "Please Wait";
    } else if (!canSelect) {
      if (cantSelectReason) {
        return cantSelectReason;
      } else {
        return "Not available";
      }
    } else if (subscriptionAccount.has_paid_subscription) {
      return "Change Plan";
    } else {
      return "Buy Plan";
    }
  };

  const handleActionClick = () => {
    const stripePriceId = annual
      ? plan.stripe_price_id_yearly
      : plan.stripe_price_id_monthly;

    return isUpgrade
      ? handleStartPlanChange(stripePriceId)
      : handleStartCheckout(stripePriceId);
  };

  return (
    <div
      key={plan.id}
      className={classNames(
        plan.most_popular ? "lg:z-10 lg:rounded-none" : "lg:mts-8",
        planIdx === 0 ? "lg:rounded-r-none" : "",
        planIdx === planCount - 1 ? "lg:rounded-l-none" : "",
        "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
      )}
    >
      <div>
        <div className="flex items-center justify-between gap-x-4">
          <h3
            id={plan.id}
            className={classNames(
              plan.mostPopular ? "text-indigo-600" : "text-gray-900",
              "text-lg font-semibold leading-8"
            )}
          >
            {plan.name}
          </h3>
          {plan.most_popular ? (
            <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
              Most popular
            </p>
          ) : null}
        </div>
        <p className="mt-4 text-sm leading-6 text-gray-600">
          {plan.description}
        </p>
        <p className="mt-6 flex items-baseline gap-x-1">
          <span className="text-4xl font-bold tracking-tight text-gray-900">
            {annual ? plan.price_yearly : plan.price_monthly}$
          </span>
          <span className="text-sm font-semibold leading-6 text-gray-600">
            {annual ? "/year" : "/month"}
          </span>
        </p>
        <ul
          role="list"
          className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
        >
          <li className="flex gap-x-3">
            <CheckIcon
              className="h-6 w-5 flex-none text-indigo-600"
              aria-hidden="true"
            />
            {`${plan.included_users} Users included`}
          </li>
          <li className="flex gap-x-3">
            <CheckIcon
              className="h-6 w-5 flex-none text-indigo-600"
              aria-hidden="true"
            />
            {`${plan.monthly_user_requests} Leads per Month / User`}
          </li>
          {plan.has_lead_sharing && (
            <li className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              Lead Sharing
            </li>
          )}
          {plan.has_crm_export && (
            <li className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              Affinity CRM Integration
            </li>
          )}
        </ul>
      </div>
      <button
        onClick={handleActionClick}
        aria-describedby={plan.id}
        disabled={loadingCheckout || !canSelect}
        className={classNames(
          plan.most_popular || isUpgrade
            ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
            : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
          "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-100 disabled:text-gray-500"
        )}
      >
        {getPlanActionText()}
      </button>
    </div>
  );
}
