import { useState, useEffect } from "react";
import useToggleState from "../../hooks/useToggleState";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  MagnifyingGlassIcon,
  ArrowPathIcon,
  PaperAirplaneIcon,
  RssIcon,
} from "@heroicons/react/24/outline";
import Logo from "../../img/logo.png";
import SubscriptionPlansModal from "./SubscriptionPlansModal";
import { useStartCheckout } from "../../api/SubscriptionAPI";
import { Link, useNavigate } from "react-router-dom";

export default function SubscriptionExpired({
  subscriptionAccount,
  subscriptionPlan,
}) {
  const [showPlansModal, toggleShowPlansModal] = useToggleState(false);
  const startCheckout = useStartCheckout();
  const [sessionUrl, setSessionUrl] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionUrl) {
      window.location.href = sessionUrl;
    }
  }, [sessionUrl]);

  const handleRenewal = () => {
    startCheckout(null, subscriptionAccount.id)
      .then((data) => {
        setSessionUrl(data.session_url);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const links = [
    {
      name: `Renew your ${subscriptionPlan.name} Plan subscription`,
      to: null,
      action: handleRenewal,
      description: "Continue with a new subscription of your previous plan.",
      icon: ArrowPathIcon,
    },
    {
      name: "Select a new plan",
      to: null,
      action: toggleShowPlansModal,
      description: "Have a look at our plans and select the one you like most.",
      icon: MagnifyingGlassIcon,
    },
    {
      name: "Contact Us",
      to: "/contact",
      action: null,
      description: "Get in touch if you have doubts about your account status.",
      icon: PaperAirplaneIcon,
    },
  ];

  return (
    <div className="bg-white h-full">
      <SubscriptionPlansModal
        open={showPlansModal}
        toggleOpen={toggleShowPlansModal}
      />
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <div className="flex gap-4 items-center justify-center">
          <img className="h-10 w-auto sm:h-12" src={Logo} alt="InProfiler" />
          <span className="text-3xl text-indigo-600 font-bold">InProfiler</span>
        </div>
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold leading-8 text-indigo-600">
            Oh no!
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Your subscription is inactive
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
            To continue using InProfiler, please choose one of the options
            below.
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <h2 className="sr-only">Options</h2>
          <ul
            role="list"
            className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5"
          >
            {links.map((link, linkIdx) => (
              <li
                key={linkIdx}
                className="relative flex gap-x-6 py-6 cursor-pointer"
                onClick={
                  link.action
                    ? link.action
                    : link.to
                    ? () => navigate(link.to)
                    : undefined
                }
              >
                <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                  <link.icon
                    className="h-6 w-6 text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-auto">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    <a href={link.href}>
                      <span className="absolute inset-0" aria-hidden="true" />
                      {link.name}
                    </a>
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-600">
                    {link.description}
                  </p>
                </div>
                <div className="flex-none self-center">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-10 flex justify-center">
            <Link
              to="/"
              className="text-sm font-semibold leading-6 text-gray-400"
            >
              Go to Homepage
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}
