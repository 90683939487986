import React from "react";
import { classNames } from "../../helpers";

export default function TextAreaWithLabel({
  label,
  placeholder,
  small,
  value = undefined,
  disabled = false,
  onChange = undefined,
  required = false,
  helperText = null,
  error = false,
  errorText = null,
  rows = 3,
}) {
  return (
    <div>
      <label
        htmlFor="comment"
        className={classNames(
          small ? "text-sm" : "text-base",
          "block font-medium leading-6 text-gray-900"
        )}
      >
        {label}
      </label>
      <div className={label && "mt-2"}>
        <textarea
          required
          rows={rows}
          name="comment"
          id="comment"
          className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:text-gray-500"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {error && errorText ? (
        <p className="mt-2 text-sm text-red-500">{errorText}</p>
      ) : (
        helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>
      )}
    </div>
  );
}
