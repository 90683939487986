import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PersonalInfo from "./PersonalInfo";
import ChangePassword from "./ChangePassword";

export default function ProfileSettings() {
  const { user, setUser, setAuthTokens } = useContext(AuthContext);

  return (
    <div className="min-w-full divide-y divide-gray-200">
      <PersonalInfo
        user={user}
        setUser={setUser}
        setAuthTokens={setAuthTokens}
      />

      <ChangePassword user={user} />
    </div>
  );
}
