import React from "react";
import AccountPreferences from "./AccountPreferences";
import DeleteAccount from "./DeleteAccount";
import { Navigate } from "react-router-dom";
import {
  accountSettingsPermissions,
  deleteAccountPermissions,
} from "../../../permissions";

export default function AccountSettings({ userRole }) {
  if (accountSettingsPermissions.includes(userRole)) {
    return (
      <div className="min-w-full divide-y divide-gray-200">
        <AccountPreferences />
        {deleteAccountPermissions.includes(userRole) && <DeleteAccount />}
      </div>
    );
  } else {
    return <Navigate to="/app/settings" />;
  }
}
