import React, { useState, useEffect } from "react";
import {
  UserPlusIcon,
  InboxArrowDownIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import useAxios from "../../hooks/useAxios";
import { useGetDashboardStats } from "../../api/LeadsAPI";

export default function StatsCards() {
  const api = useAxios();
  const [totalImported, setTotalImported] = useState(0);
  const [unseen, setUnseen] = useState(0);
  const [averageScore, setAverageScore] = useState(0);
  const getDashboardStats = useGetDashboardStats();

  const cards = [
    {
      name: "Total Leads imported",
      icon: UserPlusIcon,
      value: totalImported,
    },
    {
      name: "Unseen Leads",
      icon: InboxArrowDownIcon,
      value: unseen,
    },
    {
      name: "Average Lead Score",
      icon: StarIcon,
      value: averageScore,
    },
  ];

  useEffect(() => {
    getDashboardStats()
      .then((data) => {
        setTotalImported(data.requests);
        setUnseen(data.unseen);
        setAverageScore(data.average_score);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, []);

  return (
    <div className="w-full">
      <div className=" px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl font-medium leading-6 text-gray-900 mb-5">
          Overview
        </h2>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          {cards.map((card) => (
            <div
              key={card.name}
              className="overflow-hidden rounded-lg bg-white shadow border"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <card.icon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {card.name}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {card.value}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
