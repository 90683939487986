import React, { useRef, useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { getInitials } from "../../../helpers";
import { useUpdateProfileImage } from "../../../api/UserAPI";

export default function ProfilePicture() {
  const [error, setError] = useState();
  const { user, setUser, setAuthTokens } = useContext(AuthContext);
  const fileInputRef = useRef(null);
  const updateProfileImage = useUpdateProfileImage();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Handle profile picture update
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_picture", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    updateProfileImage(user.user_id, formData, config)
      .then((data) => {
        const { user: updatedUser, token } = data;

        setUser(updatedUser);
        setAuthTokens(token);
        localStorage.setItem("authTokens", JSON.stringify(token));
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.profile_picture[0]);
      });
  };

  return (
    <div className="col-span-full flex items-center gap-x-8">
      {user.profilePicture ? (
        <img
          src={
            process.env.REACT_APP_ENVIRONMENT === "DEVELOPsMENT"
              ? process.env.REACT_APP_BASE_URL + user.profilePicture
              : user.profilePicture
          }
          alt=""
          className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
        />
      ) : (
        <span className="inline-flex h-24 w-24 items-center justify-center rounded-lg bg-gray-400">
          <span className="text-3xl font-medium leading-none text-white">
            {getInitials(user.fullName)}
          </span>
        </span>
      )}
      <div>
        <button
          onClick={handleButtonClick}
          type="button"
          className="rounded-md bg-gray-50 border px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-100"
        >
          Change avatar
        </button>
        {error ? (
          <p className="mt-2 text-sm leading-5 text-red-500">{error}</p>
        ) : (
          <p className="mt-2 text-sm leading-5 text-gray-500">
            JPG, GIF or PNG. 1MB max.
          </p>
        )}
      </div>
      <input
        type="file"
        id="file-input"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleProfilePictureChange}
      />
    </div>
  );
}
