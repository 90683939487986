import React from "react";
import { Link } from "react-router-dom";

export default function IntegrationDoesNotExist() {
  return (
    <div className="content-height flex items-center justify-center bg-gray-50">
      <div className="flex flex-col text-center gap-2">
        <span className="font-semibold text-gray-500 text-lg">
          Integration does not exist
        </span>
        <Link to="/app/settings/integrations" className="text-gray-500 text-sm">
          Activate
        </Link>
      </div>
    </div>
  );
}
