import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import useInputState from "../../hooks/useInputState";
import { validateEmail } from "../../helpers";
import { AuthContext } from "../../context/AuthContext";
import InputWithLabel from "../../components/Forms/InputWithLabel";
import Logo from "../../img/logo.png";

export default function Login() {
  const { loginUser, user } = useContext(AuthContext);
  const [email, setEmail] = useInputState("");
  const [password, setPassword] = useInputState("");
  const [loginError, setLoginError] = useState();
  const [invitingAccount, setInvitingAccount] = useState();
  const [inviteLinkToken, setInviteLinkToken] = useState();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const inviteAccountName = urlParams.get("inviteAccountName");
    setInvitingAccount(inviteAccountName);
    const linkToken = urlParams.get("inviteLinkToken");
    setInviteLinkToken(linkToken);
  }, [location]);

  const handleSubmit = () => {
    if (validateEmail(email)) {
      loginUser({ email, password, inviteLinkToken }).then((res) => {
        setLoginError("Email and password didn't match, please try again.");
      });
    } else {
      setLoginError("Please enter a valid email address.");
    }
  };

  const checkForEnterSubmit = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    }
  };

  if (user) {
    return <Navigate to="/app" />;
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-start lg:justify-center py-12 sm:px-6 lg:px-8 bg-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex gap-3 items-center w-full justify-center mb-10">
            <img className="h-12 w-auto" src={Logo} alt="InProfiler" />
            <span className="text-3xl font-bold text-indigo-600">
              InProfiler
            </span>
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
            {invitingAccount
              ? `Sign in to join ${invitingAccount}`
              : "Sign in to your account"}
          </h2>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-[520px]">
          <div className="bg-white px-6 py-12 sm:rounded-lg sm:px-12">
            <div className="space-y-6">
              <InputWithLabel
                label="Email address"
                type="email"
                required={true}
                placeholder="Email address"
                value={email}
                onChange={setEmail}
                onKeyDown={checkForEnterSubmit}
              />
              <InputWithLabel
                label="Password"
                type="password"
                required={true}
                placeholder="Password"
                value={password}
                onChange={setPassword}
                onKeyDown={checkForEnterSubmit}
              />

              <div className="flex items-center justify-between">
                <div className="text-sm leading-6">
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div className="space-y-4">
                {loginError && (
                  <span className="text-sm text-red-500">{loginError}</span>
                )}
                <button
                  onClick={handleSubmit}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </div>

            <div>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Don't have an account?
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <Link to="/join">
                  <button className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Create Account
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            <Link
              to="/"
              className="font-semibold leading-6 text-gray-400 hover:text-gray-500"
            >
              Back to homepage
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
