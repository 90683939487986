import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";
import { PlusIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function InviteLinkExpired() {
  return (
    <div className="bg-white h-full">
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <div className="flex gap-4 items-center justify-center">
          <img className="h-10 w-auto sm:h-12" src={Logo} alt="InProfiler" />
          <span className="text-3xl text-indigo-600 font-bold">InProfiler</span>
        </div>
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold leading-8 text-indigo-600">
            We're sorry
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl leading-9">
            This invite link is expired
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
            The link might be too old or the account has reached it's user limit
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <h2 className="sr-only">Join options</h2>
          <ul
            role="list"
            className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5"
          >
            <Link to="/join" className="relative flex gap-x-6 py-6">
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow ring-0 ring-gray-900/10">
                <PlusIcon
                  className="h-6 w-6 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex-auto">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Create your own account
                </h3>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  Start exploring the benefits of InProfiler on your own
                </p>
              </div>
              <div className="flex-none self-center">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </Link>
            <Link to="/contact" className="relative flex gap-x-6 py-6">
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow ring-0 ring-gray-900/10">
                <PaperAirplaneIcon
                  className="h-6 w-6 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex-auto">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  Contact Us
                </h3>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  In case of doubt, feel free to reach out to us for support
                </p>
              </div>
              <div className="flex-none self-center">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </Link>
          </ul>
          <div className="mt-10 flex justify-center">
            <Link
              to="/"
              className="text-sm font-semibold leading-6 text-gray-400"
            >
              View Homepage
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}
