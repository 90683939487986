export const deleteReasons = [
  { key: "none", label: "Please select an option" },
  { key: "bugs", label: "Too many bugs" },
  { key: "noNeed", label: "I don't need it anymore" },
  { key: "foundAlternative", label: "Found an alternative" },
  { key: "lackingFeatures", label: "Lacking features" },
  { key: "doesntWork", label: "Doesn't work well enough" },
  { key: "tooExpensive", label: "Too expensive" },
  { key: "neverStarted", label: "Never started using it" },
  { key: "other", label: "Other" },
];

export const pricingTiers = [
  {
    name: "Personal Plan",
    id: "tier-personal",
    href: "#",
    priceMonthly: "$8.00",
    priceYearly: "$80.00",
    description:
      "Ideal for individuals managing their own leads. Perfect for entrepreneurs, freelancers, and professionals.",
    features: ["1 User included", "75 Leads per month"],
    mostPopular: false,
  },
  {
    name: "Basic Plan",
    id: "tier-basic",
    href: "#",
    priceMonthly: "$20.00",
    priceYearly: "$200.00",
    description:
      "Perfect for startups and small businesses with multiple team members working on lead management.",
    features: [
      "3 Users included",
      "150 Leads per month / user",
      "Lead Sharing",
    ],
    mostPopular: true,
  },
  {
    name: "Pro Plan",
    id: "tier-pro",
    href: "#",
    priceMonthly: "$90.00",
    priceYearly: "$900.00",
    description:
      "Designed for larger teams or businesses managing a high volume of leads. Perfect for corporations, HR departments, and sales teams.",
    features: [
      "10 Users included",
      "250 Leads per month / user",
      "Lead Sharing",
      "Affinity Integration",
    ],
    mostPopular: false,
  },
];

export const inprofilerPersonFields = [
  {
    name: "First Name",
    fieldName: "first_name",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "First Name",
    fixed: true,
  },
  {
    name: "Last Name",
    fieldName: "last_name",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Last Name",
    fixed: true,
  },
  {
    name: "Linkedin Profile Headline",
    fieldName: "headline",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Linkedin Profile Summary",
    fieldName: "person_summary",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Country",
    fieldName: "person_country",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Location",
  },
  {
    name: "City",
    fieldName: "person_city",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Occupation",
    fieldName: "person_occupation",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Current Company",
    fieldName: "current_company",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Job Title",
    fieldName: "job_title",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Job Titles",
  },
  {
    name: "Languages",
    fieldName: "person_languages",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Mutual connections count",
    fieldName: "mutual_connections_count",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Linkedin Request Message",
    fieldName: "custom_message",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "InProfiler Lead Owner",
    fieldName: "_OWNER",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Source of Introduction",
  },
  {
    name: "InProfiler Import Date",
    fieldName: "created",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Linkedin Profile URL",
    fieldName: "profile_url",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "LinkedIn URL",
  },
];

export const inprofilerOrganisationFields = [
  {
    name: "Company Name",
    fieldName: "name",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Name",
    fixed: true,
  },
  {
    name: "Website",
    fieldName: "website",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Domain",
    fixed: true,
  },
  {
    name: "Company Tagline",
    fieldName: "tagline",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Company Description",
    fieldName: "description",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "InProfiler Lead Owner",
    fieldName: "_OWNER",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Source of Introduction",
  },

  {
    name: "Industry",
    fieldName: "industry",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Industry",
  },
  {
    name: "Company Size",
    fieldName: "company_size",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Headquarter Country",
    fieldName: "hq_country",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "Location",
  },
  {
    name: "Headquarter City",
    fieldName: "hq_city",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Company Type",
    fieldName: "company_type",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Founded Year",
    fieldName: "founded_year",
    preset: false,
    targetFieldId: null,
  },
  {
    name: "Linkedin Page URL",
    fieldName: "linkedin_url",
    preset: true,
    targetFieldId: null,
    suggestedFieldName: "LinkedIn URL",
  },
];
