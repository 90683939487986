// This view is currently not used anywhere and hasn't been tested

import React, { useState, useContext, useEffect } from "react";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import useInputState from "../../hooks/useInputState";
import { useUpdateObjectives } from "../../api/UserAPI";
import InputWithLabel from "../../components/Forms/InputWithLabel";
import TextAreaWithLabel from "../../components/Forms/TextAreaWithLabel";
import Logo from "../../img/logo.png";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function IncompleteSetup() {
  const { subscriptionAccount } = useContext(SubscriptionContext);
  const [jobTitle, setJobTitle] = useInputState("");
  const [company, setCompany, overwriteCompany] = useInputState("");
  const [objective, setObjective] = useInputState("");
  const [errors, setErrors] = useState();
  const [saved, setSaved] = useState(false);
  const updateObjectives = useUpdateObjectives();

  useEffect(() => {
    if (subscriptionAccount) {
      overwriteCompany(subscriptionAccount.name);
    }
  }, [subscriptionAccount]);

  const handleSubmit = () => {
    setErrors(null);
    updateObjectives(jobTitle, company, objective)
      .then((data) => {
        setSaved(true);
      })
      .catch((err) => {
        setErrors(err.response.data.message);
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="InProfiler" />
          <h1 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            We are missing your information
          </h1>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[680px]">
          <div className="space-y-5 bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
            <div>
              <h2 className="font-semibold leading-7 text-gray-900 text-xl">
                {company
                  ? `Tell us about your work at ${company}`
                  : "Tell us about your work"}
              </h2>
            </div>

            <InputWithLabel
              label="Job Title"
              helperText="This helps us better understand your professional status and potential needs."
              placeholder="Investment Associate"
              value={jobTitle}
              onChange={setJobTitle}
              autoFocus={true}
            />
            {
              <InputWithLabel
                label="Company"
                helperText="This information assists us in tailoring the leads specifically for your industry"
                placeholder="Andreesen Horowitz"
                value={company}
                onChange={setCompany}
              />
            }
            <TextAreaWithLabel
              label="Lead objective"
              helperText="Tell us what you're looking for in the leads you wish to acquire."
              placeholder="I am looking for investment opportunities in early to mid stage startups in the healthcare sector"
              value={objective}
              onChange={setObjective}
            />
            {errors && (
              <div className="bg-red-50 my-4 px-4 py-2 rounded shadow-sm">
                <p className="text-sm text-red-600">{errors}</p>
              </div>
            )}
            <div className="rounded-lg bg-indigo-100 p-3 my-4">
              <div>
                <div className="flex gap-2 items-center">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-indigo-600"
                    aria-hidden="true"
                  />
                  <h4 className="font-semibold text-indigo-700">Important</h4>
                </div>
                <p className="mt-1 leading-6 text-indigo-700">
                  This information is critical to assess the potential value of
                  your leads. Make sure to set a precise goal for better
                  results.
                </p>
              </div>
            </div>
            <button
              type="button"
              className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSubmit}
            >
              Complete Setup
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
