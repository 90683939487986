import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import { SubscriptionProvider } from "../context/SubscriptionContext";
import PrivateOutlet from "../utils/PrivateOutlet";
import SelectAccount from "../views/App/SelectAccount";
import Setup from "../views/App/Setup";
import SetupInvited from "../views/App/SetupInvited";
import IncompleteSetup from "../views/App/IncompleteSetup";
import AppLayout from "../components/Layout/AppLayout";
import Dashboard from "../views/App/Dashboard";
import Leads from "../views/App/Leads";
import Settings from "../views/App/Settings";
import GetExtension from "../views/App/GetExtension";
import Feedback from "../views/App/Feedback";
import PageNotFound from "../views/App/PageNotFound";

export default function AppRoutes() {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <Routes>
          <Route element={<PrivateOutlet />} exact path="">
            <Route path="accounts">
              <Route index element={<SelectAccount />} />
              <Route path="create" element={<Setup />} />
              <Route path="join" element={<SetupInvited />} />
              <Route
                path="accounts/complete-setup"
                element={<IncompleteSetup />}
              />
            </Route>
            <Route element={<AppLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="leads">
                <Route index element={<Leads leadType="owner" />} />
                <Route path=":id" element={<Leads leadType="owner" />} />
                <Route path="shared">
                  <Route index element={<Leads leadType="shared" />} />
                  <Route path=":id" element={<Leads leadType="shared" />} />
                </Route>
              </Route>
              <Route path="settings" element={<Settings />}>
                <Route path=":view" element={<Settings />} />
              </Route>
              <Route path="get-extension" element={<GetExtension />} />
              <Route path="feedback" element={<Feedback />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </SubscriptionProvider>
    </AuthProvider>
  );
}
