import React from "react";
import InputWithLabel from "../../../../Forms/InputWithLabel";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

export default function EnterAPIKey({
  integrationType,
  setStep,
  apiKey,
  setApiKey,
}) {
  return (
    <div className="space-y-3">
      <InputWithLabel
        placeholder="API Key"
        value={apiKey}
        onChange={setApiKey}
      />
      <div className="rounded-md bg-indigo-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-indigo-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between items-center gap-4">
            <p className="text-sm text-indigo-700">
              {integrationType === "A"
                ? "You selected an account-based integration. When moving leads from InProfiler to Affinity, they will always be assigned to the Affinity user related to this API-Key."
                : "You selected a user-based integation. This means that other users on the account will need to connect with their own API keys once you have completed the setup."}
            </p>
            <button
              onClick={() => setStep(1)}
              className="inline-flex justify-center rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-indigo-600 "
            >
              Change
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
