import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import useInputState from "../../hooks/useInputState";
import { Link, Navigate, useLocation } from "react-router-dom";
import Logo from "../../img/logo.png";
import SetupObjectives from "../../components/Authentication/Register/SetupObjectives";
import SetupAccount from "../../components/Authentication/Register/SetupAccount";
import { useUserSetup } from "../../api/UserAPI";

export default function Setup() {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { setCurrentAccountId } = useContext(SubscriptionContext);
  const [jobTitle, setJobTitle] = useInputState("");
  const [company, setCompany, overwriteCompany] = useInputState("");
  const [objective, setObjective] = useInputState("");
  const [errors, setErrors] = useState();
  const [responseData, setResponseData] = useState();
  const [selectedAccountType, setSelectedAccountType] = useState("company");
  const [setupStep, setSetupStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState();
  const [action, setAction] = useState();
  const userSetup = useUserSetup();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const urlAction = urlParams.get("action");
    setAction(urlAction);
  }, []);

  const nextStep = () => {
    if (setupStep < 2) {
      if (!company) {
        overwriteCompany(user.firstName);
      }
      setSetupStep(setupStep + 1);
    }
  };

  const prevStep = () => {
    if (setupStep > 1) {
      setSetupStep(setupStep - 1);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setErrors(null);
    if (!company || !jobTitle || !objective) {
      setErrors("Please fill all of the fields to continue");
      setLoading(false);
      return;
    }
    userSetup(jobTitle, company, objective, selectedAccountType)
      .then((data) => {
        setResponseData(data);
        // Change of account id triggers refresh of subscription context
        setCurrentAccountId(data.subscriptionAccount.id);
        setRedirect(true);
      })
      .catch((err) => {
        setErrors(err.response.data);
        setLoading(false);
      });
  };

  if (responseData && redirect) {
    return <Navigate to="/app/get-extension" />;
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="InProfiler" />
          <h1 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Let's get your account set up
          </h1>
        </div>

        {setupStep === 1 ? (
          <SetupAccount
            selectedAccountType={selectedAccountType}
            setSelectedAccountType={setSelectedAccountType}
            company={company}
            setCompany={setCompany}
            nextStep={nextStep}
          />
        ) : (
          <SetupObjectives
            objective={objective}
            setObjective={setObjective}
            company={company}
            setCompany={setCompany}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            errors={errors}
            prevStep={prevStep}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )}
        {action === "addAccount" && (
          <div className="flex justify-center mt-5">
            <Link
              className="text-gray-400 font-semibold hover:text-gray-500"
              to="/app/accounts"
            >
              Back to account selection
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
