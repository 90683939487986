import React, { useEffect } from "react";
import useToggleState from "../../hooks/useToggleState";
import FeedbackModal from "./FeedbackModal";
import SubscriptionPlansModal from "../SubscriptionPlans/SubscriptionPlansModal";

export default function BetaBanner({
  requestsMade,
  remainingRequests,
  getSubscriptionPlan,
  getUserActivity,
}) {
  const [showFeedbackModal, toggleShowFeedbackModal] = useToggleState(false);
  const [showPlansModal, toggleShowPlansModal] = useToggleState(false);

  useEffect(() => {
    if (!showFeedbackModal) {
      getSubscriptionPlan();
      getUserActivity();
    }
  }, [showFeedbackModal]);
  return (
    <div className="shadow-sm sm:rounded-lg bg-indigo-50 px-4 py-5 sm:p-6 border border-indigo-200">
      <SubscriptionPlansModal
        open={showPlansModal}
        toggleOpen={toggleShowPlansModal}
      />
      <FeedbackModal
        open={showFeedbackModal}
        toggleOpen={toggleShowFeedbackModal}
      />
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="max-w-5xl text-gray-600">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {remainingRequests && !requestsMade
              ? "Welcome to your free trial!"
              : remainingRequests && requestsMade
              ? `You have ${remainingRequests} lead imports left in your free trial`
              : remainingRequests <= 0 &&
                "You reached the limit of your free trial."}
          </h3>
          <p className="text-sm mt-1">
            {!requestsMade
              ? "You have 25 lead evaluations included in your free trial. Once you have imported leads, you can earn additional free credits by sharing your feedback with us."
              : "Unlock 50 additional free credits by sharing feedback about your experience with InProfiler with us."}
          </p>
        </div>
        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center gap-2">
          <button
            type="button"
            onClick={toggleShowPlansModal}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-gray-50"
          >
            View Plans
          </button>
          {!requestsMade ? (
            <button
              type="button"
              disabled
              className="inline-flex items-center rounded-md bg-indigo-400 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Send Feedback
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleShowFeedbackModal}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Send Feedback
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
