import React, { useState, useEffect } from "react";
import LeadColumn from "../../components/Admin/Prompts/LeadColumn/LeadColumn";
import UsersColumn from "../../components/Admin/Prompts/UsersColumn.js/UsersColumn";
import PromptTesting from "../../components/Admin/Prompts/PromptTesting/PromptTesting";

export default function Prompts() {
  const [selectedLead, setSelectedLead] = useState();
  const [selectedUser, setSelectedUser] = useState();

  return (
    <div className="content-height content-height-max bg-white">
      {/* <div className="px-8 py-6 border-b">
        <h2 className="text-3xl font-bold">Prompt Refinement</h2>
      </div> */}
      <div className="mx-auto w-full max-w-full grow lg:flex h-full">
        {/* Left sidebar & main wrapper */}
        <div className="xl:flex">
          <LeadColumn
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
          />
          <UsersColumn
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        </div>

        <div className="shrink-0 xl:flex-1 h-full">
          {/* Main area */}
          <PromptTesting
            selectedUser={selectedUser}
            selectedLead={selectedLead}
          />
        </div>
      </div>
    </div>
  );
}
