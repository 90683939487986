import { Fragment, useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { Switch } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import Logo from "../../img/logo.png";
import { classNames } from "../../helpers";
import { Link } from "react-router-dom";
import useToggleState from "../../hooks/useToggleState";
import {
  useStartCheckout,
  useListSubscriptionPlans,
  useCreateChangePlanSession,
} from "../../api/SubscriptionAPI";
import SubscriptionPlanItem from "./SubscriptionPlanItem";

export default function SubscriptionPlansModal({ open, toggleOpen }) {
  const { currentAccountId, subscriptionPlan, subscriptionAccount } =
    useContext(SubscriptionContext);
  const startCheckout = useStartCheckout();
  const listSubscriptionPlans = useListSubscriptionPlans();
  const createChangePlanSession = useCreateChangePlanSession();
  const [annual, toggleAnnual] = useToggleState(false);
  const [sessionUrl, setSessionUrl] = useState();
  const [error, setError] = useState();
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [plans, setPlans] = useState();

  useEffect(() => {
    if (!plans) {
      listSubscriptionPlans()
        .then((data) => setPlans(data))
        .catch((err) => console.log(err));
    }
  }, []);

  const handleStartCheckout = (priceId) => {
    setError(null);
    setLoadingCheckout(true);
    startCheckout(priceId, currentAccountId)
      .then((data) => {
        setSessionUrl(data.session_url);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoadingCheckout(false);
      });
  };

  const handleStartPlanChange = (newPriceId) => {
    setError(null);
    setLoadingCheckout(true);
    createChangePlanSession(
      subscriptionAccount.stripe_customer_id,
      subscriptionAccount.stripe_subscription_id,
      newPriceId
    )
      .then((data) => {
        setSessionUrl(data.session_url);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoadingCheckout(false);
      });
  };

  useEffect(() => {
    if (sessionUrl) {
      window.location.href = sessionUrl;
    }
  }, [sessionUrl]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-10">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={toggleOpen}
                    disabled={loadingCheckout}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <img src={Logo} />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-semibold leading-6 text-gray-900"
                    >
                      Select your Subscription Plan
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-lg text-gray-500">
                        Find the right InProfiler plan that suits your
                        professional networking needs. From personal to larger
                        team requirements, we've got you covered.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-4 mt-8 flex justify-between">
                  {error ? (
                    <p className="text-red-500">{error}</p>
                  ) : (
                    <div></div>
                  )}
                  <Switch.Group as="div" className="flex items-center">
                    <Switch
                      checked={annual}
                      onChange={toggleAnnual}
                      className={classNames(
                        annual ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          annual ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-sm">
                      <span className="font-medium text-gray-900">
                        Annual billing
                      </span>{" "}
                      <span className="text-gray-500">(2 months free)</span>
                    </Switch.Label>
                  </Switch.Group>
                </div>
                <div className="isolate mx-auto mt-5 grid max-w-md grid-cols-1 gap-y-8 sm:mt-5 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {plans &&
                    plans.map((plan, planIdx) => (
                      <SubscriptionPlanItem
                        plan={plan}
                        planIdx={planIdx}
                        planCount={plans.length}
                        annual={annual}
                        loadingCheckout={loadingCheckout}
                        handleStartCheckout={handleStartCheckout}
                        currentPlan={subscriptionPlan}
                        subscriptionAccount={subscriptionAccount}
                        handleStartPlanChange={handleStartPlanChange}
                      />
                    ))}
                </div>
                <div className="rounded-xl p-5 border mt-5 bg-indigo-50 flex justify-between items-center">
                  <div>
                    <h3 className="text-base font-semibold text-indigo-600">
                      Not sure which plan to choose?
                    </h3>
                    <p className="text-gray-500 text-sm">
                      Let us help you out. Simply let us know about your
                      requirements and we will help you find the solution that
                      suits you best.
                    </p>
                  </div>
                  <Link
                    to="/contact"
                    target="_blank"
                    disabled={loadingCheckout}
                    className="flex justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Contact us
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
