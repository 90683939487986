import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers";
import useAxios from "../../hooks/useAxios";
import { useGetLeadSuggestions } from "../../api/LeadsAPI";

export default function LeadsSuggestions() {
  const api = useAxios();
  const [leads, setLeads] = useState();
  const [loading, setLoading] = useState(false);
  const getLeadSuggestions = useGetLeadSuggestions();

  useEffect(() => {
    if (!leads && !loading) {
      setLoading(true);
      getLeadSuggestions()
        .then((data) => {
          setLeads(data);
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    }
  }, [loading, leads]);

  if ((leads && leads.length === 0) || !leads) {
    return;
  }

  return (
    <div className="w-full">
      <div className=" px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl font-medium leading-6 text-gray-900 mb-5">
          New leads that could interest you
        </h2>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {leads.slice(0, 3).map((lead) => (
            <Link
              to={`/app/leads/${lead.id}`}
              key={lead.id}
              className="overflow-hidden rounded-lg bg-white shadow border cursor-pointer hover:bg-gray-50"
            >
              <div className="p-5">
                <div className="flex items-center">
                  {lead.evaluation && (
                    <div
                      className={classNames(
                        lead.evaluation.evaluation_score >= 7
                          ? "bg-green-50 border-green-500 text-green-500"
                          : lead.evaluation.evaluation_score < 4
                          ? "bg-red-50 border-red-500 text-red-500"
                          : "bg-yellow-50 border-yellow-500 text-yellow-500",
                        "flex-shrink-0 border-2 rounded font-bold h-10 w-10 flex items-center justify-center"
                      )}
                    >
                      {lead.evaluation.evaluation_score}
                    </div>
                  )}
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-lg font-medium text-gray-900">
                        {lead.name}
                      </dt>
                      <dd>
                        <div className="text-sm font-medium text-gray-500">
                          {lead.headline
                            ? lead.headline
                            : lead.person_occupation}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
