import React from "react";
import useToggleState from "../../hooks/useToggleState";
import SubscriptionPlansModal from "../SubscriptionPlans/SubscriptionPlansModal";

export default function FreeTrialBanner({
  plan,
  requestsMade,
  remainingRequests,
}) {
  const [showModal, toggleShowModal] = useToggleState(false);
  return (
    <div className="shadow-sm sm:rounded-lg bg-indigo-50 px-4 py-5 sm:p-6 border border-indigo-200">
      <SubscriptionPlansModal open={showModal} toggleOpen={toggleShowModal} />
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="max-w-xl text-gray-600">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {remainingRequests && !requestsMade
              ? "Welcome to your free trial!"
              : remainingRequests && requestsMade
              ? `You have ${remainingRequests} requests left in your free trial`
              : remainingRequests <= 0 &&
                "Your free trial is over, but that's not the end!"}
          </h3>
          <p className="text-sm mt-1">
            {!requestsMade
              ? "You have 25 lead evaluations included in your free trial. To keep using the service beyond this limit, please choose a susbcription plan."
              : "Choose a subscription plan to keep boosting your networking efforts with InProfiler."}
          </p>
        </div>
        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          <button
            type="button"
            onClick={toggleShowModal}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            View Plans
          </button>
        </div>
      </div>
    </div>
  );
}
