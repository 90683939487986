import React from "react";
import AutomaticLeadSharing from "../SetupSteps/AutomaticLeadSharing";
import { affinitySettingsPermissions } from "../../../../../permissions";

export default function AutoSyncSetting({
  enableAutoSync,
  toggleEnableAutoSync,
  minimumLeadScore,
  setMinimumLeadScore,
  integrationId,
  accountUser,
}) {
  if (!affinitySettingsPermissions.includes(accountUser.role.name)) {
    return;
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Automatic Lead Sync
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          If this setting is active, leads will automatically synced to Affinity
          if they match the selected evaluation threshold
        </p>
      </div>

      <div className="md:col-span-2">
        <AutomaticLeadSharing
          enableAutoSync={enableAutoSync}
          toggleEnableAutoSync={toggleEnableAutoSync}
          minimumLeadScore={minimumLeadScore}
          setMinimumLeadScore={setMinimumLeadScore}
          integrationId={integrationId}
        />
      </div>
    </div>
  );
}
