import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useInputState from "../../../../hooks/useInputState";
import useToggleState from "../../../../hooks/useToggleState";
import InputWithLabel from "../../../Forms/InputWithLabel";
import { useSavePrompt } from "../../../../api/AdminAPI";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../helpers";

export default function SavePromptModal({
  open,
  toggleOpen,
  prompt,
  setSaved,
}) {
  const [promptDescription, setPromptDescription, overwritePromptDescription] =
    useInputState("");
  const [setActive, toggleSetActive] = useToggleState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const savePrompt = useSavePrompt();

  useEffect(() => {
    if (prompt && prompt.description) {
      overwritePromptDescription(prompt.description);
    }
  }, [prompt]);

  const handleClick = () => {
    setError(null);
    setLoading(true);
    setSaved(false);
    savePrompt(prompt, promptDescription, setActive)
      .then((data) => {
        setSaved(true);
        setLoading(false);
        toggleOpen();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Save Prompt
                    </Dialog.Title>

                    <div className="my-3 space-y-4">
                      <InputWithLabel
                        label="Prompt Description"
                        value={promptDescription}
                        onChange={setPromptDescription}
                        placeholder="Prompt Version X"
                        small
                      />
                      <Switch.Group as="div" className="flex items-center">
                        <Switch
                          checked={setActive}
                          onChange={toggleSetActive}
                          className={classNames(
                            setActive ? "bg-indigo-600" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              setActive ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3 text-sm">
                          <span className="font-medium text-gray-900">
                            Set Prompt Active
                          </span>
                        </Switch.Label>
                      </Switch.Group>
                    </div>
                    {error && (
                      <div className="rounded-lg bg-red-50 p-3">
                        <p className="text-red-500 text-sm">{error}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-8 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto disabled:bg-indigo-300"
                    onClick={handleClick}
                    disabled={loading}
                  >
                    {loading ? "Saving" : "Save Prompt"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleOpen}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
