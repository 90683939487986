import { Fragment, useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import MultiSelectDropdown from "../../UtilityComponents/MultiSelectDropdown";
import { useGetAccountUsers } from "../../../api/UserAPI";
import { useShareLead } from "../../../api/LeadsAPI";

const shareOptions = [
  { name: "Share with everyone", key: "all" },
  { name: "Share with selected users", key: "selected" },
];

export default function ShareLeadModal({ open, toggleOpen, leadId }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [accountUsers, setAccountUsers] = useState();
  const [error, setError] = useState();
  const [sharePreference, setSharePreference] = useState("all");
  const [shared, setShared] = useState(false);
  const getAccountUsers = useGetAccountUsers();
  const shareLead = useShareLead();
  const { accountUser } = useContext(SubscriptionContext);

  useEffect(() => {
    getAccountUsers()
      .then((data) => {
        // Filter out the current user from the options
        const dataWithoutCurrentUser = data.filter(
          (user) => user.id !== accountUser.id
        );

        setAccountUsers(
          dataWithoutCurrentUser.map((accountUser) => ({
            id: accountUser.id,
            name: accountUser.user.full_name,
          }))
        );
      })
      .catch((err) => setError(err.response.data.message));
  }, []);

  const handleClick = () => {
    setError(null);
    shareLead(leadId, sharePreference, selectedUsers)
      .then((data) => {
        setShared(true);
      })
      .catch((err) => setError(err.response.data.message));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserPlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Share Lead with your team
                    </Dialog.Title>
                    <div className="mt-1">
                      <p className="text-sm text-gray-500">
                        This lead will become available to everyone you share it
                        with
                      </p>
                    </div>
                    {shared ? (
                      <div className="my-3 bg-green-50 flex items-center justify-center rounded-lg p-10">
                        <span className="text-lg font-bold text-green-600">
                          Lead Shared successfully
                        </span>
                      </div>
                    ) : (
                      <div className="my-3">
                        <fieldset>
                          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                            {shareOptions.map((option) => (
                              <div
                                key={option.key}
                                className="relative flex items-start py-4 cursor-pointer"
                                onClick={() => setSharePreference(option.key)}
                              >
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                  <label
                                    htmlFor={`option-${option.key}`}
                                    className="select-none font-medium text-gray-900"
                                  >
                                    {option.name}
                                  </label>
                                </div>
                                <div className="ml-3 flex h-6 items-center">
                                  <input
                                    id={`option-${option.key}`}
                                    name="plan"
                                    type="radio"
                                    checked={sharePreference === option.key}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                        {sharePreference === "selected" && (
                          <div className="mt-3">
                            <MultiSelectDropdown
                              options={accountUsers && accountUsers}
                              selectedState={selectedUsers}
                              setSelectedState={setSelectedUsers}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                  </div>
                </div>
                {!shared && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      onClick={handleClick}
                    >
                      Share
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={toggleOpen}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
