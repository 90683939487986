import useAxios from "../hooks/useAxios";

// Get all of the user's leads
export const useGetUserLeads = () => {
  const api = useAxios();

  const getUserLeads = () => {
    return api
      .get("get-leads/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return getUserLeads;
};

// Get all of the leads that have been shared with the user
export const useGetSharedLeads = () => {
  const api = useAxios();

  const getSharedLeads = () => {
    return api
      .get("get-shared-leads/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getSharedLeads;
};

// Get Single Lead Detail
export const useGetLeadDetail = () => {
  const api = useAxios();

  const getLeadDetail = (id) => {
    return api
      .get(`get-leads/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return getLeadDetail;
};

// Set lead status to seen
export const useUpdateSeenStatus = () => {
  const api = useAxios();

  const updateSeenStatus = (id) => {
    return api
      .put(`update-seen-status/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };
  return updateSeenStatus;
};

// Delete Lead
export const useDeleteLead = () => {
  const api = useAxios();

  const deleteLead = (id) => {
    return api
      .delete(`delete-lead/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return deleteLead;
};

// Get new lead evaluation
export const useGetLeadEvaluation = () => {
  const api = useAxios();

  const getLeadEvaluation = (id) => {
    return api
      .get(`evaluate/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return getLeadEvaluation;
};

// Rate evaluation
export const useRateEvaluation = () => {
  const api = useAxios();

  const rateEvaluation = (id, rating) => {
    return api
      .post(`rate-evaluation/${id}/`, JSON.stringify({ rating }))
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return rateEvaluation;
};

// Dashboard lead suggestions
export const useGetLeadSuggestions = () => {
  const api = useAxios();

  const useGetLeadSuggestions = () => {
    return api
      .get("get-leads/?limit=5&unseen=true&sort=score")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  return useGetLeadSuggestions;
};

// Dashboard lead stats
export const useGetDashboardStats = () => {
  const api = useAxios();

  const getDashboardStats = () => {
    return api
      .get("dashboard-stats/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  return getDashboardStats;
};

// Share lead
export const useShareLead = () => {
  const api = useAxios();

  const shareLead = (id, shareMethod, selectedUsers) => {
    return api
      .post(`share-lead/${id}/`, JSON.stringify({ shareMethod, selectedUsers }))
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return shareLead;
};
