import React from "react";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../../helpers";

export default function AutomaticLeadSharing({
  enableAutoSync,
  toggleEnableAutoSync,
  minimumLeadScore,
  setMinimumLeadScore,
}) {
  return (
    <div className="space-y-5">
      <div className="flex justify-between items-center">
        <div>
          <Switch
            checked={enableAutoSync}
            onChange={toggleEnableAutoSync}
            className={classNames(
              enableAutoSync ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                enableAutoSync ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </div>
      </div>
      {enableAutoSync && (
        <div className="space-y-5">
          <span className="isolate inline-flex rounded-md shadow-sm w-full mb-1">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
              <button
                type="button"
                onClick={() => setMinimumLeadScore(num)}
                className={classNames(
                  minimumLeadScore === num
                    ? "bg-indigo-50 text-indigo-600 ring-indigo-600 hover:bg-indigo-100"
                    : "bg-white text-gray-900 ring-gray-300 hover:bg-gray-50",
                  "w-1/12 items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                )}
              >
                {num}
              </button>
            ))}
          </span>
          <span className="text-sm text-gray-500">
            Select Minimum Lead Score for automatic import
          </span>
        </div>
      )}
    </div>
  );
}
