import React, { useState } from "react";
import InputWithLabel from "../../../../Forms/InputWithLabel";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import useInputState from "../../../../../hooks/useInputState";
import {
  useConnectAffinityAccount,
  useDisconnectAffinityAccount,
} from "../../../../../api/AffinityAPI";
import ConfirmDeleteModal from "../../../../UtilityComponents/ConfirmDeleteModal";
import useToggleState from "../../../../../hooks/useToggleState";

export default function ConnectAccount({
  integration,
  getIntegration,
  accountUser,
}) {
  const connectAffinityAccount = useConnectAffinityAccount();
  const disconnectAffinityAccount = useDisconnectAffinityAccount();
  const [apiKey, setApiKey] = useInputState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showDisconnectModal, toggleShowDisconnectModal] =
    useToggleState(false);

  const connect = () => {
    setError(null);
    setLoading(true);

    if (!apiKey) {
      setError("Please enter your API Key");
      setLoading(false);
      return;
    }

    connectAffinityAccount(apiKey)
      .then((data) => {
        setLoading(false);
        getIntegration();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const disconnect = () => {
    setError(null);
    setLoading(true);
    disconnectAffinityAccount()
      .then((data) => {
        setLoading(false);
        getIntegration();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <ConfirmDeleteModal
        handleConfirm={disconnect}
        open={showDisconnectModal}
        toggleOpen={toggleShowDisconnectModal}
        heading="Disconnect Affinity Account?"
        text="Your leads will no longer be synced with Affinity while your accout is disconnected"
        confirmCopy="Disconnect"
      />
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Connect your Account
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          {integration.integration_type === "U"
            ? "This integration requires each user to authenticate with their own Affinity API Key"
            : "Enter the API Key to use for the account based integration"}
        </p>
      </div>

      {(integration.integration_type === "U" &&
        !integration.connected_users.includes(accountUser.id)) ||
      (integration.integration_type === "A" && !integration.api_key) ? (
        <div className="w-full space-y-5">
          <div className="md:col-span-2 flex gap-3 items-end">
            <div className="w-2/3">
              <InputWithLabel
                placeholder="API Key"
                value={apiKey}
                onChange={setApiKey}
              />
            </div>
            <button
              onClick={connect}
              disabled={loading}
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 disabled:bg-gray-50 disabled:text-gray-400"
            >
              Connect
            </button>
          </div>
          <span className="text-gray-500 text-sm mt-1">
            Click{" "}
            <a
              className="text-blue-500"
              href="https://support.affinity.co/hc/en-us/articles/360032633992-How-to-obtain-your-API-Key"
              target="_blank"
            >
              here
            </a>{" "}
            to learn how to find your API Key
          </span>
          {error ? (
            <div className="p-2 bg-red-50 border border-red-700 text-sm text-red-700 rounded-lg flex gap-2">
              <ExclamationTriangleIcon className="h-5 w-5" />
              <span>{error}</span>
            </div>
          ) : (
            <div className="p-2 bg-yellow-50 border border-yellow-700 text-sm text-yellow-700 rounded-lg flex gap-2">
              <ExclamationTriangleIcon className="h-5 w-5" />
              <span>
                Your integration won't work until an account is connected
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="text-sm px-2 py-2 bg-green-50 text-green-700 rounded-lg border border-green-700 flex items-center justify-between">
          <div className="flex gap-2 items-center">
            <CheckCircleIcon className="h-5 w-5" />
            <span>Your account is connected</span>
          </div>
          <button
            disabled={loading}
            onClick={toggleShowDisconnectModal}
            className="font-semibold disabled:bg-gray-50 disabled:text-gray-400"
          >
            Disconnect
          </button>
        </div>
      )}
    </div>
  );
}
