import React, { useEffect, useState, useContext } from "react";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import ProfileSettings from "../../components/Settings/Profile/ProfileSettings";
import ObjectivesSettings from "../../components/Settings/Objectives/ObjectivesSettings";
import AccountUsers from "../../components/Settings/AccountUsers/AccountUsers";
import AccountSettings from "../../components/Settings/AccountSettings/AccountSettings";
import SubscriptionSettings from "../../components/Settings/Subscription/SubscriptionSettings";
import IntegrationsSettings from "../../components/Settings/IntegrationsSettings/IntegrationsSettings";
import AffinitySettings from "../../components/Settings/IntegrationsSettings/Affinity/AffinitySettings";
import { Link, useParams } from "react-router-dom";

const secondaryNavigation = [
  { name: "Account", key: "account", permissions: ["Owner", "Admin"] },
  { name: "Users", key: "users", permissions: ["Owner", "Admin"] },
  {
    name: "Subscription",
    key: "subscription",
    permissions: ["Owner"],
  },
  {
    name: "Integrations",
    key: "integrations",
    permissions: ["Owner", "Admin"],
    plans: ["Pro", "Beta SI"],
  },
  {
    name: "Your Profile",
    key: "profile",
    permissions: ["Owner", "Admin", "Member"],
  },
  {
    name: "Objectives",
    key: "objectives",
    permissions: ["Owner", "Admin", "Member"],
  },
];

export default function Settings() {
  const { accountUser, subscriptionPlan } = useContext(SubscriptionContext);
  const [activeView, setActiveView] = useState();
  const [userMenu, setUserMenu] = useState([]);
  const params = useParams();

  useEffect(() => {
    const { view } = params;
    if (view) {
      setActiveView(view);
    } else {
      if (
        accountUser.role.name === "Owner" ||
        accountUser.role.name === "Admin"
      ) {
        setActiveView("account");
      } else {
        setActiveView("profile");
      }
    }
  }, [params]);

  useEffect(() => {
    if (accountUser) {
      setUserMenu(
        secondaryNavigation.filter((item) => {
          if (!item.plans) {
            return item.permissions.includes(accountUser.role.name);
          } else {
            return (
              item.permissions.includes(accountUser.role.name) &&
              item.plans.includes(subscriptionPlan.name)
            );
          }
        })
      );
    }
  }, [accountUser]);

  return (
    <div className="content-height content-height-max overflow-scroll bg-white">
      <main className="w-full">
        <header className="border-b border-white/5">
          {/* Secondary navigation */}
          <nav className="flex overflow-x-auto py-4 border-b bg-gray-50">
            <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-500 sm:px-6 lg:px-8">
              {userMenu.map((item) => (
                <Link to={`/app/settings/${item.key}`} key={item.key}>
                  <li>
                    <span
                      className={
                        activeView === item.key
                          ? "text-indigo-500"
                          : "hover:text-gray-700"
                      }
                    >
                      {item.name}
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
          </nav>
        </header>

        {/* Settings forms */}
        {activeView === "account" ? (
          <AccountSettings userRole={accountUser.role.name} />
        ) : activeView === "profile" ? (
          <ProfileSettings userRole={accountUser.role.name} />
        ) : activeView === "objectives" ? (
          <ObjectivesSettings userRole={accountUser.role.name} />
        ) : activeView === "users" ? (
          <AccountUsers userRole={accountUser.role.name} />
        ) : activeView === "subscription" ? (
          <SubscriptionSettings userRole={accountUser.role.name} />
        ) : activeView === "integrations" ? (
          <IntegrationsSettings />
        ) : activeView === "affinity" ? (
          <AffinitySettings />
        ) : (
          <ProfileSettings />
        )}
      </main>
    </div>
  );
}
