import useAxios from "../hooks/useAxios";
import { getCookie } from "../helpers";

// Submit data on user onboarding
export const useUserSetup = () => {
  const api = useAxios();

  const userSetup = (jobTitle, company, objective, selectedAccountType) => {
    return api
      .post(
        "subscription/account-setup/",
        JSON.stringify({
          jobTitle,
          company,
          objective,
          selectedAccountType,
        })
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return userSetup;
};

// Setup for invited user
export const useInvitedUserSetup = () => {
  const api = useAxios();

  const invitedUserSetup = (company, objective, jobTitle) => {
    return api
      .put("subscription/account-user/", {
        company,
        objective,
        job_title: jobTitle,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return invitedUserSetup;
};

// Change password
export const useChangePassword = () => {
  const api = useAxios();

  const changePassword = (currentPassword, newPassword1, newPassword2) => {
    return api
      .post(
        "users/change-password/",
        JSON.stringify({ currentPassword, newPassword1, newPassword2 })
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  return changePassword;
};

// Delete account
export const useDeleteAccount = () => {
  const api = useAxios();

  const deleteAccount = (deleteReason) => {
    return api
      .post("subscription/delete-account/", { deleteReason })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return deleteAccount;
};

// Update User Profile
export const useUpdateUser = () => {
  const api = useAxios();

  const updateUser = (userId, firstName, lastName) => {
    return api
      .put(
        `users/router/users/${userId}/`,
        JSON.stringify({
          first_name: firstName,
          last_name: lastName,
        })
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return updateUser;
};

// Update profile image
export const useUpdateProfileImage = () => {
  const api = useAxios();

  const updateProfileImage = (userId, formData, config) => {
    return api
      .put(`users/update-profile-image/${userId}/`, formData, config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  return updateProfileImage;
};

// Get user accounts and invites
export const useGetUserAccountsAndInvites = () => {
  const api = useAxios();

  const getUserAccountsAndInvites = () => {
    return api
      .get("users/get-user-accounts-invites/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getUserAccountsAndInvites;
};

export const useAcceptInvite = () => {
  const api = useAxios();

  const acceptInvite = (token) => {
    return api
      .post("users/accept-invite/", { token })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return acceptInvite;
};

export const useRejectInvite = () => {
  const api = useAxios();

  const rejectInvite = (token) => {
    return api
      .post("users/reject-invite/", { token })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return rejectInvite;
};

export const useSelectAccount = () => {
  const api = useAxios();

  const selectAccount = (accountId) => {
    return api
      .post("users/set-current-account/", { accountId })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  return selectAccount;
};

export const useUpdateObjectives = (jobTitle, company, objective) => {
  const api = useAxios();

  const updateObjectives = () => {
    return api
      .put(
        "subscription/account-user/",
        JSON.stringify({
          job_title: jobTitle,
          company,
          objective,
        })
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return updateObjectives;
};

export const useGetAccountUsers = () => {
  const api = useAxios();

  const getAccountUsers = () => {
    return api
      .get("subscription/account-users/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  return getAccountUsers;
};

export const useInviteUser = () => {
  const api = useAxios();

  const inviteUser = (email, selectedRole) => {
    return api
      .post("users/invite-user/", { email, selectedRole })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
  return inviteUser;
};

export const useGetInvites = () => {
  const api = useAxios();

  const getInvites = () => {
    return api
      .get("users/invite-user/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getInvites;
};

export const useDeleteInvite = () => {
  const api = useAxios();

  const deleteInvite = (id) => {
    return api
      .delete(`users/delete-invite/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return deleteInvite;
};

export const useRemoveUser = () => {
  const api = useAxios();

  const removeUser = (userToDelete) => {
    return api
      .delete(`subscription/account-users/${userToDelete}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return removeUser;
};

export const useCreateInviteLink = () => {
  const api = useAxios();

  const createInviteLink = () => {
    return api
      .post("subscription/create-invite-link/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return createInviteLink;
};

export const useCheckInviteLink = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const csrfToken = getCookie("csrftoken");

  const checkInviteLink = async (token) => {
    try {
      const response = await fetch(
        `${baseUrl}subscription/check-invite-link/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({ token }),
        }
      );

      if (!response.ok) {
        throw new Error("Error checking the invite link");
      }

      const data = await response.json();
      return data;
    } catch (err) {
      throw err;
    }
  };

  return checkInviteLink;
};

export const useCreateInviteFromLink = () => {
  const api = useAxios();

  const createInviteFromLink = (token) => {
    return api
      .post("subscription/create-invite-from-link/", { token })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return createInviteFromLink;
};

export const useChangeRole = () => {
  const api = useAxios();

  const changeRole = (changeUserId, newRole) => {
    return api
      .post("subscription/change-user-role/", {
        changeUserId,
        newRole,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return changeRole;
};
