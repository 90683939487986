import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../helpers";

// Helper function to get nested object property
function getNestedProperty(obj, keyPath) {
  return keyPath
    .split(".")
    .reduce(
      (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
      obj
    );
}

export default function StyledSelect({
  label,
  choices,
  selected,
  setSelected,
  placeholder,
  expandUp = false,
  highlight = false,
  labelKey = "name",
}) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button
              className={classNames(
                highlight
                  ? "bg-white text-indigo-600 ring-indigo-500"
                  : "bg-white text-gray-900 ring-gray-300",
                "relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              )}
            >
              <span className="block truncate">
                {selected
                  ? getNestedProperty(selected, labelKey)
                  : choices.length === 0
                  ? "No options available"
                  : placeholder
                  ? placeholder
                  : "Select option"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  expandUp ? "bottom-10" : "",
                  "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border"
                )}
              >
                {choices.map((choice) => (
                  <Listbox.Option
                    key={getNestedProperty(choice, labelKey)}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={choice}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {getNestedProperty(choice, labelKey)}
                        </span>
                        {choice.selectDescription && (
                          <span
                            className={classNames(
                              active ? "text-gray-50" : "text-gray-500",
                              "font-normal"
                            )}
                          >
                            {choice.selectDescription}
                          </span>
                        )}

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
