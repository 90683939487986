import React, { useState, useEffect, useRef } from "react";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useGetAffinityFields } from "../../../../../../api/AffinityAPI";
import StyledSelect from "../../../../../UtilityComponents/StyledSelect";
import MappingItem from "./MappingItem";
import { staticMapping } from "../../constants/initialMapping";

export default function MapFields({
  entity,
  fieldMapping,
  setFieldMapping,
  additionalFieldOptions,
  setError,
}) {
  const getAffinityFields = useGetAffinityFields();
  const containerRef = useRef(null);
  const [affinityFields, setAffinityFields] = useState([]);
  const [addField, setAddField] = useState();

  const fetchFields = () => {
    getAffinityFields(entity)
      .then((data) => {
        setAffinityFields(data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    if (entity) {
      fetchFields();
    }
  }, [entity]);

  useEffect(() => {
    if (addField) {
      setFieldMapping((prevState) => [...prevState, addField]);
      setAddField(null);
    }
  }, [addField]);

  useEffect(() => {
    // Scroll to the bottom when messages change
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [additionalFieldOptions]);

  const handleFieldset = (fieldIdx, targetField) => {
    setFieldMapping((prevState) =>
      prevState.map((item, i) => {
        if (i !== fieldIdx) {
          return item;
        }
        return {
          ...item,
          targetField: {
            id: targetField.id,
            type: targetField.value_type,
            allows_multiple: targetField.allows_multiple,
          },
        };
      })
    );
  };

  const removeItem = (field) => {
    setFieldMapping((prevState) => prevState.filter((item) => item != field));
  };

  return (
    <div className="space-y-5">
      <div className="space-y-3 " ref={containerRef}>
        <div className="flex gap-8 font-semibold text-sm">
          <span className="w-1/3">InProfiler Data</span>
          <ArrowRightIcon className="h-6 w-6 invisible" />
          <span className="w-1/3">Affinity Field</span>
        </div>
        {staticMapping[entity].map((item) => (
          <div
            className="flex items-center gap-8 justify-start"
            key={item.name}
          >
            <div className="py-1.5 px-3 border  border-gray-300 w-1/3 rounded-lg shadow-sm">
              <span className="text-sm">{item.name}</span>
            </div>
            <ArrowRightIcon className="h-6 w-6" />
            <div className="w-1/2">
              <div className="py-1.5 px-3 border  border-gray-300 rounded-lg shadow-sm">
                <span className="text-sm text-gray-500">
                  {item.targetFieldName}
                </span>
              </div>
            </div>
          </div>
        ))}
        {fieldMapping.map((field, idx) => (
          <div className="flex items-center gap-8 justify-start" key={idx}>
            <div className="py-1.5 px-3 border  border-gray-300 w-1/3 rounded-lg shadow-sm">
              <span className="text-sm">{field.fieldProperties.name}</span>
            </div>
            <ArrowRightIcon className="h-6 w-6" />
            <MappingItem
              affinityFields={affinityFields}
              field={field}
              index={idx}
              handleFieldset={handleFieldset}
            />
            <button
              onClick={() => removeItem(field)}
              className="text-gray-500 p-1.5 rounded bg-gray-50 shadow-sm border"
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        ))}
      </div>
      <div className="w-1/3 font-semibold">
        <StyledSelect
          choices={additionalFieldOptions}
          highlight
          placeholder="+ Add Field"
          selected={addField}
          setSelected={setAddField}
          expandUp
          labelKey="fieldProperties.name"
        />
      </div>
    </div>
  );
}
