const terms = [
  {
    term: "Account",
    meaning:
      "Means an account required to access and/or use certain areas and features of Our Site",
  },
  {
    term: "Cookie",
    meaning:
      "Means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in section 12, below",
  },
  { term: "Our Site", meaning: "Means this website, Website_name" },
  {
    term: "Personal Information",
    meaning:
      "Means any information relating to an identified or identifiable natural person",
  },
  {
    term: "Process",
    meaning:
      "Means any operation or set of operations which is performed on Personal Information or sets of Personal Information, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction",
  },
  {
    term: "Services",
    meaning:
      "Means the services provided by InProfiler including the importation, presentation and automatic evaluation of leads coming from the user's Linkedin Profile",
  },
  {
    term: "UK and EU Cookie Law",
    meaning:
      "Means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended in 2004, 2011 and 2015; and 2017",
  },
  {
    term: "We/Us/Our",
    meaning:
      "Means InProfiler, developed and represented by Elbo Pte Ltd",
  },
];

const rights = [
  {
    right: "Right to be informed",
    description:
      "You are entitled to know whether we hold your Personal Information and the purpose your Personal Information is processed for.",
  },
  {
    right: "Right of access",
    description:
      "You are entitled to obtain a copy of your Personal Information, together with an explanation of the categories of Personal Information being Processed, the purposes of such Processing, and the details of third parties to whom the Personal Information may have been disclosed to.",
  },
  {
    right: "Right to rectification",
    description:
      "You are entitled to correct / update your Personal Information available with us.",
  },
  {
    right: "Right to erasure",
    description:
      "You are entitled to get your Personal Information erased from Our customer relationship management (CRM) databases. Please note that we need to retain certain Personal Information about you for legal and internal business reasons, such as fraud prevention. We will retain your Personal Information for as long as necessary to provide you with the websites and apps you are eligible to use and as needed to comply with our legal obligations and enforce our agreements.",
  },
  {
    right: "Right to data portability",
    description:
      "You are entitled to obtain and reuse your Personal Information. You can either obtain the Personal Information from Us or, in turn, provide it to a third party (if you so wish), or ask Us to transfer your Personal Information directly to a third party.",
  },
  {
    right: "Right to object/restrict Processing or both",
    description:
      "You have a right to object / restrict the Processing of your Personal Information in some circumstances, including where the Personal Information is inaccurate (for the period during which we are verifying the Personal Information), the Personal Information is no longer required in light of the purpose of Processing, or in connection with direct marketing (you can prevent / discontinue marketing communications to you by checking certain boxes on the forms we use to collect your Personal Information), or by utilizing opt-out mechanisms in the emails we send you. You shall also have the right not to be subject to a decision based solely on automated Processing, including profiling, which produces legal effects concerning and similarly significantly affecting you.",
  },
];

export default function Privacy() {
  return (
    <div className="bg-white px-6 py-8 lg:py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Privacy Policy
        </h1>
        <p className="mt-6 text-xl leading-8 mb-6">
          Please read this Privacy Policy carefully and ensure that you
          understand it. If you have any questions regarding this Privacy
          Policy, the practices of this website or your dealings with this
          website, you may contact Us at{" "}
          <a href="mailto:hello@inprofiler.com" className="text-blue-500">
            hello@inprofiler.com
          </a>
          .
        </p>
        <p className="mb-3">
          In this Privacy Policy (“Policy”), references to “We”, “Us” or “Our”
          means InProfiler, represented by Elbo Pte Ltd.
        </p>
        <p className="mb-3">
          This Privacy Policy shall govern Our use of any Personal Information
          collected by Us concerning your use of InProfiler (“Our Site”). The
          use of information collected through Our Site shall be limited to the
          purposes under this Policy.
        </p>
        <p className="mb-3">
          InProfiler understands that your privacy is important to you and that
          you care about how your personal and non-personal information is used
          and shared online. We respect and value the privacy of everyone who
          visits Our Site and will only collect and use information in ways that
          are useful to you and in a manner consistent with your rights and Our
          obligations under the law.
        </p>
        <div className="mt-10 max-w-3xl">
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              1. Introduction
            </h2>
            <p className="mt-6">
              This Policy applies to Our Processing of your Personal Information
              when you access and/or use Our Site. This Policy would apply to
              you as a visitor, end-user, representative, end-customer, as
              applicable.
            </p>
            <p className="mt-6">
              This Privacy Policy applies only to your use of Our Site and does
              not extend to any websites that are linked to from Our Site
              (whether We provide those links or whether they are shared by
              other users). We have no control over how your data is collected,
              stored or used by other websites and We advise you to check the
              privacy policies of any such websites before providing any
              information to them.
            </p>
          </section>

          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              2. Definition and Interpretation
            </h2>

            <p className="mt-5">
              In this Policy the following terms shall have the following
              meanings:
            </p>

            <div className="mx-auto max-w-3xl my-6">
              <table className="min-w-full divide-y divide-gray-300">
                <tbody className="divide-y divide-gray-200 bg-white">
                  {terms.map((term) => (
                    <tr key={term.term} className="divide-x divide-gray-200">
                      <td className="py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                        {term.term}
                      </td>
                      <td className="p-4 text-sm text-gray-500">
                        {term.meaning}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              3. Personal Identification Information We Collect
            </h2>
            <p className="mt-6">
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, subscribe to the newsletter, fill out a form, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, phone number.
              Users may, however, visit our Site anonymously. We will collect
              personal identification information from Users only if they
              voluntarily submit such information to us. Users can always refuse
              to supply personal identification information, except that it may
              prevent them from engaging in certain Site related activities.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              4. Non-personal Identification Information We Collect
            </h2>
            <p className="mt-6">
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer,
              and technical information about Users’ means of connection to our
              Site, such as the operating system and the Internet service
              providers utilized and other similar information.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              5. How We May Use Collected Information
            </h2>
            <p className="mt-6">
              We will use your Personal Information only as described in this
              Privacy Policy and shall Process your Personal Information only if
              We have a legal basis to. We may use your Personal Information in
              the following ways:
            </p>
            <ol className="mt-6 pl-5 space-y-2">
              <li>1. To respond to communications from you</li>
              <li>2. To carry out market research</li>
              <li>3. To analyze your use Of our site</li>
              <li>
                4. To gather feedback to enable Us to continually improve Our
                site and your user experience
              </li>
              <li>
                5. To adapt your preferences to your experience on Our site
              </li>
            </ol>
            <p className="mt-6">
              In addition to this, with your permission and/or where permitted
              by law, We may also use your Personal Information for marketing
              purposes which may include contacting you by email AND/OR
              telephone AND/OR text message AND/OR post AND/OR web push
              notifications with information, news and offers on Our Services.
            </p>
            <p className="mt-6">
              We will not, however, send you any spam and will take all
              reasonable steps to ensure that we comply with the applicable law
              in this regard. InProfiler may also do lead generation activities
              by adding a call to action buttons/banner/popups for companies
              with a profile page on the website.
            </p>
            <p className="mt-6">
              Furthermore, advertisers whose content appears on Our Site may
              engage in what is known as “behavioral advertising” i.e.
              advertising which is tailored to your preferences, based on your
              activity. Your activity is monitored using Cookies, as detailed
              below in section 12. You can control and limit your information
              used in this way by adjusting your web browser’s privacy settings.
            </p>
            <p className="mt-6">
              Please note that We do not control the activities of such
              advertisers, nor the information they collect and use. Limiting
              the use of your Personal Information in this way will not remove
              the advertising, but it will make it less relevant to your
              interests and activities on Our Site.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              6. Data Retention Period
            </h2>
            <p className="mt-6">
              InProfiler retains the Personal Information collected where an
              ongoing legitimate business requires retention of such Personal
              Information such as for litigation/defense purposes, as necessary
              to comply with our legal obligations, maintain accurate financial
              and other records, resolve disputes, and enforce our agreements.
            </p>
            <p className="mt-6">
              In the absence of a need to retain your Personal Information as
              specified herein, We will either delete or aggregate your Personal
              Information or, if this is not possible then We will securely
              store your Personal Information and isolate it from any further
              Processing until deletion is possible.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              7. Data Security
            </h2>
            <p className="mt-6">
              Your Personal Information is extremely important to us. We use
              appropriate technical and organizational measures to protect the
              Personal Information that We collect and Process. The measures We
              use are designed to provide a level of security appropriate to the
              risk of Processing your Personal Information. If you have
              questions about the security of your Personal Information, please
              contact Us immediately as described in this Policy.
            </p>
            <p className="mt-6">
              Notwithstanding the security measures that We take, it is
              important to remember that the transmission of data via the
              internet may not be completely secure and that you are advised to
              take suitable precautions when transmitting to Us data via the
              internet.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              8. Disclosure of Information to Third Parties/Partners
            </h2>
            <ol className="mt-6 space-y-6">
              <li>
                1. We may share your Personal Information with other companies
                in Our group. This includes Our subsidiaries AND/OR Our holding
                company and its subsidiaries.
              </li>
              <li>
                2. We may compile statistics about the use of Our Site including
                data on traffic, usage patterns, user numbers, sales, and other
                information. All such data will be anonymized and will not
                include any personally identifying information. We may from time
                to time share such data with third parties such as prospective
                investors, affiliates, partners, and advertisers.
              </li>
              <li>
                3. In certain circumstances, We may be legally required to share
                certain data held by Us, which may include your Personal
                Information, for example, where We are involved in legal
                proceedings or where We are complying with the requirements of
                legislation, a court order, or a governmental authority. We do
                not require any further consent from you in order to share your
                data in such circumstances and will comply as required with any
                legally binding request that is made of Us.
              </li>
              <li>
                4. As a visitor, end-user, representative, end-customer, using
                InProfiler's Services as defined herein, any data you provide in
                the process may be shared with vendors who may reach out to you
                in connection with the Services and/or to send you relevant
                marketing collateral with your permission and/or as permitted
                under law.
              </li>
              <li>
                5. We may contract with third parties to supply products and
                services to you on Our behalf. These may include payment
                processing, delivery of goods, search engine facilities amongst
                other functions we undertake to cater our Services to you. In
                some cases, the third parties may require access to some or all
                of your data. Where any of your data is required for such a
                purpose, We will take all reasonable steps to ensure that your
                data will be handled safely, securely, and in accordance with
                your rights, Our obligations, and the obligations of the third
                party under the law.
              </li>
              <li>
                6. We may also share your Personal Information if we merge with
                or are acquired by another company. In such a case, your
                information will likely be one of the assets that is
                transferred.
              </li>
            </ol>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              9. Children’s Privacy
            </h2>
            <p className="mt-6">
              We recognize the importance of children’s safety and privacy. We
              do not request, or knowingly collect, any personally identifiable
              information from children under the age of 16. If a parent or
              guardian becomes aware that his or her child has provided Us with
              Personal Information, he or she should write to Us at the email
              address set forth in this Policy.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              10. International Transfer of Personal Information
            </h2>
            <p className="mt-6">
              Your Personal Information and files including their backups are
              stored on our servers and the servers of companies we hire to
              provide services to Us. Your Personal Information may be
              transferred across national borders because We have servers
              located in Germany, and the companies we hire to help Us run our
              business may be located in different countries around the world.
            </p>
            <h3 className="mt-10 text-xl font-bold tracking-tight text-gray-900">
              1. International Transfer
            </h3>
            <p className="mt-5">
              The information that we collect from you may be transferred to and
              stored in a country outside the European Economic Area (EEA). It
              may also be Processed by staff operating outside the EEA who work
              for Us or our partners.
            </p>
            <p className="mt-5">
              If you are a resident of the European Economic Area and when your
              Personal Information is Processed outside EEA, We will ensure that
              the recipient of your Personal Information offers an adequate
              level of protection, for instance by entering into standard
              contractual clauses for the transfer of Personal Information as
              approved by the European Commission (Article 46 General Data
              Privacy Regulation, 2016), or We will ask you for your prior
              consent to such international data transfers.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              11. Summary of Your Rights Under GDPR
            </h2>
            <h3 className="mt-10 text-xl font-bold tracking-tight text-gray-900">
              (A) European Privacy Rights
            </h3>
            <p className="mt-6">
              If you are a resident of the European Economic Area, you have the
              following rights
            </p>
            <table className="min-w-full divide-y divide-gray-300 mt-6">
              <tbody className="divide-y divide-gray-200 bg-white">
                {rights.map((right) => (
                  <tr key={right.right} className="divide-x divide-gray-200">
                    <td className="py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                      {right.right}
                    </td>
                    <td className="p-4 text-sm text-gray-500">
                      {right.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="mt-6">
              In the above cases, we will retain minimum Personal Information to
              note that you opted out from being contacted again. For the
              exercise of any of the above-mentioned rights, please write to Us
              at{" "}
              <a href="mailto:hello@inprofiler.com" className="text-blue-500">
                hello@inprofiler.com
              </a>
              .
            </p>
            <h3 className="mt-10 text-xl font-bold tracking-tight text-gray-900">
              (B) California Privacy Rights
            </h3>
            <p className="mt-6">
              If you are a resident of California, this section provides
              additional details about the personal information we collect about
              you, and your rights under the California Consumer Privacy Act, or
              “CCPA.”
            </p>
            <p className="mt-6">
              Subject to certain limitations, the CCPA provides you the right to
              request:
            </p>
            <ul className="pl-5 space-y-2 mt-6">
              <li>
                - That we provide you access to details on the categories or
                specific pieces of personal information we collect and/or sell
                (including how we use and disclose this information, to whom we
                may sell it);
              </li>
              <li>- That we delete any of your personal information</li>
              <li>
                - To opt out of any “sale” of your personal information that may
                occur
              </li>
              <li>
                - To not be discriminated against for exercising any of the
                above rights.
              </li>
            </ul>
            <p className="mt-6">
              If you would like to request that we exercise your California
              privacy rights under the CCPA, please send us an email. We will
              verify your request using information associated with your
              account, including your email address. Further identification may
              be required. You may also designate an authorized representative
              to act on your behalf.
            </p>
            <p className="mt-6">
              InProfiler will not sell your personal information without your
              prior consent. If you want to opt out of the sale of your personal
              information, please send us an email.
            </p>
            <h3 className="mt-10 text-xl font-bold tracking-tight text-gray-900">
              (C) Nevada Privacy Rights
            </h3>
            <p className="mt-6">
              Nevada law requires us to share with certain Nevada residents the
              option to opt out of the “sale” of “personally identifiable
              information,” as defined by Nevada law. We do not engage in such
              activity; however, if you are a Nevada resident who has purchased
              goods or services from us, you may submit a request under Nevada
              law to opt out of any potential future sales by emailing
              <a href="mailto:hello@inprofiler.com" className="text-blue-500">
                hello@inprofiler.com
              </a>
              .
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              12. Web-browser Cookies & Similar Technologies
            </h2>
            <p className="mt-5">
              A cookie is a small piece of data (text file) that a website –
              when visited by a user – asks your browser to store on your device
              in order to remember information about you, such as your language
              preference or login information. Those cookies are set by us and
              called first-party cookies. We also use third-party cookies –
              which are cookies from a domain different from the domain of the
              website you are visiting – for our advertising and marketing
              efforts.
            </p>
            <p className="mt-6">
              1. Our Site may place and access certain first party Cookies on
              your computer or device. First party Cookies are those placed
              directly by Us and are used only by Us. We use Cookies to
              facilitate and improve your experience of Our Site and to provide
              and improve Our Services. For more details, please refer to
              section 5, above and to the section below, We have carefully
              chosen these Cookies and have taken steps to ensure that your
              privacy is protected and respected at all times.
            </p>
            <p className="mt-6">
              2. By using Our Site you may also receive certain third party
              Cookies on your computer or device. Third party Cookies are those
              placed by websites, Services, and/or parties other than Us. We use
              third party Cookies on Our Site for multiple reasons. For more
              details, please refer to section 5, above, and to section below.
              These Cookies are not integral to the functioning of Our Site.
            </p>
            <p className="mt-6">
              3. All Cookies used by and on Our Site are used in accordance with
              current English and EU Cookie Law.
            </p>
            <p className="mt-6">
              4. Before any Cookies are placed on your computer or device, you
              will be shown a prompt e.g. pop-up, message bar etc. requesting
              your consent to set those Cookies. By giving your consent to the
              placing of Cookies you are enabling Us to provide the best
              possible experience and service to you. You may, if you wish, deny
              consent to the placing of Cookies; however certain features of Our
              Site may not function fully or as intended.
            </p>
            <p className="mt-6">
              5. Certain features of Our Site depend on Cookies to function. UK
              and EU Cookie Law deems these Cookies to be “strictly necessary”.
              These Cookies are shown below. Your consent will not be sought to
              place these Cookies. You may still block these Cookies by changing
              your internet browser’s settings, but please be aware that Our
              Site may not work as intended if you do so. We have taken great
              care to ensure that your privacy is not at risk by allowing them.
            </p>

            <h3 className="mt-10 text-xl font-bold tracking-tight text-gray-900">
              Analytics Services
            </h3>

            <p className="mt-5">
              Our Site uses analytics services provided by Google and Facebook
              Website analytics refers to a set of tools used to collect and
              analyze usage statistics, enabling Us to better understand how
              people use Our Site. This, in turn, enables Us to improve Our Site
              and the services offered through it. You do not have to allow Us
              to use these Cookies, as detailed below, however whilst Our use of
              them does not pose any risk to your privacy or your safe use of
              Our Site, it does enable Us to continually improve Our Site,
              making it a better and more useful experience for you.
            </p>
            <p className="mt-5">
              The analytics service(s) used by Our Site use(s) Cookies to gather
              the required information. Certain of these Cookies may be placed
              immediately when you first visit Our Site and it may not be
              possible for Us to obtain your prior consent.
            </p>
            <p className="mt-5">
              You can choose to enable or disable Cookies in your internet
              browser. Most internet browsers also enable you to choose whether
              you wish to disable all cookies or only third party cookies. By
              default, most internet browsers accept Cookies but this can be
              changed. For further details, please consult the help menu in your
              internet browser or the documentation that came with your device.
            </p>
            <p className="mt-5">
              You can choose to delete Cookies at any time however you may lose
              any information that enables you to access Our Site more quickly
              and efficiently including, but not limited to, login and
              personalization settings.
            </p>
            <p className="mt-5">
              It is recommended that you keep your internet browser and
              operating system up-to-date and that you consult the help and
              guidance provided by the developer of your internet browser and
              manufacturer of your computer or device if you are unsure about
              adjusting your privacy settings.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              13. Links
            </h2>
            <p className="mt-6">
              Our Site and Services may contain links to third-party websites.
              For example, occasionally, we may include or offer third-party
              products or services on our Site at our discretion. These
              third-party platforms have separate and independent privacy
              policies. We, therefore, have no responsibility or liability for
              the content and activities of those linked sites, including their
              information practices.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              14. Changes To This Policy
            </h2>
            <p className="mt-6">
              We may change this Privacy Policy as we may deem necessary from
              time to time, or as may be required by law. Your continued use of
              our Services after any changes or revisions to this Privacy Policy
              indicates your agreement to the terms of the revised Privacy
              Policy. We recommend that you check this page regularly to keep
              up-to-date.
            </p>
          </section>
          <section>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              15. Your Acceptance of These Terms
            </h2>
            <p className="mt-6">
              Acceptance of Terms Through the use of this website, you are
              hereby accepting the terms and conditions stipulated within the
              aforementioned Privacy Policy Agreement. If you are not in
              agreement with our terms and conditions, then you should refrain
              from further use of our sites. In addition, your continued use of
              our website following the posting of any updates or changes to our
              terms and conditions shall mean that you are in agreement and
              acceptance of such changes.
            </p>
          </section>
          <hr className="my-10" />
          <section className="mt-10 space-y-5">
            
            <h4 className="text-lg font-semibold text-black">
              Policy Last Updated: 03. March 2024
            </h4>
          </section>
        </div>
      </div>
    </div>
  );
}
