import {
  MegaphoneIcon,
  BanknotesIcon,
  MagnifyingGlassIcon,
  DocumentTextIcon,
  UserGroupIcon,
  CalendarIcon,
} from "@heroicons/react/20/solid";
import Demo from "../../img/hero-img.png";

const useCases = [
  {
    name: "Venture Capital",
    summary: "Optimize Deal Flow",
    description:
      "InProfiler can help VCs identify high-potential founders and startups aligned with their investment focus, thus refining their deal flow and saving valuable time.",
    icon: BanknotesIcon,
  },
  {
    name: "Recruiting",
    summary: "Streamline applications",
    description:
      "InProfiler can evaluate incoming connection requests based on specified job roles, skills, and experience levels, making it easier to identify potential candidates for recruitment.",
    icon: UserGroupIcon,
  },
  {
    name: "Sales",
    description:
      "By inputting desired client characteristics into InProfiler, salespeople can automatically categorize inbound connection requests, boosting lead generation efficiency.",
    icon: MegaphoneIcon,
  },
  {
    name: "Event Planning",
    description:
      "InProfiler can help identify high-potential speakers and attendees based on their industry expertise, geographical location, and more, making the event planning process smoother.",
    icon: CalendarIcon,
  },
  {
    name: "Job Searching",
    description:
      "Job Seekers can utilize InProfiler to prioritize connection requests from potential employers or industry influencers, boosting the odds of discovering the right job opportunities.",
    icon: MagnifyingGlassIcon,
  },
  {
    name: "Partnerships",
    description:
      "Business Development Managers can leverage InProfiler to filter connection requests, honing in on potential partners crucial for business expansion.",
    icon: DocumentTextIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32" id="use-cases">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Flexible & Versatile
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Discover our Use Cases
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            See how professionals across diverse industries can leverage
            InProfiler to optimize their LinkedIn networking and lead
            management. Discover the potential of intelligent lead profiling.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src={Demo}
            alt="App screenshot"
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            width={2432}
            height={1442}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {useCases.map((useCase) => (
            <div key={useCase.name} className="relative pl-9 flex flex-col">
              <dt className="inline font-semibold text-gray-900">
                <useCase.icon
                  className="absolute left-0 top-1 h-6 w-6 text-indigo-600"
                  aria-hidden="true"
                />
                <span className="text-lg">{useCase.name}</span>
              </dt>{" "}
              <dd className="inline">{useCase.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
