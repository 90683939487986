import React from "react";
import DeleteAccountModal from "./DeleteAccountModal";
import useToggleState from "../../../hooks/useToggleState";

export default function DeleteAccount() {
  const [showDeleteAccountModal, toggleDeleteAccountModal] =
    useToggleState(false);
  return (
    <div className="grid min-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8 items-center">
      <DeleteAccountModal
        open={showDeleteAccountModal}
        toggleOpen={toggleDeleteAccountModal}
      />
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Delete account
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          No longer want to use our service? You can delete your account here.
          This action is not reversible. All information related to this account
          will be deleted permanently.
        </p>
      </div>

      <div className="flex items-start md:col-span-2">
        <button
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-500 border border-red-500 shadow-sm hover:bg-gray-50"
          onClick={toggleDeleteAccountModal}
        >
          Yes, delete my account
        </button>
      </div>
    </div>
  );
}
