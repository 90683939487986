import React from "react";
import SelectLists from "../SetupSteps/SelectLists";
import { affinitySettingsPermissions } from "../../../../../permissions";

export default function AffinityListsSetting({
  integrationId,
  selectedOrgList,
  setSelectedOrgList,
  selectedPersonsList,
  setSelectedPersonsList,
  setLoading,
  setError,
  accountUser,
  importPersons,
  toggleImportPersons,
  overwriteImportPersons,
  importOrgs,
  toggleImportOrgs,
  overwriteImportOrgs,
}) {
  if (!affinitySettingsPermissions.includes(accountUser.role.name)) {
    return;
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Import Settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Select if you want to import both persons and organizations, and which
          Affinity lists they should be assigned to
        </p>
        <span className="mt-1 text-blue-500 text-sm cursor-pointer hover:text-blue-600 hidden">
          Learn more
        </span>
      </div>

      <div className="md:col-span-2">
        <SelectLists
          integrationId={integrationId}
          selectedOrgList={selectedOrgList}
          setSelectedOrgList={setSelectedOrgList}
          selectedPersonsList={selectedPersonsList}
          setSelectedPersonsList={setSelectedPersonsList}
          setLoading={setLoading}
          setError={setError}
          importPersons={importPersons}
          toggleImportPersons={toggleImportPersons}
          overwriteImportPersons={overwriteImportPersons}
          importOrgs={importOrgs}
          toggleImportOrgs={toggleImportOrgs}
          overwriteImportOrgs={overwriteImportOrgs}
          preselected
        />
      </div>
    </div>
  );
}
