import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { classNames } from "../../../helpers";
import Logo from "../../../img/logo.png";
import { Outlet } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "Dashboard", to: "/admin", key: "dashboard" },
  { name: "Prompts", to: "/admin/prompts", key: "prompts" },
];

export default function AdminLayout() {
  const [activeItem, setActiveItem] = useState();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/admin") {
      setActiveItem("dashboard");
    } else if (pathname.includes("/admin/prompts")) {
      setActiveItem("prompts");
    }
  }, [location.pathname]);
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={Logo}
                        alt="InProfiler"
                      />
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={Logo}
                        alt="InProfiler"
                      />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <Link
                          key={item.key}
                          to={item.to}
                          className={classNames(
                            activeItem === item.key
                              ? "border-indigo-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                          )}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <Link to="/app">
                      <button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="text-sm">Go to App</span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>

        <main>
          <div className="w-full">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}
