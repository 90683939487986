import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useParams, Navigate } from "react-router-dom";
import { useCheckInviteLink, useCreateInviteFromLink } from "../../api/UserAPI";
import LoadingScreen from "../../components/Layout/LoadingScreen";
import InviteLinkActive from "../../components/InviteLink/InviteLinkActive";
import InviteLinkExpired from "../../components/InviteLink/InviteLinkExpired";

export default function InviteLinkLanding() {
  const { user } = useContext(AuthContext);
  const params = useParams();
  const [token, setToken] = useState();
  const [inviteData, setInviteData] = useState();
  const [error, setError] = useState();
  const checkInviteLink = useCheckInviteLink();
  const createInviteFromLink = useCreateInviteFromLink();
  const [loading, setLoading] = useState(true);
  const [redirectUser, setRedirectUser] = useState(false);

  useEffect(() => {
    const { token } = params;
    if (token) {
      setToken(token);
    }
  }, [params]);

  useEffect(() => {
    if (token) {
      checkInviteLink(token)
        .then((data) => {
          setInviteData(data.invite);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.response.data.message);
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    if (user && token) {
      createInviteFromLink(token)
        .then((res) => {
          setRedirectUser(true);
        })
        .catch((err) => {
          setError(err.response.data.message);
          setLoading(false);
        });
    }
  }, [user, token]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (redirectUser) {
    return (
      <Navigate to={`/app/accounts?invitedBy=${inviteData.account.name}`} />
    );
  }

  if (inviteData && inviteData.is_expired) {
    return <InviteLinkExpired />;
  }

  return <InviteLinkActive inviteData={inviteData} token={token} />;
}
