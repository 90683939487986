import React from "react";
import { Link } from "react-router-dom";

export default function AgreeTermsCheckbox({
  checked,
  onChange,
  error = false,
  errorText = null,
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id="checkbox"
          aria-describedby="checkbox-description"
          name="checkbox"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor="checkbox" className="font-medium text-gray-900">
          I have read and agree to the{" "}
          <Link to="/privacy" target="_blank" className="text-indigo-600">
            Privacy Policy
          </Link>
        </label>
        {error && errorText && <p className="text-red-500">{errorText}</p>}
      </div>
    </div>
  );
}
