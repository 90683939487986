import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import InputWithLabel from "../../Forms/InputWithLabel";
import TextAreaWithLabel from "../../Forms/TextAreaWithLabel";
import useAxios from "../../../hooks/useAxios";
import useInputState from "../../../hooks/useInputState";
import { classNames } from "../../../helpers";

export default function ObjectivesSettings() {
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const [jobTitle, setJobTitle, overwriteJobTitle] = useInputState("");
  const [objective, setObjective, overwriteObjective] = useInputState("");
  const [company, setCompany, overwriteCompany] = useInputState("");
  const [loading, setLoading] = useState(false);
  const [infoSaved, setInfoSaved] = useState(false);
  const [reevaluationStarted, setReevaluationStarted] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    api
      .get(`subscription/account-user/`)
      .then((res) => {
        const u = res.data;
        overwriteJobTitle(u.job_title);
        overwriteCompany(u.company);
        overwriteObjective(u.objective);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const updateObjectives = () => {
    setError(null);
    setInfoSaved(false);
    setLoading(true);
    api
      .put(
        "subscription/account-user/",
        JSON.stringify({
          job_title: jobTitle,
          company,
          objective,
        })
      )
      .then((res) => {
        setInfoSaved(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const reevaluateLeads = () => {
    setError(null);
    setLoading(true);
    setInfoSaved(false);
    api
      .post("reevaluate-leads/")
      .then((res) => {
        setReevaluationStarted(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="min-w-full divide-y divide-gray-200">
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-black">
            Your business objectives
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            This information is necessary for evaluating your inbound leads
          </p>
        </div>

        <form className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full">
              <InputWithLabel
                small
                label="Job Title"
                placeholder="Job Title"
                helperText="If you have a really complicated job title, try entering something that is easier to understand and related to your objective."
                value={jobTitle}
                onChange={setJobTitle}
                disabled={loading}
              />
            </div>

            <div className="col-span-full">
              <InputWithLabel
                small
                label="Company"
                placeholder="Company"
                value={company}
                onChange={setCompany}
                disabled={loading}
              />
            </div>

            <div className="col-span-full">
              <TextAreaWithLabel
                small
                label="Lead objective"
                placeholder="I want to connect with early stage founders of agriculture startups"
                value={objective}
                onChange={setObjective}
                disabled={loading}
                rows={6}
                // helperText={`We recommend a minimum of 100 characters in your objective. You are currently using ${objective.length}`}
              />
              {objective.length && !infoSaved && (
                <p
                  className={classNames(
                    objective.length < 100
                      ? "text-orange-700"
                      : "text-green-700",
                    "text-sm mt-1"
                  )}
                >
                  {objective.length < 100
                    ? "For better results, your objective should have at least 100 characters."
                    : "Good job defining your objective! You can add more info for better evaluations."}
                </p>
              )}
            </div>
          </div>

          <div className="mt-8 flex gap-4 items-center">
            <button
              onClick={updateObjectives}
              disabled={loading}
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Save
            </button>
            {infoSaved && (
              <button
                onClick={reevaluateLeads}
                disabled={loading}
                className="rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-indigo-600 border border-indigo-600 shadow-sm hover:bg-slate-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Reevaluate old leads
              </button>
            )}
            {error && <span className="text-red-500 text-sm">{error}</span>}
            {infoSaved && (
              <span className="text-green-600 text-sm">Information saved</span>
            )}
            {!reevaluationStarted && (
              <span className="text-green-600 text-sm w-1/3">
                The reevaluation has started. This might take a few minutes to
                complete, but feel free to leave the page.
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
