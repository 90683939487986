import React from "react";
import { affinitySettingsPermissions } from "../../../../../permissions";

export default function FooterBar({
  error,
  saved,
  saveChanges,
  awaitingResponse,
  accountUser,
}) {
  if (!affinitySettingsPermissions.includes(accountUser.role.name)) {
    return;
  }

  return (
    <div className="py-5 px-6 bg-white/90 sticky bottom-0 flex w-full shadow-lg border-t justify-between items-center z-50">
      {error ? (
        <p className="py-2 px-4 rounded-lg shadow-sm bg-red-100 text-red-600 text-sm">
          {error}
        </p>
      ) : (
        <div></div>
      )}
      <div className="flex gap-4 items-center">
        {saved && (
          <p className="text-green-600 text-sm">Settings saved successfully</p>
        )}
        <button
          onClick={saveChanges}
          disabled={awaitingResponse}
          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 disabled:bg-gray-50 disabled:text-gray-300"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
