import React, { useState, useEffect } from "react";
import useInputState from "../../../hooks/useInputState";
import InputWithLabel from "../../Forms/InputWithLabel";
import ProfilePicture from "./ProfilePicture";
import { useUpdateUser } from "../../../api/UserAPI";

export default function PersonalInfo({ user, setUser, setAuthTokens }) {
  const [firstName, setFirstName, overwriteFirstName] = useInputState("");
  const [lastName, setLastName, overwriteLastName] = useInputState("");
  const [email, setEmail, overwriteEmail] = useInputState("");
  const [profilePicture, setProfilePicture] = useState();
  const [errors, setErrors] = useState();
  const [saved, setSaved] = useState(false);
  const updateUser = useUpdateUser();

  useEffect(() => {
    if (user) {
      overwriteFirstName(user.firstName);
      overwriteLastName(user.lastName);
      overwriteEmail(user.email);
      setProfilePicture(user.profilePicture);
    }
  }, [user]);

  const handleUpdateUser = () => {
    setErrors(null);
    updateUser(user.user_id, firstName, lastName)
      .then((data) => {
        // Get the updated user data and new token from the response
        const { user: updatedUser, token } = data;
        // Update the user state, auth tokens, and local storage
        setUser(updatedUser);
        setAuthTokens(token);
        localStorage.setItem("authTokens", JSON.stringify(token));
        setSaved(true);
      })
      .catch((err) => {
        console.log(err.response.data);
        setErrors(err.response.data);
      });
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Personal Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Use a permanent address where you can receive mail.
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <ProfilePicture profilePicture={profilePicture} />

          <div className="sm:col-span-3">
            <InputWithLabel
              small
              label="First name"
              placeholder="First name"
              value={firstName}
              onChange={setFirstName}
              error={errors && Object.keys(errors).includes("first_name")}
              errorText={
                errors &&
                Object.keys(errors).includes("first_name") &&
                errors["first_name"][0]
              }
            />
          </div>

          <div className="sm:col-span-3">
            <InputWithLabel
              small
              label="Last name"
              placeholder="Last name"
              value={lastName}
              onChange={setLastName}
              error={errors && Object.keys(errors).includes("last_name")}
              errorText={
                errors &&
                Object.keys(errors).includes("last_name") &&
                errors["last_name"][0]
              }
            />
          </div>

          <div className="col-span-full">
            <InputWithLabel
              small
              label="Email address"
              placeholder="Email address"
              value={email}
              onChange={setEmail}
              disabled
              error={errors && Object.keys(errors).includes("email")}
              errorText={
                errors &&
                Object.keys(errors).includes("email") &&
                errors["email"][0]
              }
            />
          </div>
        </div>

        <div className="mt-8 flex items-center gap-5">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={handleUpdateUser}
          >
            Save
          </button>
          {saved && <p className="text-sm text-green-600">Information saved</p>}
        </div>
      </div>
    </div>
  );
}
