import React from "react";
import useToggleState from "../../../../hooks/useToggleState";
import TextAreaWithLabel from "../../../Forms/TextAreaWithLabel";
import { MinusIcon, PlusIcon, SparklesIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../../UtilityComponents/Tooltip";
import VariablesModal from "./VariablesModal";

const roleOptions = ["system", "user", "assistant"];

export default function Message({
  message,
  index,
  removeMessage,
  addMessage,
  loading,
  handleMessageChange,
}) {
  const [showVariablesModal, toggleShowVariablesModal] = useToggleState(false);
  const handleRemove = () => {
    removeMessage(index);
  };

  const handleAdd = () => {
    addMessage(index + 1);
  };

  return (
    <div className="px-2 py-4 flex gap-3">
      <VariablesModal
        open={showVariablesModal}
        toggleOpen={toggleShowVariablesModal}
        messageIndex={index}
        message={message.content}
        handleMessageChange={handleMessageChange}
      />
      <div className="w-1/6">
        <select
          value={message.role}
          className="text-sm font-semibold bg-gray-100 p-2 rounded"
        >
          {roleOptions.map((role) => (
            <option value={role}>{role.toUpperCase()}</option>
          ))}
        </select>
        <div className="flex gap-3 justify-start mt-4">
          <Tooltip text="Remove message" position="top">
            <button
              onClick={handleRemove}
              className="rounded-md bg-gray-100 p-1 text-gray-600 shadow-sm hover:bg-gray-200"
            >
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>
          <Tooltip text="Add message below" position="top">
            <button
              onClick={handleAdd}
              className="rounded-md bg-gray-100 p-1 text-gray-600 shadow-sm hover:bg-gray-200"
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>
          <Tooltip text="Show variables" position="top">
            <button
              onClick={toggleShowVariablesModal}
              className="rounded-md bg-gray-100 p-1 text-gray-600 shadow-sm hover:bg-gray-200"
            >
              <SparklesIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="w-5/6">
        <TextAreaWithLabel
          value={message.content}
          placeholder="Write message here"
          disabled={loading}
          onChange={(e) => handleMessageChange(index, e.target.value)}
        />
      </div>
    </div>
  );
}
