import { Routes, Route } from "react-router-dom";
import LandingLayout from "../components/LandingPage/LandingLayout";
import Landing from "../views/Landing/Landing";
import Contact from "../views/Landing/Contact";
import Privacy from "../views/Landing/Privacy";
import PageNotFound from "../views/Landing/PageNotFound";
import {
  LoginRoute,
  RegisterRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,
  InviteLinkLandingRoute,
} from "./AuthRouteHelper";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingLayout />}>
        <Route index element={<Landing />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacy" element={<Privacy />} />
      </Route>
      <Route path="login" element={<LoginRoute />} />
      <Route path="join" element={<RegisterRoute />} />
      <Route path="beta-signup" element={<RegisterRoute />} />
      <Route path="forgot-password" element={<ForgotPasswordRoute />} />
      <Route
        path="reset-password/:userId/:token"
        element={<ResetPasswordRoute />}
      />
      <Route path="invite/:token" element={<InviteLinkLandingRoute />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
