import useAxios from "../hooks/useAxios";

export const useStartCheckout = () => {
  const api = useAxios();

  const startCheckout = (priceId, accountId) => {
    return api
      .post("subscription/start-checkout/", { priceId, accountId })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return startCheckout;
};

export const useListSubscriptionPlans = () => {
  const api = useAxios();

  const listSubscriptionPlans = () => {
    return api
      .get("subscription/list-plans/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return listSubscriptionPlans;
};

export const useCreateCustomerPortalSession = () => {
  const api = useAxios();

  const createCustomerPortalSession = (accountId) => {
    return api
      .post("subscription/customer-portal/", { accountId })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return createCustomerPortalSession;
};

export const useCreateChangePlanSession = () => {
  const api = useAxios();

  const createChangePlanSession = (customerId, subscriptionId, newPriceId) => {
    return api
      .post("subscription/change-plan/", {
        customerId,
        subscriptionId,
        newPriceId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return createChangePlanSession;
};
