import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import InputWithLabel from "../../Forms/InputWithLabel";
import { useCreateInviteLink } from "../../../api/UserAPI";
import { classNames } from "../../../helpers";

export default function InviteLinkModal({ open, toggleOpen, seatsAvailable }) {
  const [link, setLink] = useState();
  const [error, setError] = useState();
  const [copied, setCopied] = useState(false);
  const createInviteLink = useCreateInviteLink();

  const triggerLinkCreation = () => {
    createInviteLink()
      .then((data) => setLink(data.url))
      .catch((err) => setError(err.response.data.message));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(
      function () {
        setCopied(true);
      },
      function (err) {
        setError("Couldn't copy link");
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserPlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Invite Users with Link
                    </Dialog.Title>
                    <div className="mt-1">
                      <p className="text-sm text-gray-500">
                        Anyone with the invite link will be able to join your
                        account as a member. Be careful who you share it with.
                      </p>
                    </div>
                    <div className="my-3">
                      {link ? (
                        <InputWithLabel
                          placeholder="Generating Link"
                          label="Invite Link"
                          helperText="Link expires after 48 hours"
                          small
                          value={link && link}
                          disabled={true}
                        />
                      ) : seatsAvailable ? (
                        <button
                          onClick={triggerLinkCreation}
                          className="mt-5 bg-indigo-600 hover:bg-indigo-500 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:mt-0 sm:w-auto"
                        >
                          Generate Invite Link
                        </button>
                      ) : (
                        <div className="p-3 text-sm bg-gray-200 text-gray-700 rounded-lg">
                          You cannot create an invite link because your plan's
                          user limit has been reached
                        </div>
                      )}
                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                  </div>
                </div>
                {link && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
                    <button
                      type="button"
                      className={classNames(
                        copied
                          ? "bg-green-600 hover:bg-green-500"
                          : "bg-indigo-600 hover:bg-indigo-500",
                        "mt-3 inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:mt-0 sm:w-auto"
                      )}
                      onClick={copyToClipboard}
                    >
                      {copied ? "Link copied" : "Copy to Clipboard"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={toggleOpen}
                    >
                      Close
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
