import React from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../../../helpers";

export default function InviteCard({ invite, deleteInvite }) {
  return (
    <div
      key={invite.id}
      className="relative flex items-center space-x-3 rounded-lg border bg-white px-6 py-5 shadow-sm"
    >
      <div className="flex-shrink-0">
        <div className="flex h-10 w-10 items-center justify-center bg-slate-100 rounded-full">
          <ClockIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm font-medium text-gray-900">{invite.email}</p>
        <p className="truncate text-sm text-gray-500">
          Invitation sent: {formatDate(invite.timestamp)}
        </p>
      </div>
      <span
        className=" text-red-600 hover:text-red-500 hover:cursor-pointer"
        onClick={() => deleteInvite(invite.id)}
      >
        Delete
      </span>
    </div>
  );
}
