import React, { useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import useInputState from "../../../hooks/useInputState";
import InputWithLabel from "../../Forms/InputWithLabel";
import AutoSharing from "./AutoSharing";
import useAxios from "../../../hooks/useAxios";

export default function AccountPreferences() {
  const api = useAxios();
  const {
    accountSettings,
    subscriptionAccount,
    getAccountSettings,
    getSubscriptionAccount,
  } = useContext(SubscriptionContext);
  const [accountName, setAccountName, overwriteAccountName] = useInputState("");
  const [automaticSharing, setAutomaticSharing] = useState(false);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (accountSettings && subscriptionAccount) {
      overwriteAccountName(subscriptionAccount.name);
      setAutomaticSharing(accountSettings.automatic_sharing);
    }
  }, [accountSettings, subscriptionAccount]);

  const handleSubmit = () => {
    setErrors(null);
    setSaved(false);
    setLoading(true);
    api
      .put(`subscription/update-account-settings/${subscriptionAccount.id}/`, {
        accountName,
        automaticSharing,
      })
      .then((res) => {
        getSubscriptionAccount();
        getAccountSettings();
        setSaved(true);
      })
      .catch((err) => {
        setErrors(err.response.data.message);
      });
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Account Settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Adjust your global account preferences
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="sm:col-span-6">
            <InputWithLabel
              small
              label="Account Name"
              placeholder="Account Name"
              value={accountName}
              onChange={setAccountName}
            />
          </div>
          <AutoSharing
            automaticSharing={automaticSharing}
            setAutomaticSharing={setAutomaticSharing}
          />
        </div>

        <div className="mt-8 flex gap-4 items-center">
          <button
            onClick={handleSubmit}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
          {saved && <p className="text-sm text-green-600">Preferences saved</p>}
          {errors && <p className="text-sm text-red-500">{errors}</p>}
        </div>
      </div>
    </div>
  );
}
