import React, { useState } from "react";
import useInputState from "../../hooks/useInputState";
import useAxios from "../../hooks/useAxios";
import { classNames } from "../../helpers";
import Logo from "../../img/logo.png";

export default function Feedback() {
  const api = useAxios();
  const [message, setMessage] = useInputState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();

  const handleSend = () => {
    api
      .post("send-feedback/", { message })
      .then((res) => {
        setSent(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return (
    <div className="relative isolate bg-white content-height">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-30">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Share your Feedback
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              As our product is currently in Beta phase, we are always looking
              for feedback from early adopters like you. Feel free to share any
              feedback or suggestion you might have for us. Thank you for your
              support!
            </p>
          </div>
        </div>
        <div className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-32">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Your Feedback
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={6}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    value={message}
                    onChange={setMessage}
                    placeholder="Tell us about your InProfiler experience ..."
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end items-center gap-5">
              {error && <span className="text-red-500 text-sm">{error}</span>}
              <button
                onClick={handleSend}
                disabled={sent}
                className={classNames(
                  sent
                    ? "bg-green-600 hover:bg-green-500"
                    : "bg-indigo-600 hover:bg-indigo-500",
                  "rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                )}
              >
                {sent ? "Message sent" : "Send message"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
