import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import useInputState from "../../../../hooks/useInputState";
import InputWithLabel from "../../../Forms/InputWithLabel";

export default function ConfigModal({ open, toggleOpen, prompt, setPrompt }) {
  const [model, setModel, overwriteModel] = useInputState("");
  const [temperature, setTemperature, overwriteTemperature] = useInputState("");
  const [topP, setTopP, overwriteTopP] = useInputState("");
  const [maxTokens, setMaxTokens, overwriteMaxTokens] = useInputState("");
  const [frequencyPenalty, setFrequencyPenalty, overwriteFrequencyPenalty] =
    useInputState("");
  const [presencePenalty, setPresencePenalty, overwritePresencePenalty] =
    useInputState("");

  useEffect(() => {
    if (prompt) {
      overwriteModel(prompt.model);
      overwriteTemperature(prompt.temperature);
      overwriteMaxTokens(prompt.max_tokens);
      overwriteTopP(prompt.top_p);
      overwriteFrequencyPenalty(prompt.frequency_penalty);
      overwritePresencePenalty(prompt.presence_penalty);
    }
  }, [prompt]);

  const handleClick = () => {
    setPrompt((prevPrompt) => ({
      ...prevPrompt,
      temperature: temperature,
      max_tokens: maxTokens,
      top_p: topP,
      frequency_penalty: frequencyPenalty,
      presence_penalty: presencePenalty,
      model: model,
    }));
    toggleOpen();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={toggleOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      Change Configuration
                    </Dialog.Title>

                    <div className="my-3 columns-2">
                      <InputWithLabel
                        label="Temperature"
                        type="number"
                        value={temperature}
                        onChange={setTemperature}
                        min={0}
                        max={2}
                        step=".01"
                        small
                      />
                      <InputWithLabel
                        label="Max Tokens"
                        type="number"
                        value={maxTokens}
                        onChange={setMaxTokens}
                        min={0}
                        max={2048}
                        small
                      />
                    </div>
                    <div className="my-3 columns-2">
                      <InputWithLabel
                        label="Top P"
                        type="number"
                        value={topP}
                        onChange={setTopP}
                        min={0}
                        max={1}
                        step=".01"
                        small
                      />
                      <InputWithLabel
                        label="Frequency Penalty"
                        type="number"
                        value={frequencyPenalty}
                        onChange={setFrequencyPenalty}
                        min={0}
                        max={2}
                        step=".01"
                        small
                      />
                    </div>
                    <div className="my-3 columns-2">
                      <InputWithLabel
                        label="Presence Penalty"
                        type="number"
                        value={presencePenalty}
                        onChange={setPresencePenalty}
                        min={0}
                        max={2}
                        step=".01"
                        small
                      />
                      <InputWithLabel
                        label="Model"
                        type="text"
                        value={model}
                        onChange={setModel}
                        small
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleClick}
                  >
                    Save Config
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleOpen}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
