import { useState } from "react";
import useInputState from "../../hooks/useInputState";
import axios from "axios";
import { classNames, validateEmail, getCookie } from "../../helpers";
import Logo from "../../img/logo.png";

export default function Contact() {
  const [firstName, setFirstName] = useInputState("");
  const [lastName, setLastName] = useInputState("");
  const [companyName, setCompanyName] = useInputState("");
  const [email, setEmail] = useInputState("");
  const [message, setMessage, overwriteMessage] = useInputState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();

  const sendMessage = () => {
    setError(null);

    if (!firstName || !lastName || !email || !message) {
      setError("Please fill out your name, email address and message");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }

    const baseURL = process.env.REACT_APP_API_URL;
    const csrfToken = getCookie("csrftoken");
    const body = {
      firstName,
      lastName,
      companyName,
      email,
      message,
    };
    const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    };

    axios
      .post(baseURL + "landing/contact-form-submit/", body, headers)
      .then((res) => {
        setSent(true);
        overwriteMessage("");
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return (
    <div className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-30">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="flex gap-2 items-center mb-10">
              <img src={Logo} className="h-10 w-auto" />
              <h2 className="text-3xl text-indigo-600 font-bold">InProfiler</h2>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Contact Us
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              The best way to reach out to us is using the contact form to the
              right. Whether you have a question, suggestion or issue, we'll be
              in touch with you as soon as possible.
            </p>
          </div>
        </div>
        <div className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={firstName}
                    onChange={setFirstName}
                    placeholder="Bill"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={lastName}
                    onChange={setLastName}
                    placeholder="Gates"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Company Name <span className="text-gray-400">(optional)</span>
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={companyName}
                    onChange={setCompanyName}
                    placeholder="Microsoft"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={email}
                    onChange={setEmail}
                    placeholder="billy@microsoft.com"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                    value={message}
                    onChange={setMessage}
                    placeholder="Hello, I love your app!"
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end items-center gap-5">
              {error && <span className="text-red-500 text-sm">{error}</span>}
              <button
                onClick={sendMessage}
                disabled={sent}
                className={classNames(
                  sent
                    ? "bg-green-600 hover:bg-green-500"
                    : "bg-indigo-600 hover:bg-indigo-500",
                  "rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                )}
              >
                {sent ? "Message sent" : "Send message"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
