import { XCircleIcon } from "@heroicons/react/20/solid";

export default function SubscriptionErrorAlert() {
  return (
    <div className="rounded-md bg-red-50 p-4 mt-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Your subscription was unsuccessful
          </h3>
          <p className="text-sm mt-2 text-red-800">
            Please try again or let us know if the issue persists
          </p>
        </div>
      </div>
    </div>
  );
}
