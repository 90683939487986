import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  Cog6ToothIcon,
  PuzzlePieceIcon,
  HomeIcon,
  UsersIcon,
  ShareIcon,
  XMarkIcon,
  ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Logo from "../../img/logo.png";
import { classNames } from "../../helpers";

const navigation = [
  { name: "Dashboard", to: "/app", icon: HomeIcon, key: "dashboard" },
  {
    name: "Your Leads",
    to: "/app/leads",
    icon: UsersIcon,
    key: "leads",
  },
  {
    name: "Shared Leads",
    to: "/app/leads/shared",
    icon: ShareIcon,
    key: "shared",
  },
  {
    name: "Chrome Extension",
    to: "/app/get-extension",
    icon: PuzzlePieceIcon,
    key: "extension",
  },
  {
    name: "Settings",
    to: "/app/settings",
    icon: Cog6ToothIcon,
    key: "settings",
  },
  // {
  //   name: "Subscription",
  //   to: "/app/subscription",
  //   icon: CreditCardIcon,
  //   key: "subscription",
  // },
];

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const [activeItem, setActiveItem] = useState();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/app") {
      setActiveItem("dashboard");
    } else if (pathname.includes("/app/leads/shared")) {
      setActiveItem("shared");
    } else if (pathname.includes("/app/leads")) {
      setActiveItem("leads");
    } else if (pathname.includes("/app/settings")) {
      setActiveItem("settings");
    } else if (pathname === "/app/get-extension") {
      setActiveItem("extension");
    } else if (pathname === "/app/subscription") {
      setActiveItem("subscription");
    } else if (pathname === "/app/feedback") {
      setActiveItem("feedback");
    }
  }, [location.pathname]);
  return (
    <Fragment>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center space-x-3">
                    <img className="h-8 w-auto" src={Logo} alt="InProfiler" />
                    <span className="text-xl font-bold text-indigo-500">
                      InProfiler
                    </span>
                    <span className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                      Beta
                    </span>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="-mx-2 flex-1 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.to}
                            className={classNames(
                              activeItem === item.key
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:flex lg:w-72 lg:flex-col bg-white">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center space-x-3">
            <img className="h-8 w-auto" src={Logo} alt="InProfiler" />
            <span className="text-xl font-bold text-indigo-600">
              InProfiler
            </span>
            <span className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
              Beta
            </span>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className={classNames(
                          activeItem === item.key
                            ? "bg-gray-50 text-indigo-600"
                            : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            activeItem === item.key
                              ? "text-indigo-600"
                              : "text-gray-400 group-hover:text-indigo-600",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <Link
                  to="/app/feedback"
                  className={classNames(
                    activeItem === "feedback"
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md px-6 py-4 text-sm leading-6 font-semibold items-center"
                  )}
                >
                  <ChatBubbleBottomCenterIcon
                    className={classNames(
                      activeItem === "feedback"
                        ? "text-indigo-600"
                        : "text-gray-400 group-hover:text-indigo-600",
                      "h-6 w-6 shrink-0"
                    )}
                    aria-hidden="true"
                  />
                  Send Feedback
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Fragment>
  );
}
