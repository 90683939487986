import React, { useState } from "react";
import useToggleState from "../../../../hooks/useToggleState";
import LoadPromptModal from "./LoadPromptModal";
import ConfigModal from "./ConfigModal";
import SavePromptModal from "./SavePromptModal";
import Message from "./Message";
import ResultSlideOver from "./ResultSlideOver";
import { useTestPrompt } from "../../../../api/AdminAPI";

export default function PromptTesting({ selectedUser, selectedLead }) {
  const [prompt, setPrompt] = useState({
    messages: [{ role: "system", content: "" }],
    temperature: 0,
    max_tokens: 200,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    model: "gpt-3.5-turbo-0613",
  });
  const [showLoadPromptModal, toggleShowLoadPromptModal] =
    useToggleState(false);
  const [showConfigModal, toggleShowConfigModal] = useToggleState(false);
  const [showSaveModal, toggleShowSaveModal] = useToggleState(false);
  const [showResults, toggleShowResults] = useToggleState(false);
  const [evaluation, setEvaluation] = useState();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState();
  const testPrompt = useTestPrompt();

  const addMessage = (index) => {
    const newMessages = [
      ...prompt.messages.slice(0, index),
      { role: "user", content: "" },
      ...prompt.messages.slice(index),
    ];
    setPrompt((prevPrompt) => ({ ...prevPrompt, messages: newMessages }));
  };

  const removeMessage = (index) => {
    const newMessages = [...prompt.messages];
    newMessages.splice(index, 1);
    setPrompt((prevPrompt) => ({ ...prevPrompt, messages: newMessages }));
  };

  const handleMessageChange = (index, newContent) => {
    setPrompt((prevPrompt) => {
      const newMessages = [...prevPrompt.messages];
      newMessages[index].content = newContent;
      return { ...prevPrompt, messages: newMessages };
    });
  };

  const getEvaluation = () => {
    setLoading(true);
    setError(null);
    testPrompt(prompt, selectedLead.id, selectedUser.id)
      .then((data) => {
        setEvaluation(data.evaluation);
        setLoading(false);
        toggleShowResults();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="content-height w-full relative">
      <LoadPromptModal
        open={showLoadPromptModal}
        toggleOpen={toggleShowLoadPromptModal}
        setPrompt={setPrompt}
      />
      <ConfigModal
        open={showConfigModal}
        toggleOpen={toggleShowConfigModal}
        prompt={prompt}
        setPrompt={setPrompt}
      />
      <SavePromptModal
        prompt={prompt}
        setSaved={setSaved}
        open={showSaveModal}
        toggleOpen={toggleShowSaveModal}
      />
      <ResultSlideOver
        open={showResults}
        toggleOpen={toggleShowResults}
        evaluation={evaluation}
        selectedUser={selectedUser}
      />
      <div className="p-4 border-b flex items-center justify-between sticky top-0 bg-white">
        <h3 className="font-semibold">
          {prompt.description
            ? `Selected Prompt: ${prompt.description}`
            : "Configure New Prompt"}
        </h3>
        <div className="flex gap-3 items-center">
          <button
            disabled={loading}
            onClick={toggleShowLoadPromptModal}
            className="flex justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 hover:bg-gray-50"
          >
            Load Prompt
          </button>
          {evaluation && (
            <>
              <button
                disabled={loading}
                onClick={toggleShowResults}
                className="flex justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 hover:bg-gray-50"
              >
                Show Result
              </button>
              <button
                onClick={toggleShowSaveModal}
                className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save Prompt
              </button>
            </>
          )}
        </div>
      </div>
      <div className="bg-gray-100 p-4">
        <p className="text-sm text-gray-500">{`Required output format: {"assessment": string, "evaluationScore": integer}`}</p>
      </div>
      <div className="w-full p-5 overflow-scroll">
        {prompt ? (
          <div className="divide-y divide-gray-200">
            {prompt.messages.map((message, index) => (
              <Message
                key={index}
                message={message}
                index={index}
                removeMessage={removeMessage}
                addMessage={addMessage}
                loading={loading}
                handleMessageChange={handleMessageChange}
              />
            ))}
          </div>
        ) : (
          <p>Select prompt</p>
        )}
      </div>
      <div className="p-4 bg-gray-50 absolute w-full bottom-0 flex justify-between gap-3 border-t items-center">
        {error ? (
          <span className="text-red-500 text-sm">{error}</span>
        ) : (
          <div className="flex items-center gap-5">
            <span className="text-sm text-gray-500">
              Tmp: {prompt.temperature}
            </span>
            <span className="text-sm text-gray-500">
              MaxT: {prompt.max_tokens}
            </span>
            <span className="text-sm text-gray-500">TopP: {prompt.top_p}</span>
            <span className="text-sm text-gray-500">
              FreqP: {prompt.frequency_penalty}
            </span>
            <span className="text-sm text-gray-500">
              PresP: {prompt.presence_penalty}
            </span>
            <span className="text-sm text-gray-500">Model: {prompt.model}</span>
          </div>
        )}
        <div className="flex gap-2 items-center">
          <button
            className="flex justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm hover:bg-gray-50"
            onClick={toggleShowConfigModal}
            disabled={loading}
          >
            Change Config
          </button>
          <button
            onClick={getEvaluation}
            disabled={!selectedLead || !selectedUser || loading}
            className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-300"
          >
            {loading ? "Loading" : "Test Prompt"}
          </button>
        </div>
      </div>
    </div>
  );
}
