import useAxios from "../hooks/useAxios";

export const useGetAdminLeads = () => {
  const api = useAxios();

  const getAdminLeads = (page) => {
    return api
      .get(`admin/list-leads/?page=${page}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getAdminLeads;
};

export const useGetAdminUsers = () => {
  const api = useAxios();

  const getAdminUsers = (page) => {
    return api
      .get(`admin/list-account-users/?page=${page}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getAdminUsers;
};

export const useGetSavedPrompts = () => {
  const api = useAxios();

  const getSavedPrompts = (page) => {
    return api
      .get(`admin/get-prompts/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getSavedPrompts;
};

export const useTestPrompt = () => {
  const api = useAxios();

  const testPrompt = (prompt, leadId, userId) => {
    return api
      .post("admin/test-prompt/", JSON.stringify({ prompt, leadId, userId }))
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return testPrompt;
};

export const useSavePrompt = () => {
  const api = useAxios();

  const savePrompt = (prompt, promptDescription, setActive) => {
    return api
      .post(
        "admin/save-prompt/",
        JSON.stringify({ prompt, promptDescription, setActive })
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return savePrompt;
};

export const useGetDashboardStats = () => {
  const api = useAxios();

  const getDashboardStats = () => {
    return api
      .get("admin/dashboard-stats/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getDashboardStats;
};
