import React from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { formatDate, classNames, getInitials } from "../../../helpers";
import EvaluationBadge from "../EvaluationBadge";
import { Link } from "react-router-dom";

export default function RequestsListItem({
  selectedLead,
  setSelectedLead,
  lead,
  leadType,
}) {
  return (
    <Link
      to={
        leadType === "owner"
          ? `/app/leads/${lead.id}`
          : `/app/leads/shared/${lead.id}`
      }
      onClick={() => setSelectedLead(lead.id)}
    >
      <li
        key={lead.id}
        className={classNames(
          selectedLead === lead.id
            ? "bg-indigo-50"
            : "bg-white hover:bg-gray-50",
          "relative flex justify-between gap-x-6 py-5 cursor-pointer px-5 shrink"
        )}
      >
        <div className="flex gap-x-4 flex-shrink flex-1 max-w-2/3 overflow-hidden">
          {lead.picture ? (
            <img
              className="h-8 w-8 xl:h-12 xl:w-12 flex-none rounded-full bg-gray-50 hidden xl:block"
              src={lead.picture}
              alt=""
            />
          ) : (
            <span className="md:inline-flex h-8 w-8 xl:h-12 xl:w-12 items-center justify-center rounded-full bg-gray-400 hidden">
              <span className="text-sm xl:text-lg font-medium leading-none text-white">
                {getInitials(lead.name)}
              </span>
            </span>
          )}
          <div className="min-w-0 flex-1 flex-shrink">
            <p className="text-sm font-semibold leading-6 text-gray-900">
              <span className="flex items-center gap-2">
                {!lead.seen && leadType === "owner" && (
                  <span className="inline-flex h-2 w-2 bg-blue-500 rounded-full -top-1 right-1" />
                )}
                {lead.name}
              </span>
            </p>
            {lead.person_occupation && (
              <p className="mt-1 text-xs leading-5 text-gray-500 flex flex-wrap">
                <span className="relative truncate">
                  {lead.person_occupation}
                </span>
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center gap-x-4 flex-shrink-0">
          <div className="flex flex-col items-end flex-shrink-0">
            <div className="flex gap-2">
              <span
                className={
                  "px-2 py-1 text-xs bg-gray-50 ring-gray-6000 text-gray-600 inline-flex items-center rounded-md font-medium  ring-1 ring-inset"
                }
              >
                {lead.lead_type === "IB" ? "Inbound" : "Outbound"}
              </span>
              <EvaluationBadge
                score={lead.evaluation && lead.evaluation.evaluation_score}
                small
              />
            </div>
            <p className="mt-1 text-xs leading-5 text-gray-500">
              <time dateTime={lead.created}>{formatDate(lead.created)}</time>
            </p>
          </div>
          <ChevronRightIcon
            className="h-5 w-5 flex-none text-gray-400"
            aria-hidden="true"
          />
        </div>
      </li>
    </Link>
  );
}
