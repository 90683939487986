import React, { useState } from "react";
import { formatDate } from "../../../../helpers";

const ListItem = ({ heading, content }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="px-4 py-3">
      <p className="text-sm font-semibold">{heading}</p>
      <p className="text-sm">
        {content.length < 200 ? (
          content
        ) : expanded ? (
          <div>
            {content}{" "}
            <p
              className="text-blue-500 font-semibold cursor-pointer"
              onClick={() => setExpanded(false)}
            >
              Show less
            </p>
          </div>
        ) : (
          <div>
            {content.slice(0, 200)}{" "}
            <p
              className="text-blue-500 font-semibold cursor-pointer"
              onClick={() => setExpanded(true)}
            >
              Show more
            </p>
          </div>
        )}
      </p>
    </div>
  );
};

export default function UserDetail({ user }) {
  return (
    <div className="bg-white divide-gray-200 divide-y content-height">
      <div className="bg-gray-100 p-4">
        <p className="text-sm font-semibold">Basic Info</p>
      </div>
      {user.company && <ListItem heading="Company" content={user.company} />}
      {user.job_title && (
        <ListItem heading="Job Title" content={user.job_title} />
      )}
      {user.objective && (
        <ListItem heading="Objective" content={user.objective} />
      )}
    </div>
  );
}
