export const affinityIntegrationTypes = [
  {
    name: "Account based",
    key: "A",
    description:
      "By selecting this, the admin connects all users to Affinity with one API key. All leads will be under the admin's ownership in Affinity. This simplifies setup but offers less control.",
  },
  {
    name: "User based",
    key: "U",
    description:
      "This option requires each user to connect to Affinity with their own API key. Leads are associated with individual users in Affinity. This provides greater control but requires individual setup.",
  },
];
