import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../../img/logo.png";
import { Link, useNavigate } from "react-router-dom";

const navigation = [
  { name: "Features", sectionId: "features" },
  { name: "Use Cases", sectionId: "use-cases" },
  { name: "How it works", sectionId: "how-it-works" },
  { name: "Pricing", sectionId: "pricing" },
  { name: "FAQ", sectionId: "faq" },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (id) => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
    if (window.location.pathname !== "/") {
      navigate("/"); // navigate to the root
    }
    setTimeout(() => navigate(`/#${id}`), 0); // navigate to the section after next event loop iteration
  };

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-full items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center gap-x-12">
          <Link to="/" className="-m-1.5 p-1.5 flex gap-3 items-center">
            <img className="h-8 w-auto" src={Logo} alt="" />
            <span class="font-bold text-indigo-600 text-2xl">InProfiler <span class="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-600/10">Beta</span></span>
          </Link>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <span
                key={item.name}
                onClick={() => handleNavigation(item.sectionId)}
                className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:text-indigo-600 hover:cursor-pointer"
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex gap-5 items-center">
          <Link
            to="/login"
            className="px-3 py-2 text-sm font-semibold text-black"
          >
            Login
          </Link>
          <Link
            to="/join"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign Up
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5 flex gap-3 items-center">
              <img className="h-8 w-auto" src={Logo} alt="" />
              <span className="font-bold text-indigo-600 text-2xl">
                InProfiler
              </span>
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <span
                    key={item.name}
                    onClick={() => handleNavigation(item.sectionId)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </span>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="https://calendly.com/d/333-9gn-r48/inprofiler-demo"
                  target="_blank"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-indigo-600 hover:bg-gray-50"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
