import React, { useContext } from "react";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import AffinitySetupModal from "./Affinity/AffinitySetupModal";
import useToggleState from "../../../hooks/useToggleState";
import AffinityLogo from "../../../img/affinitylogo.png";
import { Link } from "react-router-dom";

export default function IntegrationsSettings() {
  const { accountSettings } = useContext(SubscriptionContext);
  const [showAffinityModal, toggleShowAffinityModal] = useToggleState(false);
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-2 lg:px-8">
      <AffinitySetupModal
        open={showAffinityModal}
        toggleOpen={toggleShowAffinityModal}
      />
      <div className="border p-6 rounded-lg shadow-sm flex gap-6 items-center">
        <img src={AffinityLogo} className="h-14 w-14" />
        <div>
          <h2 className="text-lg font-semibold text-black">Affinity</h2>
          <p className="text-sm leading-6 text-gray-500">
            Connect your Affinity account and set criteria for automatically
            importing InProfiler leads into Affinity
          </p>
        </div>
        {accountSettings.has_affinity_integration ? (
          <Link
            to="/app/settings/affinity"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Configure
          </Link>
        ) : (
          <button
            onClick={toggleShowAffinityModal}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Activate
          </button>
        )}
      </div>

      <div className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"></div>
      </div>
    </div>
  );
}
