import React from "react";
import { classNames } from "../../helpers";

export default function EvaluationBadge({ score, small = false }) {
  if (score) {
    return (
      <span
        className={classNames(
          score >= 7
            ? "bg-green-50 ring-green-600/20 text-green-700"
            : score >= 4
            ? "bg-yellow-50 text-yellow-700 ring-yellow-600/20"
            : "bg-red-50 text-red-700 ring-red-600/20",
          small ? "px-2 py-1 text-xs" : "px-4 py-2 text-sm",
          "inline-flex items-center rounded-md font-medium  ring-1 ring-inset"
        )}
      >
        {score >= 7
          ? "High Potential"
          : score >= 4
          ? "Medium Potential"
          : "Low Potential"}
      </span>
    );
  } else {
    return (
      <span
        className={classNames(
          small ? "px-2 py-1 text-xs" : "px-4 py-2 text-sm",
          "bg-gray-50 ring-gray-6000 text-gray-600 inline-flex items-center rounded-md font-medium  ring-1 ring-inset"
        )}
      >
        Evaluation pending
      </span>
    );
  }
}
