import React from "react";
import { Link } from "react-router-dom";

export default function GetStartedVideo() {
  return (
    <div className="lg:w-1/2">
      <div className="rounded-xl px-10 flex flex-col gap-5">
        <div>
          <h4 className="font-semibold text-xl">Get Started with InProfiler</h4>
          <p className="text-gray-500">
            Watch this short video to find out where to go from here
          </p>
        </div>
        <div>
          <iframe
            className="shadow-xl rounded-xl w-[336px] h-[189px] md:w-[672px] md:h-[378px] lg:w-[560px] lg:h-[315px]"
            src="https://www.youtube.com/embed/rR6AIX5LHlM?controls=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
        <div className="flex gap-1">
          <span className="text-gray-500">Need more help getting started?</span>
          <Link
            className="text-blue-500 cursor-pointer hover:text-blue-600"
            to="/contact"
            target="_blank"
          >
            Contact us
          </Link>
        </div>
      </div>
    </div>
  );
}
