import React, { useEffect, useState } from "react";
import { useGetAdminUsers } from "../../../../api/AdminAPI";
import { getInitials } from "../../../../helpers";
import { MoonLoader } from "react-spinners";

export default function UsersList({ setSelectedUser }) {
  const getAdminUsers = useGetAdminUsers();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getAdminUsers(page)
      .then((data) => {
        setUsers([...users, ...data.results]);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  // handle scroll event
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      loadData();
    }
  };

  if (users && users.length > 0) {
    return (
      <div onScroll={handleScroll} className="h-full overflow-auto">
        {users.map((user) => (
          <div
            className="p-4 bg-white flex items-center gap-3 border-b cursor-pointer hover:bg-indigo-50"
            onClick={() => setSelectedUser(user)}
          >
            {user.user.profile_picture ? (
              <img
                src={user.user.profile_picture}
                className="h-8 w-8 rounded-full shrink-0"
              />
            ) : (
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 shrink-0">
                <span className="text-xs font-medium leading-none text-white">
                  {getInitials(user.user.full_name)}
                </span>
              </span>
            )}
            <div>
              <p className="text-sm">{user.user.full_name}</p>
              <p className="text-xs text-gray-500">{user.objective}</p>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="h-full flex items-center justify-center">
        <MoonLoader size={30} />
      </div>
    );
  }
}
