import React from "react";
import { classNames, getInitials } from "../../../../helpers";
import { XMarkIcon } from "@heroicons/react/20/solid";
import UserDetail from "./UserDetail";
import UsersList from "./UsersList";

export default function UsersColumn({ selectedUser, setSelectedUser }) {
  return (
    <div className="border-b border-gray-200 xl:w-96 xl:shrink-0 xl:border-b-0 xl:border-r h-full max-h-full bg-gray-50 relative">
      {/* Header */}
      <div
        className={classNames(
          selectedUser ? "bg-indigo-700 text-white" : "bg-white",
          "p-4 border-b sticky top-0"
        )}
      >
        {selectedUser ? (
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              {selectedUser.user.profile_picture ? (
                <img
                  src={selectedUser.user.profile_picture}
                  className="h-8 w-8 rounded-full"
                />
              ) : (
                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-400">
                  <span className="text-xs font-medium leading-none text-white">
                    {getInitials(selectedUser.user.full_name)}
                  </span>
                </span>
              )}
              <h3 className="font-semibold">{selectedUser.user.full_name}</h3>
            </div>
            <button
              onClick={() => setSelectedUser(null)}
              className="rounded-full bg-white p-1 text-indigo-600 hover:bg-gray-50 "
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        ) : (
          <h3 className="font-semibold">Select Users</h3>
        )}
      </div>

      <div className="content-height-reduced">
        {/* Body */}
        {selectedUser ? (
          <UserDetail user={selectedUser} />
        ) : (
          <UsersList setSelectedUser={setSelectedUser} />
        )}
      </div>
    </div>
  );
}
