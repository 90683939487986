import React, { useState, useEffect, useRef } from "react";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useGetAffinityFields } from "../../../../../../api/AffinityAPI";
import StyledSelect from "../../../../../UtilityComponents/StyledSelect";
import DefaultMappingItem from "./DefaultMappingItem";
import { classNames } from "../../../../../../helpers";

export default function DefaultFields({
  defaultFields,
  setDefaultFields,
  setError,
}) {
  const getAffinityFields = useGetAffinityFields();
  const containerRef = useRef(null);
  const [affinityPersonFields, setAffinityPersonFields] = useState([]);
  const [affinityOrgFields, setAffinityOrgFields] = useState([]);
  const [affinityFields, setAffinityFields] = useState([]);
  const [addField, setAddField] = useState();

  const fetchPersonFields = () => {
    getAffinityFields("persons")
      .then((data) => {
        setAffinityPersonFields(data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const fetchOrgFields = () => {
    getAffinityFields("organizations")
      .then((data) => {
        setAffinityOrgFields(data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    if (!affinityFields.length) {
      fetchPersonFields();
      fetchOrgFields();
    }
  }, []);

  useEffect(() => {
    if (affinityOrgFields && affinityPersonFields) {
      const annotatedPersonFields = affinityPersonFields.map((field) => ({
        ...field,
        selectDescription: "Person",
      }));
      const annotatedOrgFields = affinityOrgFields.map((field) => ({
        ...field,
        selectDescription: "Organization",
      }));
      const joined = annotatedPersonFields.concat(annotatedOrgFields);
      setAffinityFields(joined);
    }
  }, [affinityOrgFields, affinityPersonFields]);

  useEffect(() => {
    // Add new field to mapping after user selected it
    if (addField) {
      setDefaultFields((prevState) => [
        ...prevState,
        { field: addField, default: null },
      ]);
      setAddField(null);
    }
  }, [addField]);

  // Set default value on a field
  const handleFieldset = (fieldIdx, defaultValue) => {
    setDefaultFields((prevState) =>
      prevState.map((item, i) => {
        if (i !== fieldIdx) {
          return item;
        }
        return { ...item, default: defaultValue };
      })
    );
  };

  const removeItem = (field) => {
    setDefaultFields((prevState) => prevState.filter((item) => item != field));
  };

  return (
    <div className="space-y-5">
      <div className="space-y-3 " ref={containerRef}>
        <div className="flex gap-8 font-semibold text-sm">
          {defaultFields.length > 0 && (
            <>
              <span className="w-1/3">Affinity Field</span>
              <ArrowRightIcon className="h-6 w-6 invisible" />
              <span className="w-1/3">Default Value</span>
            </>
          )}
        </div>
        {defaultFields.map((field, idx) => (
          <div className="flex items-center gap-8 justify-start" key={idx}>
            <div className="py-1.5 px-3 border  border-gray-300 w-1/3 rounded-lg shadow-sm">
              <span className="text-sm">{field.field.name}</span>
            </div>
            <ArrowRightIcon className="h-6 w-6" />
            <DefaultMappingItem
              field={field}
              index={idx}
              handleFieldset={handleFieldset}
            />
            <button
              onClick={() => removeItem(field)}
              className={classNames(
                field.fixed && "invisible",
                "text-gray-500 p-1.5 rounded bg-gray-50 shadow-sm border"
              )}
            >
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        ))}
      </div>
      <div className="w-1/3 font-semibold">
        <StyledSelect
          choices={affinityFields}
          highlight
          placeholder="+ Add Default Value"
          selected={addField}
          setSelected={setAddField}
          expandUp
        />
      </div>
    </div>
  );
}
