import React from "react";
import DefaultFields from "../SetupSteps/DefaultFields/DefaultFields";
import { affinitySettingsPermissions } from "../../../../../permissions";

export default function DefaultFieldsSetting({
  defaultFields,
  setDefaultFields,
  accountUser,
  setError,
}) {
  if (!affinitySettingsPermissions.includes(accountUser.role.name)) {
    return;
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <div className="flex items-center gap-4">
          <h2 className="text-base font-semibold leading-7 text-black">
            Default field values
          </h2>
          <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-600 ring-1 ring-inset ring-indigo-500/10">
            Experimental
          </span>
        </div>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Assign default values that will always be set on the when a new person
          or organization is created in your Affinity account.
        </p>
      </div>

      <div className="md:col-span-2">
        <DefaultFields
          defaultFields={defaultFields}
          setDefaultFields={setDefaultFields}
          setError={setError}
        />
      </div>
    </div>
  );
}
