// Get name initials
export const getInitials = (name) => {
  let words;
  try {
    words = name.split(" ");
  } catch {
    words = ["User"];
  }
  let initials = "";

  for (let i = 0; i < words.length; i++) {
    initials += words[i].charAt(0);
  }

  if (words.length === 1) {
    initials += words[0].charAt(1) || "";
  }

  return initials.toUpperCase().slice(0, 2);
};

// Find and replace item in list by id
export const updateItemInState = (state, updatedItem) => {
  const updatedItems = state.map((item) => {
    if (item.id === updatedItem.id) {
      return updatedItem;
    }
    return item;
  });
  return updatedItems;
};

// Class names helper
export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

// Get cookie
export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Check if email is valid
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// Format date
export const formatDate = (dateTimeString) => {
  const datetime = new Date(dateTimeString); // create a new Date object
  const options = { day: "numeric", month: "numeric", year: "numeric" };
  const formattedDatetime = datetime.toLocaleDateString(
    navigator.locale,
    options
  );

  return formattedDatetime;
};
