import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";

export default function LandingLayout() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      let elem = document.querySelector(hash);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}
