import React, { createContext, useState, useEffect, useContext } from "react";
import useAxios from "../hooks/useAxios";
import { AuthContext } from "./AuthContext";
import LoadingScreen from "../components/Layout/LoadingScreen";
import SubscriptionExpired from "../components/SubscriptionPlans/SubscriptionExpired";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [currentAccountId, setCurrentAccountId] = useState();
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    localStorage.getItem(
      "subscription" ? JSON.parse(localStorage.getItem("subscription")) : null
    )
  );
  const [subscriptionAccount, setSubscriptionAccount] = useState(
    localStorage.getItem(
      "account" ? JSON.parse(localStorage.getItem("account")) : null
    )
  );
  const [accountUser, setAccountUser] = useState(
    localStorage.getItem(
      "accountUser" ? JSON.parse(localStorage.getItem("accountUser")) : null
    )
  );
  const [userActivity, setUserActivity] = useState(
    localStorage.getItem(
      "userActivity" ? JSON.parse(localStorage.getItem("userActivity")) : null
    )
  );
  const [accountSettings, setAccountSettings] = useState(
    localStorage.getItem(
      "accountSettings"
        ? JSON.parse(localStorage.getItem("accountSettings"))
        : null
    )
  );
  const [loading, setLoading] = useState(true);

  const api = useAxios();
  const {
    user,
    loginEvent,
    logoutEvent,
    loading: authLoading,
  } = useContext(AuthContext);

  const removeSubscriptionData = () => {
    setSubscriptionAccount(null);
    setAccountUser(null);
    setCurrentAccountId(null);
    setSubscriptionPlan(null);
    setUserActivity(null);
  };

  const getUserActivity = () => {
    if (!user) {
      setUserActivity(null);
      localStorage.removeItem("userActivity");
      return;
    }
    api.get("subscription/user-activity/").then((res) => {
      setUserActivity(res.data);
      localStorage.setItem("userActivity", JSON.stringify(res.data));
    });
    setLoading(false);
  };

  const getAccountUser = () => {
    if (!user) {
      setAccountUser(null);
      localStorage.removeItem("accountUser");
      return;
    }
    api.get("subscription/account-user/").then((res) => {
      setAccountUser(res.data);
      localStorage.setItem("accountUser", JSON.stringify(res.data));
    });
    setLoading(false);
  };

  const getSubscriptionAccount = () => {
    if (!user) {
      setSubscriptionAccount(null);
      localStorage.removeItem("subscriptionAccount");
      return;
    }
    api.get("subscription/account/").then((res) => {
      setSubscriptionAccount(res.data);
      localStorage.setItem("subscriptionAccount", JSON.stringify(res.data));
    });
    setLoading(false);
  };

  const getSubscriptionPlan = () => {
    if (!user) {
      setSubscriptionPlan(null);
      localStorage.removeItem("subscriptionPlan");
      return;
    }
    api.get("subscription/plan/").then((res) => {
      setSubscriptionPlan(res.data);
      localStorage.setItem("subscriptionPlan", JSON.stringify(res.data));
    });
    setLoading(false);
  };

  const getAccountSettings = () => {
    if (!user) {
      setAccountSettings(null);
      localStorage.removeItem("accountSettings");
      return;
    }
    api.get("subscription/account-settings/").then((res) => {
      setAccountSettings(res.data);
      localStorage.setItem("accountSettings", JSON.stringify(res.data));
    });
  };

  const getSubscriptionData = () => {
    if (user) {
      setLoading(true);
      api
        .get("subscription/subscription-data/")
        .then((res) => {
          // If user is new and does not have an account
          if (res.data.unconfigured) {
            setLoading(false);
            return;
          }
          // Current account id is used to update subscription context on change
          setCurrentAccountId(res.data.subscriptionAccount.id);
          // Set subscription in state and local storage
          setSubscriptionPlan(res.data.subscriptionPlan);
          localStorage.setItem(
            "subscriptionPlan",
            JSON.stringify(res.data.subscriptionPlan)
          );
          // Set account in state and local storage
          setSubscriptionAccount(res.data.subscriptionAccount);
          localStorage.setItem(
            "subscriptionAccount",
            JSON.stringify(res.data.subscriptionAccount)
          );
          // Set accountUser in state and local storage
          setAccountUser(res.data.accountUser);
          localStorage.setItem(
            "accountUser",
            JSON.stringify(res.data.accountUser)
          );
          // Set user activity in state and local storage
          setUserActivity(res.data.userActivity);
          localStorage.setItem(
            "userActivity",
            JSON.stringify(res.data.userActivity)
          );
          // Set account settings in state and local storage
          setAccountSettings(res.data.accountSettings);
          localStorage.setItem(
            "accountSettings",
            JSON.stringify(res.data.accountSettings)
          );
          // Set loading false
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (Object.keys(err.response.data).includes("account_user")) {
            setAccountUser(null);
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (!user && !authLoading) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (loginEvent || user) {
      getSubscriptionData();
    } else {
      removeSubscriptionData();
    }
  }, [loginEvent, currentAccountId]);

  useEffect(() => {
    if (userActivity) {
      // Pull data about available limits every 5 min
      const intervalId = setInterval(getUserActivity, 300000);
      return () => clearInterval(intervalId);
    }
  }, [userActivity]);

  useEffect(() => {
    if (logoutEvent) {
      removeSubscriptionData();
    }
  }, [logoutEvent]);

  const contextData = {
    subscriptionAccount,
    setSubscriptionAccount,
    currentAccountId,
    setCurrentAccountId,
    accountUser,
    setAccountUser,
    userActivity,
    accountSettings,
    setAccountSettings,
    setUserActivity,
    subscriptionPlan,
    setSubscriptionPlan,
    getUserActivity,
    getAccountUser,
    getSubscriptionAccount,
    getSubscriptionPlan,
    getAccountSettings,
  };

  return (
    <SubscriptionContext.Provider value={contextData}>
      {loading ? (
        <LoadingScreen />
      ) : subscriptionAccount && !subscriptionAccount.active ? (
        <SubscriptionExpired
          subscriptionAccount={subscriptionAccount}
          subscriptionPlan={subscriptionPlan}
        />
      ) : (
        children
      )}
    </SubscriptionContext.Provider>
  );
};
