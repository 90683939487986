import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../helpers";
import LoadingScreen from "../components/Layout/LoadingScreen";

const baseUrl = process.env.REACT_APP_API_URL;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);
  const [loginEvent, setLoginEvent] = useState(false);
  const [logoutEvent, setLogoutEvent] = useState(false);

  const navigate = useNavigate();

  const csrfToken = getCookie("csrftoken");

  const loginUser = async ({
    email,
    password,
    firstLogin = false,
    fromInvite = false,
    inviteLinkToken = null,
  }) => {
    try {
      const data = await authenticateUser(email, password);

      if (inviteLinkToken) {
        await createInviteFromLink(inviteLinkToken, data.access);
      }

      if (firstLogin) {
        navigateToFirstLogin(fromInvite);
      } else {
        await handleMultipleAccountsAndInvites(data.access);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const authenticateUser = async (email, password) => {
    const response = await fetch(`${baseUrl}users/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (response.status !== 200) {
      throw new Error("Authentication failed.");
    }

    const data = await response.json();

    setLogoutEvent(false);
    setAuthTokens(data);
    setUser(jwt_decode(data.access));
    localStorage.setItem("authTokens", JSON.stringify(data));

    return data;
  };

  const createInviteFromLink = async (inviteLinkToken, accessToken) => {
    await fetch(`${baseUrl}subscription/create-invite-from-link/`, {
      method: "POST",
      body: JSON.stringify({ token: inviteLinkToken }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  const navigateToFirstLogin = (fromInvite) => {
    if (fromInvite) {
      navigate(`/app/accounts/join?account=${fromInvite}`);
    } else {
      navigate("/app/accounts/create");
    }
  };

  const handleMultipleAccountsAndInvites = async (accessToken) => {
    const response = await fetch(
      `${baseUrl}users/check-user-accounts-invites/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const accountsData = await response.json();
    if (response.ok) {
      if (accountsData.has_multiple) {
        navigate("/app/accounts");
      } else {
        navigate("/app");
        setLoginEvent(true);
      }
    }
  };

  const registerUser = async ({
    email,
    password,
    password2,
    firstName,
    inviteToken = null,
    tokenType,
  }) => {
    const body = {
      email,
      password,
      password2,
      first_name: firstName,
    };
    if (inviteToken) {
      body["invite_token"] = inviteToken;
      body["token_type"] = tokenType;
    }

    const response = await fetch(`${baseUrl}users/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(body),
    });

    return response;
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    setLogoutEvent(true);
    setLoginEvent(false);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("subscription");
    localStorage.removeItem("account");
    localStorage.removeItem("accountUser");
    localStorage.removeItem("userActivity");

    navigate("/");
  };

  const requestPasswordReset = async (email) => {
    const response = await fetch(`${baseUrl}users/forgot-password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify({
        email,
      }),
    });
    return response;
  };

  const resetPassword = async (password1, password2, userId, token) => {
    const response = await fetch(
      `${baseUrl}users/reset-password/${userId}/${token}/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({
          password1,
          password2,
        }),
      }
    );
    return response.json();
  };

  const checkInviteToken = async (token) => {
    const response = await fetch(
      `${baseUrl}users/check-invite/?token=${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response;
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    loginEvent,
    logoutEvent,
    logoutUser,
    requestPasswordReset,
    resetPassword,
    checkInviteToken,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
      localStorage.setItem("authTokens", JSON.stringify(authTokens));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};
