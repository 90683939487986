import React, { useEffect, useState } from "react";
import EvaluationBadge from "../EvaluationBadge";

export default function LeadEvaluation({
  evaluation,
  leadCreation,
  getLeadEvaluation,
  leadType,
}) {
  const [loading, setLoading] = useState(false);

  const checkEvaluationFailure = () => {
    const currentDate = new Date();
    const tenMinutesAgo = new Date(currentDate.getTime() - 5 * 60000);
    const creationDate = new Date(leadCreation);

    if (creationDate < tenMinutesAgo) {
      return true;
    }
    return false;
  };

  const handleRequestNewEvaluation = () => {
    setLoading(true);
    getLeadEvaluation();
  };

  useEffect(() => {
    if (evaluation) {
      setLoading(false);
    }
  }, [evaluation]);

  if (evaluation) {
    return (
      <div>
        <div className="px-8 py-8 flex justify-between items-center border-t">
          <div className="space-y-2">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              Lead Evaluation Score: {evaluation.evaluation_score}
            </h3>
          </div>
          <div className="flex gap-2">
            <EvaluationBadge score={evaluation.evaluation_score} />
          </div>
        </div>
        <div className="border-t border-gray-100 py-4 px-8">
          <dl className="divide-y divide-gray-100">
            <div className="px-0 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Assessment
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {evaluation.text}
              </dd>
            </div>
          </dl>
          {evaluation.objective && (
            <dl className="divide-y divide-gray-100">
              <div className="px-0 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  User Objective
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {evaluation.objective}
                </dd>
              </div>
            </dl>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="px-8 py-8 flex justify-between items-center border-t">
        {checkEvaluationFailure() ? (
          <div className="space-y-2">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              Lead evaluation failed
            </h3>
            {loading ? (
              <p className="text-gray-500 font-semibold, text-sm">
                Loading new evaluation, please wait
              </p>
            ) : (
              <div>
                <p
                  className="text-blue-500 font-semibold text-sm cursor-pointer hover:text-blue-600"
                  onClick={handleRequestNewEvaluation}
                >
                  Click here to request a new evaluation
                </p>
                {leadType === "shared" && (
                  <p className="mt-1 text-xs text-gray-500">
                    Evaluation will be created based on the lead owner's
                    objective
                  </p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="space-y-2">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              Lead evaluation pending ...
            </h3>
            <p className="text-gray-500 text-sm">
              Please wait a moment and refresh the page to view the evaluation
            </p>
          </div>
        )}
      </div>
    );
  }
}
