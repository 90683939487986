import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AffinityLogo from "../../../../img/affinitylogo.png";
import useToggleState from "../../../../hooks/useToggleState";
import useInputState from "../../../../hooks/useInputState";
import {
  useCreateAffinityIntegration,
  useUpdateAffinityIntegration,
} from "../../../../api/AffinityAPI";
import {
  inprofilerOrganisationFields,
  inprofilerPersonFields,
} from "./constants/initialMapping";
import { Navigate } from "react-router-dom";
import SelectIntegrationType from "./SetupSteps/SelectIntegrationType";
import MapFields from "./SetupSteps/FieldMappings/MapFields";
import AutomaticLeadSharing from "./SetupSteps/AutomaticLeadSharing";
import EnterAPIKey from "./SetupSteps/EnterAPIKey";
import SelectLists from "./SetupSteps/SelectLists";
import DefaultFields from "./SetupSteps/DefaultFields/DefaultFields";

export default function AffinitySetupModal({ open, toggleOpen }) {
  // Hooks
  const createAffinityIntegration = useCreateAffinityIntegration();
  const updateAffinityIntegration = useUpdateAffinityIntegration();
  // Flow state
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [setupComplete, setSetupComplete] = useState(false);
  // Integration type
  const [integrationType, setIntegrationType] = useState("A");
  // Entities
  const [importPersons, toggleImportPersons, overwriteImportPersons] =
    useToggleState(true);
  const [importOrgs, toggleImportOrgs, overwriteImportOrgs] =
    useToggleState(true);
  // Auto sync
  const [enableAutoSync, toggleEnableAutoSync] = useToggleState(true);
  const [minimumLeadScore, setMinimumLeadScore] = useState(7);
  // API Key
  const [apiKey, setApiKey] = useInputState("");
  const [integrationId, setIntegrationId] = useState();
  // Lists
  const [selectedOrgList, setSelectedOrgList] = useState();
  const [selectedPersonsList, setSelectedPersonsList] = useState();
  // Person Mappings
  const [personFieldMapping, setPersonFieldMapping] = useState(
    inprofilerPersonFields.filter((i) => i.fieldProperties.preset === true)
  );
  const [additionalPersonFieldOptions, setAdditionalPersonFieldOptions] =
    useState(
      inprofilerPersonFields.filter((i) => i.fieldProperties.preset === false)
    );
  // Org Mappings
  const [orgFieldMapping, setOrgFieldMapping] = useState(
    inprofilerOrganisationFields.filter(
      (i) => i.fieldProperties.preset === true
    )
  );
  const [additionalOrgFieldOptions, setAdditionalOrgFieldOptions] = useState(
    inprofilerOrganisationFields.filter(
      (i) => i.fieldProperties.preset === false
    )
  );
  // Default mappings
  const [defaultFields, setDefaultFields] = useState([]);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const createIntegration = () => {
    if (!apiKey) {
      setError("Please enter your API Key");
    }
    setLoading(true);
    createAffinityIntegration(integrationType, apiKey)
      .then((data) => {
        setIntegrationId(data.id);
        setLoading(false);
        nextStep();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const setLists = () => {
    setLoading(true);
    updateAffinityIntegration({
      id: integrationId,
      importPersons,
      importOrgs,
      personsListId: selectedPersonsList ? selectedPersonsList.id : null,
      orgListId: selectedOrgList ? selectedOrgList.id : null,
    })
      .then((data) => {
        setLoading(false);
        nextStep();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const setFieldMapping = () => {
    setLoading(true);

    const removeFieldProperties = (mapping) =>
      mapping
        .filter((field) => field.targetField !== undefined)
        .map(({ fieldProperties, ...rest }) => rest);

    const personMapping = removeFieldProperties(personFieldMapping);
    const orgMapping = removeFieldProperties(orgFieldMapping);

    const body = {
      persons: personMapping,
      organizations: orgMapping,
      defaults: defaultFields,
    };

    updateAffinityIntegration({
      id: integrationId,
      fieldMappings: body,
    })
      .then((data) => {
        setLoading(false);
        nextStep();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const setAutoImport = () => {
    setLoading(true);
    updateAffinityIntegration({
      id: integrationId,
      enableAutoSync,
      minimumLeadScore,
    })
      .then((data) => {
        setLoading(false);
        setSetupComplete(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const steps = [
    {
      name: "Select Integration Type",
      description: "Choose how you would like your integratino to behave",
      nextAction: nextStep,
      index: 1,
    },
    {
      name: "Enter your API Key",
      description:
        "You can find your API Key by navigating to the User Settings in Affinity",
      nextAction: createIntegration,
      index: 2,
    },
    {
      name: "Import Settings",
      description:
        "Select if you want to import both persons and organizations, and which Affinity lists they should be assigned to",
      nextAction: setLists,
      index: 3,
    },
    {
      name: "Assign Fields for Personal Data",
      description:
        "When creating a lead in your Affinity account, InProfiler will write the available data about the person into these fields",
      nextAction: setFieldMapping,
      index: 4,
    },
    {
      name: "Assign Fields for Company Data",
      description:
        "When creating a company that's related to a lead in your Affinity Account, InProfiler will write the available data about the company into these fields",
      nextAction: setFieldMapping,
      index: 5,
    },
    {
      name: "Define Default Field Values",
      description:
        "Assign default values that will always be set on the when a new person or organization is created in your Affinity account. (Experimental feature, results may vary)",
      nextAction: setFieldMapping,
      index: 6,
    },
    {
      name: "Enable Automatic Lead Sync",
      description:
        "If this setting is active, leads will automatically synced to Affinity if they match the selected evaluation threshold",
      nextAction: setAutoImport,
      index: 7,
    },
  ];

  const handleNextClick = () => {
    setError(null);
    steps[step - 1].nextAction();
  };

  if (setupComplete) {
    return <Navigate to="/app/settings/affinity?setupComplete=true" />;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 h-4/5">
                <div className="flex gap-6 mt-3 center sm:mt-2 border-b pb-4">
                  <img src={AffinityLogo} className="h-14 w-14" />
                  <div className="">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900"
                    >
                      Affinity Integration Setup
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Please follow the instructions to set up your
                        integration. You can edit your preferences later on.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="space-y-5 px-4 py-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-black">
                      {steps[step - 1].name}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-500">
                      {steps[step - 1].description}
                    </p>
                  </div>
                  {(() => {
                    switch (step) {
                      case 1:
                        return (
                          <SelectIntegrationType
                            integrationType={integrationType}
                            setIntegrationType={setIntegrationType}
                          />
                        );
                      case 2:
                        return (
                          <EnterAPIKey
                            integrationType={integrationType}
                            setStep={setStep}
                            apiKey={apiKey}
                            setApiKey={setApiKey}
                            setLoading={setLoading}
                            setError={setError}
                          />
                        );
                      case 3:
                        return (
                          <SelectLists
                            integrationId={integrationId}
                            selectedOrgList={selectedOrgList}
                            setSelectedOrgList={setSelectedOrgList}
                            selectedPersonsList={selectedPersonsList}
                            setSelectedPersonsList={setSelectedPersonsList}
                            setLoading={setLoading}
                            setError={setError}
                            importPersons={importPersons}
                            toggleImportPersons={toggleImportPersons}
                            overwriteImportPersons={overwriteImportPersons}
                            importOrgs={importOrgs}
                            toggleImportOrgs={toggleImportOrgs}
                            overwriteImportOrgs={overwriteImportOrgs}
                          />
                        );
                      case 4:
                        return (
                          <MapFields
                            entity="persons"
                            setLoading={setLoading}
                            setError={setError}
                            fieldMapping={personFieldMapping}
                            setFieldMapping={setPersonFieldMapping}
                            additionalFieldOptions={
                              additionalPersonFieldOptions
                            }
                          />
                        );
                      case 5:
                        return (
                          <MapFields
                            entity="organizations"
                            fieldMapping={orgFieldMapping}
                            setFieldMapping={setOrgFieldMapping}
                            additionalFieldOptions={additionalOrgFieldOptions}
                          />
                        );
                      case 6:
                        return (
                          <DefaultFields
                            defaultFields={defaultFields}
                            setDefaultFields={setDefaultFields}
                          />
                        );
                      case 7:
                        return (
                          <AutomaticLeadSharing
                            enableAutoSync={enableAutoSync}
                            toggleEnableAutoSync={toggleEnableAutoSync}
                            minimumLeadScore={minimumLeadScore}
                            setMinimumLeadScore={setMinimumLeadScore}
                            integrationId={integrationId}
                          />
                        );
                    }
                  })()}
                </div>

                <div className="mt-5 sm:mt-6 flex justify-between border-t pt-6 px-4 items-center">
                  {error ? (
                    <p className="px-4 py-2 bg-red-50 text-red-500 rounded shadow-sm border border-red-500 text-sm">
                      {error}
                    </p>
                  ) : (
                    <nav
                      className="flex items-center justify-center"
                      aria-label="Progress"
                    >
                      <p className="text-sm font-medium">
                        Step {step} of {steps.length}
                      </p>
                      <ol
                        role="list"
                        className="ml-8 flex items-center space-x-5"
                      >
                        {steps.map((s) => (
                          <li key={s.index}>
                            {s.index < step ? (
                              <span className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900">
                                <span className="sr-only">{s.name}</span>
                              </span>
                            ) : s.index === step ? (
                              <span
                                className="relative flex items-center justify-center"
                                aria-current="num"
                              >
                                <span
                                  className="absolute flex h-5 w-5 p-px"
                                  aria-hidden="true"
                                >
                                  <span className="h-full w-full rounded-full bg-indigo-200" />
                                </span>
                                <span
                                  className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">{s.index}</span>
                              </span>
                            ) : (
                              <span className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                                <span className="sr-only">{s.name}</span>
                              </span>
                            )}
                          </li>
                        ))}
                      </ol>
                    </nav>
                  )}

                  <div className="flex gap-3">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600  hover:bg-gray-50 border border-indigo-600 disabled:bg-gray-50 disabled:text-gray-300 disabled:border-none"
                      onClick={previousStep}
                      disabled={loading}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 disabled:bg-gray-50 disabled:text-gray-300 disabled:border-none"
                      onClick={handleNextClick}
                      disabled={loading || error}
                    >
                      {step < steps.length ? "Next" : "Complete Setup"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
