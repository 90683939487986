import React, { useState } from "react";
import InputWithLabel from "../../Forms/InputWithLabel";
import useInputState from "../../../hooks/useInputState";
import { useChangePassword } from "../../../api/UserAPI";

export default function ChangePassword({ user }) {
  const [currentPassword, setCurrentPassword] = useInputState("");
  const [newPassword1, setNewPassword1] = useInputState("");
  const [newPassword2, setNewPassword2] = useInputState("");
  const [loading, setLoading] = useState(false);
  const [infoSaved, setInfoSaved] = useState(false);
  const [errors, setErrors] = useState();
  const changePassword = useChangePassword();

  const handleChangePassword = () => {
    setInfoSaved(false);
    setErrors(null);
    setLoading(true);
    changePassword(currentPassword, newPassword1, newPassword2)
      .then((data) => {
        setInfoSaved(true);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="grid min-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Change password
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Update your password associated with your account.
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <InputWithLabel
              small
              label="Current Password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={setCurrentPassword}
              disabled={loading}
              type="password"
            />
          </div>

          <div className="col-span-full">
            <InputWithLabel
              small
              label="New Password"
              placeholder="New Password"
              value={newPassword1}
              onChange={setNewPassword1}
              disabled={loading}
              type="password"
            />
          </div>

          <div className="col-span-full">
            <InputWithLabel
              small
              label="Confirm Password"
              placeholder="Confirm Password"
              value={newPassword2}
              onChange={setNewPassword2}
              disabled={loading}
              type="password"
            />
          </div>
        </div>

        <div className="mt-8 flex gap-4 items-center">
          <button
            onClick={handleChangePassword}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
          {errors && <span className="text-red-600 text-sm">{errors}</span>}
          {infoSaved && (
            <span className="text-green-600 text-sm">Password changed</span>
          )}
        </div>
      </div>
    </div>
  );
}
