import React from "react";
import useToggleState from "../../../hooks/useToggleState";
import { getInitials } from "../../../helpers";
import ChangeRoleModal from "./ChangeRoleModal";

export default function UserCard({
  user,
  handleDeleteClick,
  handleRoleChangeClick,
}) {
  return (
    <li
      key={user.id}
      className="col-span-2 divide-y divide-gray-200 rounded-lg bg-white border"
    >
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        {user.user.profile_picture ? (
          <img
            className="h-14 w-14 flex-shrink-0 rounded-full bg-gray-300"
            src={user.user.profile_picture}
            alt=""
          />
        ) : (
          <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-400">
            <span className="text-xl font-medium leading-none text-white">
              {getInitials(user.user.full_name)}
            </span>
          </span>
        )}
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-base font-medium text-gray-900">
              {user.user.full_name}
            </h3>
            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
              {user.role.name}
            </span>
          </div>
          <p className="mt-1 truncate text-sm text-gray-500 font-semibold">
            {user.job_title}
          </p>
          <p className="mt-1 truncate text-sm text-gray-500">
            {user.user.email}
          </p>
        </div>

        {user.role.name !== "Owner" && (
          <>
            <button
              onClick={() => handleRoleChangeClick(user.id)}
              className="rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-200"
            >
              Change Role
            </button>
            <button
              onClick={() => handleDeleteClick(user.id)}
              className="rounded-md bg-red-100 px-3 py-2 text-sm font-semibold text-red-500  hover:bg-red-200"
            >
              Remove User
            </button>
          </>
        )}
      </div>
      <div className="px-6 py-5 bg-slate-50">
        <p className="font-semibold text-gray-800 text-sm">Lead Objective:</p>
        <p className="text-gray-500 text-sm">{user.objective}</p>
      </div>
    </li>
  );
}
