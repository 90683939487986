import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { MoonLoader } from "react-spinners";
import {
  useSearchPerson,
  useExportLeadToAffinity,
} from "../../../api/AffinityAPI";
import AffinityLogo from "../../../img/affinitylogo.png";

export default function AffinityExportModal({ open, toggleOpen, leadId }) {
  const exportLeadToAffinity = useExportLeadToAffinity();
  const searchPerson = useSearchPerson();
  const [hasSimilarLeads, setHasSimilarLeads] = useState(false);
  const [similarLeads, setSimilarLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [exported, setExported] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [selectedDuplicate, setSelectedDuplicate] = useState(null);

  useEffect(() => {
    return () => {
      setError(null);
      setLoading(false);
      setHasSimilarLeads(false);
      setSimilarLeads([]);
      setExported(false);
      setSelectedDuplicate(null);
      setExporting(false);
    };
  }, []);

  useEffect(() => {
    if (leadId) {
      setError(null);
      setLoading(true);
      setHasSimilarLeads(false);
      setSimilarLeads([]);
      setExported(false);
      setSelectedDuplicate(null);

      searchPerson(leadId)
        .then((data) => {
          if (data.persons.length > 0) {
            setHasSimilarLeads(true);
            setSimilarLeads(data.persons);
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(err.response.data.message);
          setLoading(false);
        });
    }
  }, [leadId]);

  const exportLead = () => {
    setError(null);
    setExporting(true);
    exportLeadToAffinity({
      leadId,
      updatePersonId: selectedDuplicate ? selectedDuplicate.id : null,
    })
      .then((data) => {
        setExported(true);
        setExporting(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setExporting(false);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-y-scroll rounded-lg bg-white px-4 pb-0 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:pb-0 max-h-[460px]">
                <div className="flex items-center mb-3">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <img src={AffinityLogo} />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Export Lead to Affinity
                    </Dialog.Title>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="mt-2">
                      {loading || exporting ? (
                        <div className="text-sm text-gray-600 space-y-2 flex gap-2 items-end">
                          <MoonLoader size={15} />
                          <span className="font-semibold">
                            {loading
                              ? "Checking for duplicate leads"
                              : "Exporting lead, please wait"}
                          </span>
                        </div>
                      ) : error ? (
                        <div className="text-sm text-red-800 p-3 bg-red-50 space-y-2 w-full">
                          <span className="font-semibold flex gap-2 items-center">
                            <ExclamationTriangleIcon className="h-4 w-4" />
                            There has been an error
                          </span>
                          <p>{error}</p>
                        </div>
                      ) : exported ? (
                        <div className="text-sm text-green-800 p-3 bg-green-50 space-y-2">
                          <span className="font-semibold flex gap-2 items-center">
                            <CheckCircleIcon className="h-4 w-4" />
                            Lead Exported successfully
                          </span>
                        </div>
                      ) : hasSimilarLeads ? (
                        <>
                          <div className="text-sm text-yellow-800 p-3 bg-yellow-50 space-y-2 max-h-[400px] overflow-y-scroll">
                            <span className="font-semibold flex gap-2 items-center">
                              <ExclamationTriangleIcon className="h-4 w-4" />
                              This lead might be a duplicate
                            </span>
                            <p>
                              Select a person to update their information or
                              choose to create a new person
                            </p>
                          </div>
                          <div className="space-y-3 mt-3">
                            <ul className="divide-y divide w-full">
                              {similarLeads.map((person) => (
                                <li
                                  className="py-2 text-sm flex justify-between items-center pr-4 cursor-pointer"
                                  onClick={() => setSelectedDuplicate(person)}
                                >
                                  <div>
                                    <p>{`${person.first_name} ${person.last_name}`}</p>
                                    <p className="text-gray-500">
                                      {person.primary_email
                                        ? person.primary_email
                                        : "No email available"}
                                    </p>
                                  </div>
                                  <div className="ml-3 flex h-6 items-center">
                                    <input
                                      name="person"
                                      type="radio"
                                      checked={selectedDuplicate === person}
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <div className="text-sm text-green-800 p-3 bg-green-50 space-y-2">
                          <span className="font-semibold flex gap-2 items-center">
                            <CheckCircleIcon className="h-4 w-4" />
                            Looks like a fresh lead
                          </span>
                          <p>
                            No leads with this name have been found in your
                            Affinity account
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sticky bottom-0 bg-white pb-4 pt-3 border-t gap-3">
                  {exported ? (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:w-auto disabled:bg-gray-50 disabled:text-gray-500"
                      onClick={toggleOpen}
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:w-auto disabled:bg-gray-50 disabled:text-gray-500"
                        onClick={exportLead}
                        disabled={loading || exporting}
                      >
                        {hasSimilarLeads
                          ? selectedDuplicate
                            ? "Update selected person"
                            : "Create new Person"
                          : "Export Lead"}
                      </button>
                      {selectedDuplicate && (
                        <button
                          onClick={() => setSelectedDuplicate(null)}
                          disabled={exporting}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:bg-gray-50 disabled:text-gray-500"
                        >
                          Deselect
                        </button>
                      )}
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:bg-gray-50 disabled:text-gray-500"
                        onClick={toggleOpen}
                        disabled={exporting}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
