import { Link } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function SubscriptionSuccessAlert({ showInvite }) {
  return (
    <div className="rounded-md bg-green-50 p-4 mt-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Your subscription is active
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <p>
              Thank you for your trust! You can now use InProfiler according to
              the limits set in your new plan.
            </p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <Link
                to="/app/get-extension"
                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                Get Extension
              </Link>
              {showInvite && (
                <Link
                  to="/app/settings/users"
                  className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                >
                  Invite Users
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
