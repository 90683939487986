import React, { useState } from "react";

const Tooltip = ({ children, text, position }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const show = () => {
    setShowTooltip(true);
  };

  const hide = () => {
    setShowTooltip(false);
  };

  let tooltipClass =
    "bg-black/80 text-white text-sm rounded-md p-2 absolute opacity-7 z-100 max-w-xs whitespace-nowrap";

  switch (position) {
    case "top":
      tooltipClass += " bottom-full mb-3";
      break;
    case "right":
      tooltipClass += " left-full ml-3";
      break;
    case "bottom":
      tooltipClass += " top-full mt-3";
      break;
    case "left":
      tooltipClass += " right-full mr-3";
      break;
    default:
      tooltipClass += " top-full mt-3";
  }

  return (
    <div
      className="relative inline-block"
      onMouseEnter={show}
      onMouseLeave={hide}
    >
      {showTooltip && <div className={tooltipClass}>{text}</div>}
      {children}
    </div>
  );
};

export default Tooltip;
