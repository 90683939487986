import React from "react";
import InputWithLabel from "../../Forms/InputWithLabel";
import TextAreaWithLabel from "../../Forms/TextAreaWithLabel";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../helpers";

export default function SetupObjectives({
  jobTitle,
  setJobTitle,
  errors,
  company,
  setCompany,
  objective,
  setObjective,
  handleSubmit,
  prevStep,
  loading,
  fromInvite = false,
}) {
  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[680px]">
      <div className="space-y-5 bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
        <div>
          <h2 className="font-semibold leading-7 text-gray-900 text-xl">
            {fromInvite && company
              ? `Tell us about your work at ${company}`
              : "Tell us about your work"}
          </h2>
        </div>

        <InputWithLabel
          label="Job Title"
          helperText="This helps us better understand your professional status and potential needs."
          placeholder="Investment Associate"
          value={jobTitle}
          onChange={setJobTitle}
          autoFocus={true}
          disabled={loading}
        />
        {!fromInvite && (
          <InputWithLabel
            label="Company"
            helperText="This information assists us in tailoring the leads specifically for your industry"
            placeholder="Andreesen Horowitz"
            value={company}
            onChange={setCompany}
            disabled={loading}
          />
        )}
        <TextAreaWithLabel
          label="Lead objective"
          helperText="Tell us what you're looking for in the leads you wish to acquire."
          placeholder="I am looking for investment opportunities in early to mid stage startups in the healthcare sector"
          value={objective}
          onChange={setObjective}
          disabled={loading}
        />
        {objective.length > 0 && (
          <p
            className={classNames(
              objective.length < 100 ? "text-orange-700" : "text-green-700",
              "text-sm mt-1"
            )}
          >
            {objective.length < 100
              ? "For better results, your objective should have at least 100 characters."
              : "Good job defining your objective! You can add more info for better evaluations."}
          </p>
        )}
        {errors && (
          <div className="bg-red-50 my-4 px-4 py-2 rounded shadow-sm">
            <p className="text-sm text-red-600">{errors}</p>
          </div>
        )}
        <div className="rounded-lg bg-indigo-100 p-3 my-4">
          <div>
            <div className="flex gap-2 items-center">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-indigo-600"
                aria-hidden="true"
              />
              <h4 className="font-semibold text-indigo-700">Important</h4>
            </div>
            <p className="mt-1 leading-6 text-sm text-indigo-700">
              This information is critical to assess the potential value of your
              leads. Make sure to set a precise goal for better results.
            </p>
          </div>
        </div>
        <button
          type="button"
          className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Please wait" : "Complete Setup"}
        </button>
        {!fromInvite && (
          <button
            type="button"
            className="w-full rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 hover:bg-gray-50 "
            onClick={prevStep}
            disabled={loading}
          >
            Previous Step
          </button>
        )}
      </div>
    </div>
  );
}
