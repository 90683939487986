import React, { useState, useEffect } from "react";
import StyledSelect from "../../../../../UtilityComponents/StyledSelect";
import InputWithLabel from "../../../../../Forms/InputWithLabel";
import useInputState from "../../../../../../hooks/useInputState";

export default function DefaultMappingItem({ field, index, handleFieldset }) {
  const [selected, setSelected] = useState();
  const [value, setValue, overwriteValue] = useInputState("");

  useEffect(() => {
    if (selected) {
      handleFieldset(index, selected);
    }
  }, [selected]);

  useEffect(() => {
    if (value) {
      handleFieldset(index, value);
    }
  }, [value]);

  useEffect(() => {
    if (field && field.default) {
      if (field.field.dropdown_options.length) {
        setSelected(field.default);
      } else {
        overwriteValue(field.default);
      }
    }
  }, [field]);

  return (
    <div className="w-1/2">
      {field.field.dropdown_options.length ? (
        <StyledSelect
          choices={field.field.dropdown_options}
          selected={selected}
          setSelected={setSelected}
          labelKey="text"
          expandUp
        />
      ) : field.field.value_type === 3 ? (
        <InputWithLabel
          placeholder="Enter number"
          type="number"
          helperText={
            field.field.allows_multiple &&
            "Allows multiple - separate values by comma"
          }
          value={value}
          onChange={setValue}
        />
      ) : (
        <InputWithLabel
          placeholder="Enter value"
          helperText={
            field.field.allows_multiple &&
            "Allows multiple - separate values by comma"
          }
          value={value}
          onChange={setValue}
        />
      )}
    </div>
  );
}
