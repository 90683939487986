import React from "react";
import MapFields from "../SetupSteps/FieldMappings/MapFields";
import { affinitySettingsPermissions } from "../../../../../permissions";

export default function PersonalFieldsMapping({
  setLoading,
  setError,
  personFieldMapping,
  setPersonFieldMapping,
  additionalPersonFieldOptions,
  accountUser,
}) {
  if (!affinitySettingsPermissions.includes(accountUser.role.name)) {
    return;
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Personal data fields
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          When creating a lead in your Affinity account, InProfiler will write
          the available data about the person into these fields
        </p>
      </div>

      <div className="md:col-span-2">
        <MapFields
          entity="persons"
          setLoading={setLoading}
          setError={setError}
          fieldMapping={personFieldMapping}
          setFieldMapping={setPersonFieldMapping}
          additionalFieldOptions={additionalPersonFieldOptions}
        />
      </div>
    </div>
  );
}
