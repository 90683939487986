import useAxios from "../hooks/useAxios";

export const useCreateAffinityIntegration = () => {
  const api = useAxios();

  const createAffinityIntegration = (integrationType, apiKey) => {
    return api
      .post("integrations/affinity-integration-setup/", {
        integrationType,
        apiKey,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return createAffinityIntegration;
};

export const useUpdateAffinityIntegration = () => {
  const api = useAxios();

  const updateAffinityIntegration = (params) => {
    const {
      id,
      personsListId,
      orgListId,
      fieldMappings,
      enableAutoSync,
      minimumLeadScore,
      integrationType,
      importPersons,
      importOrgs,
    } = params;

    const body = {
      ...(integrationType && { integration_type: integrationType }),
      ...(importPersons !== null && { import_persons: importPersons }),
      ...(importOrgs !== null && { import_orgs: importOrgs }),
      ...(personsListId && { persons_list_id: personsListId }),
      ...(orgListId && { org_list_id: orgListId }),
      ...(fieldMappings && { field_mappings: fieldMappings }),
      ...(enableAutoSync !== null && { auto_sharing_active: enableAutoSync }),
      ...(minimumLeadScore && {
        auto_sharing_min_score: minimumLeadScore,
      }),
    };

    return api
      .patch(
        `integrations/affinity-integration-setup/${id}/`,
        JSON.stringify(body)
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return updateAffinityIntegration;
};

export const useGetAffinityIntegration = () => {
  const api = useAxios();

  const getAffinityIntegration = () => {
    return api
      .get("integrations/affinity-integration-setup/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getAffinityIntegration;
};

export const useGetAffinityLists = () => {
  const api = useAxios();

  const getAffinityLists = () => {
    return api
      .get("integrations/affinity-lists/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getAffinityLists;
};

export const useCreateAffinityList = () => {
  const api = useAxios();

  const createAffinityList = (listType, listName) => {
    return api
      .post("integrations/affinity-lists/", { listType, listName })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return createAffinityList;
};

export const useGetAffinityFields = () => {
  const api = useAxios();

  const getAffinityFields = (entity) => {
    let url = "integrations/affinity-fields/";

    if (entity) {
      url = url + `?entity=${entity}`;
    }

    return api
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return getAffinityFields;
};

export const useToggleAffinityIntegration = () => {
  const api = useAxios();

  const toggleAffinityIntegration = (id) => {
    return api
      .post(`integrations/toggle-affinity-integration/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return toggleAffinityIntegration;
};

export const useDeleteAffinityIntegration = () => {
  const api = useAxios();

  const deleteAffinityIntegration = (id) => {
    return api
      .delete(`integrations/delete-affinity-integration/${id}/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return deleteAffinityIntegration;
};

export const useConnectAffinityAccount = () => {
  const api = useAxios();

  const connectAffinityAccount = (apiKey) => {
    return api
      .post("integrations/connect-affinity-account/", { apiKey })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return connectAffinityAccount;
};

export const useDisconnectAffinityAccount = () => {
  const api = useAxios();

  const disconnectAffinityAccount = () => {
    return api
      .post("integrations/disconnect-affinity-account/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return disconnectAffinityAccount;
};

export const useDeleteUserIntegration = () => {
  const api = useAxios();

  const deleteUserIntegration = () => {
    return api
      .delete("integrations/delete-affinity-user-integration/")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return deleteUserIntegration;
};

export const useSearchPerson = () => {
  const api = useAxios();

  const searchPerson = (leadId) => {
    return api
      .get(`integrations/search-affinity-person/?leadId=${leadId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return searchPerson;
};

export const useExportLeadToAffinity = () => {
  const api = useAxios();

  const exportLeadToAffinity = ({ leadId, updatePersonId }) => {
    return api
      .post("integrations/affinity-export-single-lead/", {
        leadId,
        updatePersonId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return exportLeadToAffinity;
};
