import React, { useContext } from "react";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  TrashIcon,
  ShareIcon,
} from "@heroicons/react/20/solid";
import useToggleState from "../../../hooks/useToggleState";
import ConfirmDeleteModal from "../../UtilityComponents/ConfirmDeleteModal";
import Tooltip from "../../UtilityComponents/Tooltip";
import { classNames } from "../../../helpers";
import ShareLeadModal from "./ShareLeadModal";
import AffinityLogo from "../../../img/affinitylogo.png";
import AffinityExportModal from "./AffinityExportModal";

export default function FooterActions({
  leadId,
  deleteLead,
  rateEvaluation,
  evaluationRating,
  hasEvaluation,
  leadType,
}) {
  const [deleteModalOpen, toggleDeleteModalOpen] = useToggleState(false);
  const [shareModalOpen, toggleShareModalOpen] = useToggleState(false);
  const [exportModalOpen, toggleExportModalOpen] = useToggleState(false);
  const { accountUser, accountSettings } = useContext(SubscriptionContext);

  const handleDeleteConfirmed = () => {
    deleteLead(leadId);
    toggleDeleteModalOpen();
  };

  return (
    <div className="bg-gray-50 border-t shadow-md py-4 h-20 px-8 flex items-center justify-between">
      <ConfirmDeleteModal
        open={deleteModalOpen}
        toggleOpen={toggleDeleteModalOpen}
        handleConfirm={handleDeleteConfirmed}
        heading="Delete lead"
        text="Are you sure you want to delete this lead? It won't be imported again."
      />
      <ShareLeadModal
        open={shareModalOpen}
        toggleOpen={toggleShareModalOpen}
        leadId={leadId}
      />
      {accountSettings.affinity_integration_active && (
        <AffinityExportModal
          open={exportModalOpen}
          toggleOpen={toggleExportModalOpen}
          leadId={leadId}
        />
      )}

      {hasEvaluation ? (
        <div className="flex gap-2 items-center">
          <span className="text-gray-500 font-medium">Rate evaluation</span>
          <button
            onClick={() => rateEvaluation("GOOD")}
            type="button"
            className={classNames(
              evaluationRating === "GOOD"
                ? "bg-green-100 hover:bg-green-200"
                : "bg-gray-200 hover:bg-gray-300",
              "rounded-full p-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            )}
          >
            <HandThumbUpIcon
              className={classNames(
                evaluationRating === "GOOD"
                  ? "text-green-600"
                  : "text-gray-500",
                "h-4 w-4"
              )}
            />
          </button>
          <button
            onClick={() => rateEvaluation("BAD")}
            type="button"
            className={classNames(
              evaluationRating === "BAD"
                ? "bg-red-100 hover:bg-red-200"
                : "bg-gray-200 hover:bg-gray-300",
              "rounded-full p-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            )}
          >
            <HandThumbDownIcon
              className={classNames(
                evaluationRating === "BAD" ? "text-red-500" : "text-gray-500",
                "h-4 w-4"
              )}
            />
          </button>
        </div>
      ) : (
        <div></div>
      )}
      <div className="flex gap-5">
        {accountSettings.affinity_integration_active &&
          (accountSettings.affinity_integration_type === "A" ||
            accountSettings.affinity_connected_users.includes(
              accountUser.id
            )) && (
            <Tooltip text="Export to Affinity" position="left">
              <button onClick={toggleExportModalOpen}>
                <img src={AffinityLogo} className="h-8 w-8" />
              </button>
            </Tooltip>
          )}

        {leadType === "owner" && (
          <Tooltip text="Share Lead" position="left">
            <button
              onClick={toggleShareModalOpen}
              type="button"
              className="rounded-full bg-gray-200 p-2 text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              <ShareIcon className="h-4 w-4 text-blue-500" />
            </button>
          </Tooltip>
        )}
        {(leadType === "owner" ||
          accountUser.role.name === "Owner" ||
          accountUser.role.name === "Admin") && (
          <Tooltip text="Delete Lead" position="left">
            <button
              onClick={toggleDeleteModalOpen}
              type="button"
              className="rounded-full bg-gray-200 p-2 text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              <TrashIcon className="h-4 w-4 text-red-500" />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
