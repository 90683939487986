export const staticMapping = {
  persons: [
    {
      name: "First Name",
      targetFieldName: "First Name",
    },
    {
      name: "Last Name",
      targetFieldName: "Last Name",
    },
  ],
  organizations: [
    {
      name: "Company Name",
      targetFieldName: "Name",
    },

    {
      name: "Website",
      targetFieldName: "Domain",
    },
  ],
};

export const inprofilerPersonFields = [
  {
    sourceFieldName: "first_name",
    fieldProperties: {
      name: "First Name",
      preset: false,
      targetFieldId: null,
      suggestedFieldName: null,
      fixed: false,
    },
  },
  {
    sourceFieldName: "last_name",
    fieldProperties: {
      name: "Last Name",
      preset: false,
      targetFieldId: null,
      suggestedFieldName: null,
      fixed: false,
    },
  },
  {
    sourceFieldName: "headline",
    fieldProperties: {
      name: "Linkedin Profile Headline",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "person_summary",
    fieldProperties: {
      name: "Linkedin Profile Summary",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "person_country",
    fieldProperties: {
      name: "Country",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "Location",
    },
  },
  {
    sourceFieldName: "person_city",
    fieldProperties: {
      name: "City",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "person_occupation",
    fieldProperties: {
      name: "Occupation",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "current_company",
    fieldProperties: {
      name: "Current Company",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "job_title",
    fieldProperties: {
      name: "Job Title",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "Job Titles",
    },
  },
  {
    sourceFieldName: "person_languages",
    fieldProperties: {
      name: "Languages",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "mutual_connections_count",
    fieldProperties: {
      name: "Mutual connections count",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "custom_message",
    fieldProperties: {
      name: "Linkedin Request Message",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "_OWNER",
    fieldProperties: {
      name: "InProfiler Lead Owner",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "Source of Introduction",
    },
  },
  {
    sourceFieldName: "created",
    fieldProperties: {
      name: "InProfiler Import Date",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "profile_url",
    fieldProperties: {
      name: "Linkedin Profile URL",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "LinkedIn URL",
    },
  },
];

export const inprofilerOrganisationFields = [
  {
    sourceFieldName: "name",
    fieldProperties: {
      name: "Company Name",
      preset: false,
      targetFieldId: null,
      suggestedFieldName: null,
    },
  },
  {
    sourceFieldName: "website",
    fieldProperties: {
      name: "Website",
      preset: false,
      targetFieldId: null,
      suggestedFieldName: null,
    },
  },
  {
    sourceFieldName: "tagline",
    fieldProperties: {
      name: "Company Tagline",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "description",
    fieldProperties: {
      name: "Company Description",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "_OWNER",
    fieldProperties: {
      name: "InProfiler Lead Owner",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "Source of Introduction",
    },
  },
  {
    sourceFieldName: "industry",
    fieldProperties: {
      name: "Industry",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "Industry",
    },
  },
  {
    sourceFieldName: "company_size",
    fieldProperties: {
      name: "Company Size",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "hq_country",
    fieldProperties: {
      name: "Headquarter Country",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "Location",
    },
  },
  {
    sourceFieldName: "hq_city",
    fieldProperties: {
      name: "Headquarter City",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "company_type",
    fieldProperties: {
      name: "Company Type",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "founded_year",
    fieldProperties: {
      name: "Founded Year",
      preset: false,
      targetFieldId: null,
    },
  },
  {
    sourceFieldName: "linkedin_url",
    fieldProperties: {
      name: "Linkedin Page URL (Company)",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: "LinkedIn URL",
    },
  },
  {
    sourceFieldName: "_LEAD_LINKEDIN_URL",
    fieldProperties: {
      name: "Linkedin Profile URL (Person)",
      preset: true,
      targetFieldId: null,
      suggestedFieldName: null,
    },
  },
];
