import React from "react";
import SelectIntegrationType from "../SetupSteps/SelectIntegrationType";
import { affinitySettingsPermissions } from "../../../../../permissions";

export default function IntegrationTypeSetting({
  integrationType,
  setIntegrationType,
  accountUser,
}) {
  if (!affinitySettingsPermissions.includes(accountUser.role.name)) {
    return;
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-black">
          Integration Type
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Select how you would like the integration to behave
        </p>
      </div>

      <div className="md:col-span-2">
        <SelectIntegrationType
          integrationType={integrationType}
          setIntegrationType={setIntegrationType}
        />
      </div>
    </div>
  );
}
