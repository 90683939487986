import React, { useContext } from "react";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import StatsCards from "../../components/Dashboard/StatsCards";
import LeadsSuggestions from "../../components/Dashboard/LeadsSuggestions";
import GetStartedVideo from "../../components/Dashboard/GetStartedVideo";
import { Navigate } from "react-router-dom";

export default function Dashboard() {
  const { userActivity } = useContext(SubscriptionContext);

  try {
    return (
      <div className="space-y-8 overflow-scroll">
        <DashboardHeader />
        {userActivity.requests_made < 1 ? (
          <GetStartedVideo />
        ) : (
          <>
            <StatsCards />
            <LeadsSuggestions />
          </>
        )}
      </div>
    );
  } catch (err) {
    return <Navigate to="/app/accounts/create" />;
  }
}
