import React, { useState, useEffect, useRef } from "react";

const MultiSelectDropdown = ({ options, selectedState, setSelectedState }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const toggleSelectOption = (option) => {
    setSelectedState((prev) => {
      if (prev.includes(option)) {
        return prev.filter((o) => o !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div
      className="relative inline-block text-left z-50 w-full"
      ref={dropdownRef}
    >
      <div>
        <span className="rounded-md shadow-sm">
          <button
            onClick={toggleDropdown}
            type="button"
            className="inline-flex justify-center w-full rounded-md px-4 py-2 bg-gray-50 border text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 transition ease-in-out duration-150"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            {selectedState.length > 0
              ? `${selectedState.length} selected`
              : "Click to select"}
          </button>
        </span>
      </div>

      {dropdownOpen && (
        <div
          className={
            "origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bottom-full mb-2"
          }
        >
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1 overflow-auto max-h-40"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => toggleSelectOption(option.id)}
                  className={`block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-50 hover:text-gray-900 focus:outline-none  ${
                    selectedState.includes(option.id)
                      ? "bg-blue-50 text-gray-900"
                      : ""
                  }`}
                  role="menuitem"
                >
                  {option.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
