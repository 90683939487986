import React from "react";

export default function AutoSharing({ automaticSharing, setAutomaticSharing }) {
  return (
    <div className="sm:col-span-6">
      <label className="text-sm font-semibold text-gray-900">
        Share Leads automatically
      </label>
      <p className="text-sm text-gray-500">
        All user's imported leads can be automatically shared with the rest of
        the team
      </p>
      <fieldset className="mt-4">
        <legend className="sr-only">Notification method</legend>
        <div className="space-y-4">
          {[
            { label: "Activated", value: true },
            { label: "Deactivated", value: false },
          ].map((option) => (
            <div
              key={option.label}
              className="flex items-center"
              onClick={() => setAutomaticSharing(option.value)}
            >
              <input
                id={option.label}
                name="notification-method"
                type="radio"
                checked={automaticSharing === option.value}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor={option.label}
                className="ml-3 block text-sm font-medium leading-6 text-gray-900"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
