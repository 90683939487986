import React, { useEffect, useState } from "react";
import LeadsListItem from "./LeadsListItem";
import useToggleState from "../../../hooks/useToggleState";
import {
  AdjustmentsVerticalIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import OnlyUnseenFilter from "./OnlyUnseenFilter";
import PotentialFilter from "./PotentialFilter";

export default function RequestsList({
  leads,
  selectedLead,
  setSelectedLead,
  leadType,
}) {
  const [showFilters, toggleShowFilters] = useToggleState(false);
  const [onlyShowUnseen, toggleOnlyShowUnseen] = useToggleState(false);
  const [displayLeads, setDisplayLeads] = useState(leads);
  const [potentialFilter, setPotentialFilter] = useState("Show All");

  const potentialChoices = [
    "Show All",
    "Low Potential",
    "Medium Potential",
    "High Potential",
  ];

  const filterLeads = () => {
    let filteredLeads = [...leads];

    // Filter by potential
    if (potentialFilter === "Low Potential") {
      filteredLeads = filteredLeads.filter(
        (r) =>
          r.evaluation &&
          r.evaluation.evaluation_score !== null &&
          r.evaluation.evaluation_score < 4
      );
    } else if (potentialFilter === "Medium Potential") {
      filteredLeads = filteredLeads.filter(
        (r) =>
          r.evaluation &&
          r.evaluation.evaluation_score !== null &&
          r.evaluation.evaluation_score >= 4 &&
          r.evaluation.evaluation_score < 7
      );
    } else if (potentialFilter === "High Potential") {
      filteredLeads = filteredLeads.filter(
        (r) =>
          r.evaluation &&
          r.evaluation.evaluation_score !== null &&
          r.evaluation.evaluation_score >= 7
      );
    }

    // Filter by unseen
    if (onlyShowUnseen) {
      filteredLeads = filteredLeads.filter((r) => !r.seen);
    }

    setDisplayLeads(filteredLeads);
  };

  useEffect(() => {
    if (leads) {
      filterLeads();
    }
  }, [leads, onlyShowUnseen, potentialFilter]);

  return (
    <div className="content-height-max content-height overflow-y-scroll flex flex-col">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sticky top-0 z-10 shadow-sm space-y-4">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 className="ml-4 mt-2 text-lg font-semibold leading-6 text-gray-900">
            {leadType === "owner" ? "Your Leads" : "Shared Leads"}
          </h3>
          {showFilters ? (
            <button
              type="button"
              onClick={toggleShowFilters}
              className="inline-flex items-center gap-x-1.5 rounded-md mt-2 px-2.5 py-1.5 text-sm font-semibold text-black border shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Hide Filters
              <EyeSlashIcon className="ml-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleShowFilters}
              className="inline-flex items-center gap-x-1.5 rounded-md mt-2 px-2.5 py-1.5 text-sm font-semibold text-black border shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Filters
              <AdjustmentsVerticalIcon
                className="ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
        {showFilters && (
          <div className="flex justify-between">
            {leadType === "owner" ? (
              <OnlyUnseenFilter
                onlyShowUnseen={onlyShowUnseen}
                toggleOnlyShowUnseen={toggleOnlyShowUnseen}
              />
            ) : (
              <div></div>
            )}

            <PotentialFilter
              potentialChoices={potentialChoices}
              potentialFilter={potentialFilter}
              setPotentialFilter={setPotentialFilter}
            />
          </div>
        )}
      </div>
      <ul className="divide-y divide-gray-100 flex flex-col">
        {displayLeads &&
          displayLeads.map((lead) => (
            <LeadsListItem
              selectedLead={selectedLead}
              setSelectedLead={setSelectedLead}
              lead={lead}
              leadType={leadType}
            />
          ))}
      </ul>
    </div>
  );
}
