import { AuthProvider } from "../context/AuthContext";
import Login from "../views/App/Login";
import Register from "../views/App/Register";
import ResetPassword from "../views/App/ResetPassword";
import ForgotPassword from "../views/App/ForgotPassword";
import PageNotFound from "../views/App/PageNotFound";
import InviteLinkLanding from "../views/App/InviteLinkLanding";

export const LoginRoute = () => (
  <AuthProvider>
    <Login />
  </AuthProvider>
);

export const RegisterRoute = () => (
  <AuthProvider>
    <Register />
  </AuthProvider>
);

export const ResetPasswordRoute = () => (
  <AuthProvider>
    <ResetPassword />
  </AuthProvider>
);

export const ForgotPasswordRoute = () => (
  <AuthProvider>
    <ForgotPassword />
  </AuthProvider>
);

export const PageNotFoundRoute = () => (
  <AuthProvider>
    <PageNotFound />
  </AuthProvider>
);

export const InviteLinkLandingRoute = () => (
  <AuthProvider>
    <InviteLinkLanding />
  </AuthProvider>
);
