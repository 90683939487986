import React, { useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import useToggleState from "../../../hooks/useToggleState";
import useAxios from "../../../hooks/useAxios";
import InviteUserModal from "./InviteUserModal";
import InviteErrorAlert from "./InviteErrorAlert";
import InviteSuccessAlert from "./InviteSuccessAlert";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteModal";
import UserCard from "./UserCard";
import InviteCard from "./InviteCard";
import InviteLinkModal from "./InviteLinkModal";
import ChangeRoleModal from "./ChangeRoleModal";
import {
  useInviteUser,
  useRemoveUser,
  useGetInvites,
  useGetAccountUsers,
  useDeleteInvite,
  useChangeRole,
} from "../../../api/UserAPI";
import { accountUsersPermissions } from "../../../permissions";
import { Navigate } from "react-router-dom";

export default function AccountUsers({ userRole }) {
  const { subscriptionPlan } = useContext(SubscriptionContext);
  const api = useAxios();
  const [showInviteModal, toggleShowInviteModal] = useToggleState(false);
  const [showLinkModal, toggleShowLinkModal] = useToggleState(false);
  const [showConfirmDelete, toggleShowConfirmDelete] = useToggleState(false);
  const [showChangeRoleModal, toggleShowChangeRoleModal] =
    useToggleState(false);
  const [inviteSent, setInviteSent] = useState();
  const [inviteError, setInviteError] = useState(false);
  const [users, setUsers] = useState();
  const [usersError, setUsersError] = useState();
  const [userToDelete, setUserToDelete] = useState();
  const [changeRoleUser, setChangeRoleUser] = useState();
  const [invites, setInvites] = useState();
  const [seatsAvailable, setSeatsAvailable] = useState(false);
  const inviteUser = useInviteUser();
  const removeUser = useRemoveUser();
  const getInvites = useGetInvites();
  const getAccountUsers = useGetAccountUsers();
  const deleteInvite = useDeleteInvite();
  const changeRole = useChangeRole();

  const handleInviteUser = (email, selectedRole) => {
    setInviteError(null);
    setInviteSent(null);
    inviteUser(email, selectedRole)
      .then((data) => {
        setInviteSent(email);
        getInvites();
      })
      .catch((err) => {
        setInviteError(err.response.data.message);
      });
  };

  const handleGetAccountUsers = () => {
    getAccountUsers()
      .then((data) => setUsers(data))
      .catch((err) => setUsersError(err.response.data.message));
  };

  const handleGetInvites = () => {
    getInvites()
      .then((data) => setInvites(data))
      .catch((err) => console.log(err.response.data.message));
  };

  useEffect(() => {
    handleGetAccountUsers();
    handleGetInvites();
  }, []);

  useEffect(() => {
    if (invites && users && subscriptionPlan) {
      if (subscriptionPlan.included_users > invites.length + users.length) {
        setSeatsAvailable(true);
      } else {
        setSeatsAvailable(false);
      }
    }
  }, [invites, users, subscriptionPlan]);

  const handleDeleteClick = (id) => {
    setUserToDelete(id);
    toggleShowConfirmDelete();
  };

  const handleDeleteInvite = (id) => {
    deleteInvite(id)
      .then((data) => {
        setInvites((prevInvites) =>
          prevInvites.filter((invite) => invite.id !== id)
        );
      })
      .catch((err) => console.log(err.response.data.message));
  };

  const handleRemoveUser = () => {
    removeUser(userToDelete)
      .then((data) => {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== userToDelete)
        );
        toggleShowConfirmDelete();
      })
      .catch((err) => {
        setUsersError(err.response.data.message);
      });
  };

  const handleRoleChangeClick = (id) => {
    setChangeRoleUser(id);
    toggleShowChangeRoleModal();
  };

  const handleChangeRole = (newRole) => {
    changeRole(changeRoleUser, newRole).then((data) => {
      toggleShowChangeRoleModal();
      handleGetAccountUsers();
    });
  };

  if (accountUsersPermissions.includes(userRole)) {
    return (
      <div className="grid min-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <InviteUserModal
          open={showInviteModal}
          toggleOpen={toggleShowInviteModal}
          inviteUser={handleInviteUser}
        />
        <InviteLinkModal
          open={showLinkModal}
          toggleOpen={toggleShowLinkModal}
          seatsAvailable={seatsAvailable}
        />
        <ConfirmDeleteModal
          heading="Delete user"
          text="This user will be removed from this account permanently and some leads might be lost."
          open={showConfirmDelete}
          toggleOpen={toggleShowConfirmDelete}
          handleConfirm={handleRemoveUser}
        />
        <ChangeRoleModal
          open={showChangeRoleModal}
          toggleOpen={toggleShowChangeRoleModal}
          handleConfirm={handleChangeRole}
        />
        <div>
          <h2 className="text-base font-semibold leading-7 text-black">
            Account Users
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Manage the people working on this account
          </p>

          {inviteSent && <InviteSuccessAlert email={inviteSent} />}
          {inviteError && <InviteErrorAlert error={inviteError} />}

          <div className="flex gap-4">
            <button
              onClick={toggleShowInviteModal}
              className="mt-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Invite Users
            </button>
            <button
              onClick={toggleShowLinkModal}
              className="mt-5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm border border-indigo-600 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Create Invite Link
            </button>
          </div>
        </div>

        <div className="md:col-span-2">
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2"
          >
            {users &&
              users.map((user) => (
                <UserCard
                  user={user}
                  handleDeleteClick={handleDeleteClick}
                  handleRoleChangeClick={handleRoleChangeClick}
                />
              ))}
          </ul>
          {invites && invites.length > 0 && (
            <>
              <hr className="my-8" />
              <h3 className="my-5 text-lg font-semibold text-gray-900">
                Pending Invites
              </h3>
              <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                {invites.map((invite) => (
                  <InviteCard
                    invite={invite}
                    deleteInvite={handleDeleteInvite}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return <Navigate to="/app/settings" />;
  }
}
