import { useState } from "react";

function useToggleState(initialVal = false) {
  const [state, setState] = useState(initialVal);
  const toggle = () => {
    setState(!state);
  };
  const overwrite = (val) => {
    setState(val);
  };
  return [state, toggle, overwrite];
}
export default useToggleState;
