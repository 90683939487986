import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { Link } from "react-router-dom";
import BetaBanner from "./BetaBanner";
import FreeTrialBanner from "./FreeTrialBanner";

export default function DashboardHeader() {
  const { user } = useContext(AuthContext);
  const {
    subscriptionPlan,
    userActivity,
    getSubscriptionPlan,
    getUserActivity,
  } = useContext(SubscriptionContext);
  return (
    <div className="overflow-hidden rounded-md bg-white shadow pt-14 pb-10 px-8 space-y-10">
      <div>
        <h1 className="text-3xl font-bold mb-2">
          Hello, {user && user.firstName}!
        </h1>
        <span className="text-gray-500 text-base">
          Install our{" "}
          <Link
            className="text-blue-500 hover:text-blue-600 cursor-pointer"
            to="/app/get-extension"
          >
            Chrome extension
          </Link>{" "}
          and start analyzing your LinkedIn inbound leads.
        </span>
      </div>

      {subscriptionPlan && subscriptionPlan.name === "Trial" ? (
        <BetaBanner
          plan={subscriptionPlan.name}
          getSubscriptionPlan={getSubscriptionPlan}
          getUserActivity={getUserActivity}
          requestsMade={userActivity.requests_made}
          remainingRequests={
            subscriptionPlan.monthly_user_requests - userActivity.requests_made
          }
        />
      ) : (
        subscriptionPlan.name === "Trial Extended" && (
          <FreeTrialBanner
            plan={subscriptionPlan.name}
            requestsMade={userActivity.requests_made}
            remainingRequests={
              subscriptionPlan.monthly_user_requests -
              userActivity.requests_made
            }
          />
        )
      )}
    </div>
  );
}
