import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../helpers";
import InputWithLabel from "../../Forms/InputWithLabel";

const accountTypes = [
  {
    id: 1,
    title: "For my company",
    description:
      "I want to invite my team to InProfiler and share leads with them.",
    value: "company",
  },
  {
    id: 2,
    title: "For myself",
    description:
      "I will use InProfiler for my personal needs and don't need to invite anyone.",
    value: "personal",
  },
];

export default function SetupAccount({
  selectedAccountType,
  setSelectedAccountType,
  company,
  setCompany,
  nextStep,
}) {
  const checkForEnterSubmit = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      nextStep();
    }
  };

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[680px]">
      <div className="space-y-5 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <div>
          <h2 className="font-semibold leading-7 text-gray-900 text-xl">
            How do you plan to use InProfiler?
          </h2>
          <p className="mt-1 leading-6 text-gray-600">
            We'll use this info to set up your account. If you are not sure, you
            can change your selection later.
          </p>
        </div>
        <RadioGroup
          value={selectedAccountType}
          onChange={setSelectedAccountType}
        >
          <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            {accountTypes.map((accountType) => (
              <RadioGroup.Option
                key={accountType.id}
                value={accountType.value}
                className={({ checked, active }) =>
                  classNames(
                    selectedAccountType === accountType.value
                      ? "border-indigo-600 ring-2 ring-indigo-600"
                      : "border-gray-300",
                    "relative flex cursor-pointer rounded-lg border bg-white p-4 pb-12 shadow-sm focus:outline-none"
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className="block text-md font-medium text-gray-900"
                        >
                          {accountType.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="mt-1 flex items-center text-base text-gray-500"
                        >
                          {accountType.description}
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-indigo-600"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-indigo-600" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        {selectedAccountType === "company" && (
          <div>
            <hr className="my-5" />
            <InputWithLabel
              label="Company Name"
              small
              placeholder="Company Name"
              autoFocus={true}
              value={company}
              onChange={setCompany}
              onKeyDown={checkForEnterSubmit}
            />
          </div>
        )}
        <button
          type="button"
          className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={nextStep}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
