import React from "react";
import AffinityLogo from "../../../../../img/affinitylogo.png";

export default function Header({
  integration,
  toggleShowDeleteModal,
  toggleShowDeactivationModal,
  toggleIntegrationActivation,
  accountUser,
}) {
  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-10 pb-10 flex justify-between items-center border-b">
      <div className="flex gap-6 items-center">
        <img src={AffinityLogo} className="h-12 w-12" />
        <div>
          <h1 className="text-xl font-bold">Affinity Integration</h1>
          <p className="text-gray-500 text-sm">
            Connect InProfiler to your Affinity account to track lead
            interactions
          </p>
        </div>
      </div>
      <div className="space-x-3">
        <button
          onClick={
            integration.active
              ? toggleShowDeactivationModal
              : toggleIntegrationActivation
          }
          className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 hover:bg-gray-50 border border-indigo-600"
        >
          {integration.active ? "Deactivate" : "Activate"}
        </button>
        <button
          onClick={toggleShowDeleteModal}
          className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 hover:bg-gray-50 border border-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
