import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const AdminOutlet = ({ children, ...rest }) => {
  let { user } = useContext(AuthContext);
  return !user ? (
    <Navigate to="/login" />
  ) : user.rank !== "admin" ? (
    <Navigate to="/app" />
  ) : (
    <Outlet />
  );
};

export default AdminOutlet;
