import React from "react";
import {
  TrophyIcon,
  ArrowPathIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";

const steps = [
  {
    title: "1. Set your objectives",
    description:
      "Create your InProfiler account and define your principal objective. Whether you're looking to connect with early-stage founders in the ed-tech sector, or any specific profile, set your objective with ease.",
    icon: TrophyIcon,
  },
  {
    title: "2. Sync with Linkedin",
    description:
      "Install our Google Chrome extension and import your LinkedIn connection requests. With just a few clicks, your LinkedIn leads are ready to be processed in InProfiler.",
    icon: ArrowPathIcon,
  },
  {
    title: "3. Get instant Lead Evaluations",
    description:
      "Watch as InProfiler automatically evaluates each imported lead based on your set objective. You'll receive categorized leads, helping you focus on high-potential connections and manage your leads effectively.",
    icon: ArrowTrendingUpIcon,
  },
];

export default function HowItWorks() {
  return (
    <div className="bg-white py-24 sm:py-32" id="how-it-works">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Easy Lead Management
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How InProfiler works
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Discover a seamless, three-step process to optimize your LinkedIn
            networking and lead management.
          </p>
        </div>
      </div>

      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <div className="flex flex-col gap-20 lg:gap-0 lg:flex-row items-center justify-between">
          <dl className="mx-auto lg:w-1/2 text-base leading-7 text-gray-600 flex flex-col gap-8 lg:gap-10 lg:pr-5">
            {steps.map((step) => (
              <div className="flex items-start justify-start gap-10">
                <div>
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <step.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div>
                  <dt className="text-lg font-bold text-black">{step.title}</dt>
                  <dt>{step.description}</dt>
                </div>
              </div>
            ))}
          </dl>
          <div className="lg:ml-12">
            <iframe
              className="shadow-xl rounded-xl w-[336px] h-[189px] md:w-[672px] md:h-[378px] lg:w-[560px] lg:h-[315px]"
              src="https://www.youtube.com/embed/rR6AIX5LHlM?controls=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            ></iframe>
            <p className="font-semibold mt-5 text-right">
              Product Demo Video (2:30min)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
