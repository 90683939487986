import React from "react";
import useToggleState from "../../hooks/useToggleState";
import SubscriptionPlansModal from "../SubscriptionPlans/SubscriptionPlansModal";

export default function SharedLeadsUpsell() {
  const [showModal, toggleShowModal] = useToggleState();

  return (
    <div className="content-height flex justify-center items-center">
      <SubscriptionPlansModal open={showModal} toggleOpen={toggleShowModal} />
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
          />
        </svg>

        <h3 className="mt-4 text-base font-semibold text-gray-900">
          Lead Sharing is not available on your plan
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Upgrade to the Basic plan to share leads with your teammates.
        </p>
        <button
          type="button"
          onClick={toggleShowModal}
          className="mt-5 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          View Plans
        </button>
      </div>
    </div>
  );
}
