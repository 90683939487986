import { Fragment, useState, useEffect } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { classNames, formatDate, getInitials } from "../../helpers";
import useAxios from "../../hooks/useAxios";
import EvaluationBadge from "../Leads/EvaluationBadge";
import { useNavigate } from "react-router-dom";

export default function SearchBox({ open, setOpen }) {
  const api = useAxios();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const nagivate = useNavigate();

  useEffect(() => {
    const storedSearches = localStorage.getItem("recentSearches");
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
  }, []);

  useEffect(() => {
    const handleSearch = async () => {
      if (query !== "") {
        api
          .get(`search/?query=${query}`)
          .then((res) => {
            setResults(Array.isArray(res.data) ? res.data : []);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const timeoutId = setTimeout(handleSearch, 500);

    // Cleanup function to clear the timeout if the component is unmounted
    return () => clearTimeout(timeoutId);
  }, [query]);

  const redirectUser = (activeItem, confirm) => {
    if (confirm) {
      updateRecentSearch(activeItem);
      setOpen(false);
      nagivate(`/app/leads/${activeItem.id}`);
    }
  };

  const updateRecentSearch = (item) => {
    if (!recentSearches.includes(item)) {
      localStorage.setItem(
        "recentSearches",
        JSON.stringify([...recentSearches, item])
      );
    }
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(person) => (window.location = person.profileUrl)}
              >
                {({ activeOption }) => (
                  <>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={handleInputChange}
                      />
                    </div>

                    {(query === "" || results.length > 0) && (
                      <Combobox.Options
                        as="div"
                        static
                        hold
                        className="flex divide-x divide-gray-100"
                      >
                        <div
                          className={classNames(
                            "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                            activeOption && "sm:h-96"
                          )}
                        >
                          {query === "" && recentSearches.length > 0 && (
                            <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">
                              Recent searches
                            </h2>
                          )}
                          <div className="-mx-2 text-sm text-gray-700">
                            {(query === "" ? recentSearches : results).map(
                              (person) => (
                                <Combobox.Option
                                  as="div"
                                  key={person.id}
                                  value={person}
                                  className={({ active }) =>
                                    classNames(
                                      "flex cursor-default select-none items-center rounded-md p-2",
                                      active && "bg-gray-100 text-gray-900"
                                    )
                                  }
                                >
                                  {({ active }) => (
                                    <>
                                      {person.picture ? (
                                        <img
                                          src={person.imageUrl}
                                          alt=""
                                          className="mx-auto h-6 w-6 rounded-full"
                                        />
                                      ) : (
                                        <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-400">
                                          <span className="text-xs font-medium leading-none text-white">
                                            {getInitials(person.name)}
                                          </span>
                                        </span>
                                      )}
                                      <span className="ml-3 flex-auto truncate">
                                        {person.name}
                                      </span>
                                      {active && (
                                        <ChevronRightIcon
                                          className="ml-3 h-5 w-5 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              )
                            )}
                          </div>
                        </div>

                        {activeOption && (
                          <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                            <div className="flex-none p-6 text-center">
                              {activeOption.picture ? (
                                <img
                                  src={activeOption.picture}
                                  alt=""
                                  className="mx-auto h-12 w-12 rounded-full"
                                />
                              ) : (
                                <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-400">
                                  <span className="text-lg font-medium leading-none text-white">
                                    {getInitials(activeOption.name)}
                                  </span>
                                </span>
                              )}
                              <h2 className="mt-3 font-semibold text-gray-900">
                                {activeOption.name}
                              </h2>
                              <p className="text-sm leading-6 text-gray-500">
                                {activeOption.headline}
                              </p>
                              <div className="mt-2">
                                {" "}
                                <EvaluationBadge
                                  score={
                                    activeOption.evaluation?.evaluation_score
                                  }
                                  small
                                />
                              </div>
                            </div>
                            <div className="flex flex-auto flex-col justify-between p-6">
                              <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                <dt className="col-end-1 font-semibold text-gray-900">
                                  Country
                                </dt>
                                <dd>{activeOption.person_country}</dd>
                                <dt className="col-end-1 font-semibold text-gray-900">
                                  Import Date
                                </dt>
                                <dd className="truncate">
                                  {formatDate(activeOption.created)}
                                </dd>
                              </dl>
                              <button
                                onClick={() => redirectUser(activeOption, true)}
                                type="button"
                                className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                View details
                              </button>
                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {query !== "" && results.length === 0 && (
                      <div className="px-6 py-14 text-center text-sm sm:px-14">
                        <UsersIcon
                          className="mx-auto h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-gray-900">
                          No people found
                        </p>
                        <p className="mt-2 text-gray-500">
                          We couldn’t find anything with that term. Please try
                          again.
                        </p>
                      </div>
                    )}

                    {query === "" && recentSearches.length === 0 && (
                      <div className="px-6 py-14 text-center text-sm sm:px-14">
                        <UsersIcon
                          className="mx-auto h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-gray-900">
                          Search your leads by name
                        </p>
                        <p className="mt-2 text-gray-500">
                          Start typing to quickly find a lead
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
