import React from "react";
import { classNames } from "../../helpers";
import useToggleState from "../../hooks/useToggleState";

export default function InputWithLabel({
  label,
  placeholder,
  type = "text",
  small,
  step,
  disabled = false,
  value = undefined,
  onChange = undefined,
  required = false,
  autoComplete = true,
  onKeyDown = undefined,
  helperText = null,
  error = false,
  errorText = null,
  autoFocus = false,
  min,
  max,
}) {
  const [showPassword, toggleShowPassword] = useToggleState(false);
  return (
    <div>
      {type === "password" ? (
        <div className="flex items-center justify-between">
          <label
            className={classNames(
              small ? "text-sm" : "text-base",
              "block leading-6 text-gray-900 font-medium"
            )}
          >
            {label}
          </label>
          {showPassword ? (
            <span
              onClick={toggleShowPassword}
              className="text-sm text-gray-400 hover:text-gray-500 cursor-pointer"
            >
              Hide password
            </span>
          ) : (
            <span
              onClick={toggleShowPassword}
              className="text-sm text-gray-400 hover:text-gray-500 cursor-pointer"
            >
              Show password
            </span>
          )}
        </div>
      ) : (
        <label
          className={classNames(
            small ? "text-sm" : "text-base",
            "block leading-6 text-gray-900 font-medium"
          )}
        >
          {label}
        </label>
      )}

      <div className="mt-2">
        <input
          value={value}
          onChange={onChange}
          name={label}
          type={type === "password" && showPassword ? "text" : type}
          autoComplete={autoComplete ? "true" : undefined}
          required={required}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          disabled={disabled}
          autoFocus={autoFocus}
          step={type === "number" && step}
          min={type === "number" && min}
          max={type === "number" && max}
          className="block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:text-gray-500"
        />
      </div>
      {error && errorText ? (
        <p className="mt-2 text-sm text-red-500">{errorText}</p>
      ) : (
        helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>
      )}
    </div>
  );
}
