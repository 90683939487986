import React, { useState } from "react";
import { formatDate } from "../../../../helpers";

const ListItem = ({ heading, content }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="px-4 py-3">
      <p className="text-sm font-semibold">{heading}</p>
      <p className="text-sm">
        {content.length < 200 ? (
          content
        ) : expanded ? (
          <div>
            {content}{" "}
            <p
              className="text-blue-500 font-semibold cursor-pointer"
              onClick={() => setExpanded(false)}
            >
              Show less
            </p>
          </div>
        ) : (
          <div>
            {content.slice(0, 200)}{" "}
            <p
              className="text-blue-500 font-semibold cursor-pointer"
              onClick={() => setExpanded(true)}
            >
              Show more
            </p>
          </div>
        )}
      </p>
    </div>
  );
};

export default function LeadDetail({ lead }) {
  return (
    <div className="bg-white divide-gray-200 divide-y content-height">
      <div className="bg-gray-100 p-4">
        <p className="text-sm font-semibold">Basic Info</p>
      </div>
      {lead.headline && <ListItem heading="Headline" content={lead.headline} />}
      {lead.person_occupation && (
        <ListItem heading="Occupation" content={lead.person_occupation} />
      )}
      {lead.person_country && (
        <ListItem heading="Country" content={lead.person_country} />
      )}
      {lead.person_city && (
        <ListItem heading="City" content={lead.person_city} />
      )}
      {lead.languages && (
        <ListItem heading="Languages" content={lead.languages} />
      )}
      {lead.person_summary && (
        <ListItem heading="Profile Summary" content={lead.person_summary} />
      )}
      {lead.custom_message && (
        <ListItem heading="Message" content={lead.custom_message} />
      )}
      {lead.experiences.length > 0 && (
        <div className="divide-y divide-gray-200">
          <div className="bg-gray-100 p-4">
            <p className="text-sm font-semibold">Work Experience</p>
          </div>
          {lead.experiences.map((experience) => (
            <div className="px-4 py-3 text-sm">
              <p className="font-semibold">{experience.company_name}</p>
              <p>{experience.job_title}</p>
              <p className="text-gray-500">
                {formatDate(experience.start_date)} -{" "}
                {formatDate(experience.end_date)}
              </p>
            </div>
          ))}
        </div>
      )}
      {lead.education.length > 0 && (
        <div className="divide-y divide-gray-200">
          <div className="bg-gray-100 p-4">
            <p className="text-sm font-semibold">Education</p>
          </div>
          {lead.education.map((education) => (
            <div className="px-4 py-3 text-sm">
              <p className="font-semibold">{education.school_name}</p>
              <p>{education.study_field}</p>
              <p className="text-gray-500">{education.degree_name}</p>
              <p className="text-gray-500">
                {formatDate(education.start_date)} -{" "}
                {formatDate(education.end_date)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
