import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import useInputState from "../../hooks/useInputState";
import { validateEmail } from "../../helpers";
import { AuthContext } from "../../context/AuthContext";
import InputWithLabel from "../../components/Forms/InputWithLabel";
import Logo from "../../img/logo.png";

export default function ForgotPassword() {
  const { user, requestPasswordReset } = useContext(AuthContext);
  const [email, setEmail] = useInputState("");
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);

  const checkForEnterSubmit = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      requestPasswordReset(email).then((res) => {
        setSent(true);
      });
    } else {
      setError("Please enter a valid email address.");
    }
  };

  if (user) {
    return <Navigate to="/app" />;
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex gap-3 items-center w-full justify-center mb-10">
            <img className="h-12 w-auto" src={Logo} alt="InProfiler" />
            <span className="text-3xl font-bold text-indigo-600">
              InProfiler
            </span>
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
            {sent ? "Password reset requested" : "Reset Password"}
          </h2>
          <p className="text-gray-500 text-base text-center mt-3">
            {sent
              ? "Please check your inbox and click the link we sent you"
              : "Enter your email address and we will send you a link to reset your password if your mail exists in our system."}
          </p>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-[520px]">
          {!sent && (
            <div className="bg-white px-6 py-12 sm:rounded-lg sm:px-12">
              <div className="space-y-6">
                <InputWithLabel
                  label="Email address"
                  type="email"
                  required={true}
                  placeholder="Email address"
                  value={email}
                  onChange={setEmail}
                  onKeyDown={checkForEnterSubmit}
                />

                <div className="space-y-4">
                  {error && (
                    <span className="text-sm text-red-500">{error}</span>
                  )}
                  <button
                    onClick={handleSubmit}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Request Link
                  </button>
                </div>
              </div>
            </div>
          )}

          <p className="mt-5 text-center text-sm text-gray-500">
            <Link
              to="/login"
              className="font-semibold leading-6 text-gray-400 hover:text-gray-500 cursor-pointer"
            >
              Back to Login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
