import React, { useEffect, useState } from "react";
import { useGetAdminLeads } from "../../../../api/AdminAPI";
import { getInitials } from "../../../../helpers";
import { MoonLoader } from "react-spinners";

export default function LeadList({ setSelectedLead }) {
  const getAdminLeads = useGetAdminLeads();
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getAdminLeads(page)
      .then((data) => {
        setLeads([...leads, ...data.results]);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  // handle scroll event
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      loadData();
    }
  };

  if (leads && leads.length > 0) {
    return (
      <div onScroll={handleScroll} className="h-full overflow-auto">
        {leads.map((lead) => (
          <div
            className="p-4 bg-white flex items-center gap-3 border-b cursor-pointer hover:bg-indigo-50"
            onClick={() => setSelectedLead(lead)}
          >
            {lead.picture ? (
              <img
                src={lead.picture}
                className="h-8 w-8 rounded-full shrink-0"
              />
            ) : (
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 shrink-0">
                <span className="text-xs font-medium leading-none text-white">
                  {getInitials(lead.name)}
                </span>
              </span>
            )}
            <div>
              <p className="text-sm">{lead.name}</p>
              <p className="text-xs text-gray-500">{lead.person_occupation}</p>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="h-full flex items-center justify-center">
        <MoonLoader size={30} />
      </div>
    );
  }
}
