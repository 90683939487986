import React from "react";

export default function EmptyStateSingleRequest() {
  return (
    <>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        No request selected
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Select a request to view it's details
      </p>
    </>
  );
}
