import React, { useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import LeadsList from "../../components/Leads/LeadsList/LeadsList";
import LeadDetail from "../../components/Leads/LeadDetail/LeadDetail";
import EmptyStateSingleLead from "../../components/Leads/EmptyStateSingleLead";
import { Link, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { updateItemInState } from "../../helpers";
import {
  useGetLeadDetail,
  useGetUserLeads,
  useUpdateSeenStatus,
  useDeleteLead,
  useGetLeadEvaluation,
  useRateEvaluation,
  useGetSharedLeads,
} from "../../api/LeadsAPI";
import EmptyStateOwnedLeads from "../../components/Leads/EmptyStateOwnedLeads";
import EmptyStateSharedLeads from "../../components/Leads/EmptyStateSharedLeads";
import SharedLeadsUpsell from "../../components/Leads/SharedLeadsUpsell";

export default function Leads({ leadType }) {
  const { subscriptionPlan } = useContext(SubscriptionContext);
  const [leads, setLeads] = useState();
  const [selectedLead, setSelectedLead] = useState();
  const [leadDetail, setLeadDetail] = useState();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const getLeadDetail = useGetLeadDetail();
  const getUserLeads = useGetUserLeads();
  const updateSeenStatus = useUpdateSeenStatus();
  const deleteLead = useDeleteLead();
  const getLeadEvaluation = useGetLeadEvaluation();
  const rateEvaluation = useRateEvaluation();
  const getSharedLeads = useGetSharedLeads();

  // Get lead detail
  useEffect(() => {
    const { id } = params;
    if (id) {
      getLeadDetail(id)
        .then((data) => {
          console.log(data);
          setLeadDetail(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params]);

  useEffect(() => {
    setLoading(true);
    let callerFunction;
    if (leadType === "owner") {
      callerFunction = getUserLeads;
    } else if (leadType === "shared") {
      callerFunction = getSharedLeads;
    }
    callerFunction()
      .then((data) => {
        setLeads(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [leadType]);

  useEffect(() => {
    if (leadDetail && !leadDetail.seen) {
      updateSeenStatus(leadDetail.id)
        .then((data) => {
          setLeads((prevState) => updateItemInState(prevState, data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [leadDetail]);

  const handleDelete = (id) => {
    deleteLead(id)
      .then((data) => {
        setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== id));
        setLeadDetail(null);
        setSelectedLead(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetLeadEvaluation = () => {
    getLeadEvaluation(leadDetail.id)
      .then((data) => {
        setLeadDetail(data);
        setLeads((prevLeads) => updateItemInState(prevLeads, data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unsetLeadDetail = () => {
    setLeadDetail(null);
  };

  const handleRateEvaluation = (rating) => {
    if (rating !== "GOOD" && rating !== "BAD") {
      return;
    }

    rateEvaluation(leadDetail.evaluation.id, rating)
      .then((data) => {
        setLeadDetail(data);
        setLeads((prevLeads) => updateItemInState(prevLeads, data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (leadType === "shared" && !subscriptionPlan.has_lead_sharing) {
    return <SharedLeadsUpsell />;
  }

  if (loading) {
    return (
      <div className="content-height flex justify-center items-center flex-col gap-4">
        <MoonLoader />
        <p className="text-xl text-gray-500 font-medium">Loading</p>
      </div>
    );
  }

  if (leads && leads.length === 0 && !loading) {
    if (leadType === "owner") {
      return <EmptyStateOwnedLeads />;
    } else if (leadType === "shared") {
      return <EmptyStateSharedLeads />;
    }
  }
  return (
    <div className="flex">
      <div className="w-full lg:w-1/2 bg-white overflow-scroll relative border-r">
        <LeadsList
          leads={leads}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          leadType={leadType}
        />
      </div>
      <div className="w-1/2 overflow-scroll content-height hidden lg:block">
        {leadDetail ? (
          <LeadDetail
            lead={leadDetail}
            deleteLead={handleDelete}
            rateEvaluation={handleRateEvaluation}
            getLeadEvaluation={handleGetLeadEvaluation}
            leadType={leadType}
          />
        ) : (
          <div className="flex justify-center items-center h-full bg-gray-50 flex-col">
            <EmptyStateSingleLead />
          </div>
        )}
      </div>
      {leadDetail && (
        <div className="w-full z-20 absolute lg:hidden content-height">
          <LeadDetail
            lead={leadDetail}
            deleteLead={handleDelete}
            hasBack={true}
            back={unsetLeadDetail}
            rateEvaluation={handleRateEvaluation}
            getLeadEvaluation={handleGetLeadEvaluation}
            leadType={leadType}
          />
        </div>
      )}
    </div>
  );
}
