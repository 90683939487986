import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What is the main purpose of InProfiler?",
    answer:
      "InProfiler is designed to help professionals manage their LinkedIn connection requests more effectively by evaluating the potential value of each lead, based on the user's predefined objectives.",
  },
  {
    question: "How does InProfiler determine the potential value of a lead?",
    answer:
      "InProfiler uses a sophisticated algorithm to analyze the information in the LinkedIn profile of the connection request sender. It then compares this information with the user's predefined objectives to categorize the lead from high to low potential.",
  },
  {
    question:
      "What kind of information does InProfiler use from LinkedIn to evaluate leads?",
    answer:
      "InProfiler evaluates leads based on information available in the LinkedIn profile of the connection request sender, such as their job title, company, industry, previous experiences and more.",
  },
  {
    question: "Do I need to install the Chrome extension to use InProfiler?",
    answer:
      "Yes, the InProfiler Chrome extension is necessary to import your LinkedIn connection requests into the application.",
  },
  {
    question: "Will InProfiler work with other browsers besides Google Chrome?",
    answer:
      "As of now, InProfiler works with Google Chrome and Chrome-based browsers. We're continually working to expand our support for other browsers in the future.",
  },
  {
    question: "Can I adjust my lead evaluation criteria once they've been set?",
    answer:
      "Absolutely! You can update your objectives at any time to ensure that InProfiler is always aligned with your current goals.",
  },
  {
    question: "How secure is my LinkedIn data when I use InProfiler?",
    answer:
      "InProfiler doesn't access any of your LinkedIn data. We only read publicly accessible profile data from the profiles that are looking to connect with you.",
  },
  // {
  //   question:
  //     "What does the 'beta phase' mean, and will I be charged for using the app during this phase?",
  //   answer:
  //     "The beta phase is a testing period during which we gather feedback and make necessary improvements. During this phase, users will have free access to the full functionality of InProfiler.",
  // },
  // More questions...
];

export default function Faq() {
  return (
    <div className="bg-white" id="faq">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
          <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
