import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import useInputState from "../../hooks/useInputState";
import { AuthContext } from "../../context/AuthContext";
import InputWithLabel from "../../components/Forms/InputWithLabel";
import Logo from "../../img/logo.png";

export default function ResetPassword() {
  const { resetPassword, user, loginUser } = useContext(AuthContext);
  const [password1, setPassword1] = useInputState("");
  const [password2, setPassword2] = useInputState("");
  const [error, setError] = useState(null);
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const params = useParams();

  useEffect(() => {
    const { userId, token } = params;

    if (userId) {
      setUserId(userId);
    }
    if (token) {
      setToken(token);
    }
  }, [params]);

  const handleSubmit = () => {
    if (!password1 || !password2) {
      setError("Please fill out both fields");
      return;
    }
    if (password1 !== password2) {
      setError("Passwords don't match");
      return;
    }
    resetPassword(password1, password2, userId, token)
      .then((res) => {
        loginUser({ email: res.email, password: password1 });
      })
      .catch((err) => console.log(err));
  };

  const checkForEnterSubmit = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    }
  };

  if (user) {
    return <Navigate to="/app" />;
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex gap-3 items-center w-full justify-center mb-10">
            <img className="h-12 w-auto" src={Logo} alt="InProfiler" />
            <span className="text-3xl font-bold text-indigo-600">
              InProfiler
            </span>
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
            Set your new password
          </h2>
          <p className="text-center text-gray-500 text-base mt-3">
            Make sure you don't forget it again
          </p>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-[520px]">
          <div className="bg-white px-6 py-12 sm:rounded-lg sm:px-12">
            <div className="space-y-6">
              <InputWithLabel
                label="Password"
                type="password"
                required={true}
                placeholder="Password"
                value={password1}
                onChange={setPassword1}
                onKeyDown={checkForEnterSubmit}
                small
              />
              <InputWithLabel
                label="Confirm Password"
                type="password"
                required={true}
                placeholder="Confirm Password"
                value={password2}
                onChange={setPassword2}
                onKeyDown={checkForEnterSubmit}
                small
              />

              <div className="space-y-4">
                {error && <span className="text-sm text-red-500">{error}</span>}
                <button
                  onClick={handleSubmit}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
