import React from "react";
import { classNames } from "../../../../../helpers";
import { RadioGroup } from "@headlessui/react";
import { affinityIntegrationTypes } from "../constants/integrationTypes";

export default function SelectIntegrationType({
  setIntegrationType,
  integrationType,
}) {
  return (
    <RadioGroup value={integrationType} onChange={setIntegrationType}>
      <RadioGroup.Label className="sr-only">
        Integration Type Setting
      </RadioGroup.Label>
      <div className="-space-y-px rounded-md bg-white">
        {affinityIntegrationTypes.map((type, typeId) => (
          <RadioGroup.Option
            key={type.name}
            value={type.key}
            className={({ checked }) =>
              classNames(
                typeId === 0 ? "rounded-tl-md rounded-tr-md" : "",
                typeId === affinityIntegrationTypes.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "z-10 border-indigo-200 bg-indigo-50"
                  : "border-gray-200",
                "relative flex cursor-pointer border p-4 focus:outline-none"
              )
            }
          >
            {({ checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {type.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? "text-indigo-700" : "text-gray-500",
                      "block text-sm"
                    )}
                  >
                    {type.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
