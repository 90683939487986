import React, { useContext } from "react";
import FooterActions from "./FooterActions";
import LeadEvaluation from "./LeadEvaluation";
import LeadData from "./LeadData";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

export default function LeadDetail({
  lead,
  deleteLead,
  rateEvaluation,
  hasBack,
  back,
  getLeadEvaluation,
  leadType,
}) {
  return (
    <div className="bg-white content-height-max content-height flex flex-col justify-between">
      {hasBack && (
        <div
          className="py-5 px-8 flex gap-2 font-semibold items-center cursor-pointer"
          onClick={back}
        >
          <ArrowLeftIcon className="h-6 w-6" /> Back
        </div>
      )}
      <div className="overflow-y-scroll">
        <LeadData lead={lead} leadType={leadType} />
        <LeadEvaluation
          evaluation={lead.evaluation}
          leadCreation={lead.created}
          getLeadEvaluation={getLeadEvaluation}
          leadType={leadType}
        />
      </div>
      <FooterActions
        leadId={lead.id}
        evaluationRating={lead.evaluation?.evaluation_user_rating}
        rateEvaluation={rateEvaluation}
        deleteLead={deleteLead}
        hasEvaluation={lead.evaluation ? true : false}
        leadType={leadType}
      />
    </div>
  );
}
