import React from "react";
import {
  UsersIcon,
  CursorArrowRaysIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/20/solid";
import Screenshot from "../../img/ext-demo.png";
import VideoModal from "../../components/UtilityComponents/VideoModal";
import useToggleState from "../../hooks/useToggleState";

const features = [
  {
    name: "Add extension.",
    description:
      "Click the button below to go to the Chrome extension page and add it to your browser from there. You might have to restart Chrome.",
    icon: PuzzlePieceIcon,
  },
  {
    name: "Pin the extension to your toolbar.",
    description:
      "In order to have easy access to your extension, click the puzzle icon in your toolbar and then the pin icon in order have it fixed to your toolbar.",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Export your LinkedIn leads.",
    description:
      "Once your extension is installed, simply click on the InProfiler icon and follow the instructions in order to get a neat overview of your inbound connection requests.",
    icon: UsersIcon,
  },
];
export default function GetExtension() {
  const [showModal, toggleShowModal] = useToggleState(false);
  return (
    <div className="overflow-hidden bg-white lg:h-full w-full flex items-center justify-center">
      <VideoModal
        open={showModal}
        toggleOpen={toggleShowModal}
        videoTitle="InProfiler Demo"
        videoUrl="https://www.youtube.com/embed/rR6AIX5LHlM?controls=1"
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-8 lg:py-0">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-0">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Start Profiling
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Get our Chrome Extension
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                In order to start pulling your LinkedIn connection requests into
                InProfiler, you need to add our Chrome extension to your Google
                Chrome browser.
              </p>
              <dl className="mt-5 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <div className="space-x-5 flex justify-start mt-5 items-center">
                <button
                  onClick={toggleShowModal}
                  className="w-1/2 lg:w-1/3 rounded-md bg-white px-3 py-3 text-sm font-semibold border border-indigo-300 text-indigo-600 shadow hover:bg-gray-50 text-center"
                >
                  Watch Tutorial
                </button>
                <a
                  href="https://chrome.google.com/webstore/detail/pgbjmhcngpiikpendffkhldgekobeohi"
                  target="_blank"
                  className="w-1/2 lg:w-1/3 rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center"
                >
                  Get extension
                </a>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={Screenshot}
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-2xl ring-1 ring-gray-400/10 sm:w-[57rem] border-1"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
