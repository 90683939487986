import React, { useState, useEffect } from "react";
import StyledSelect from "../../../../UtilityComponents/StyledSelect";
import Tooltip from "../../../../UtilityComponents/Tooltip";
import {
  useGetAffinityLists,
  useCreateAffinityList,
} from "../../../../../api/AffinityAPI";
import InputWithLabel from "../../../../Forms/InputWithLabel";
import useToggleState from "../../../../../hooks/useToggleState";
import {
  CheckIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import useInputState from "../../../../../hooks/useInputState";

export default function SelectLists({
  selectedOrgList,
  setSelectedOrgList,
  selectedPersonsList,
  setSelectedPersonsList,
  preselected,
  setError,
  importPersons,
  toggleImportPersons,
  overwriteImportPersons,
  importOrgs,
  toggleImportOrgs,
  overwriteImportOrgs,
}) {
  // hooks
  const getAffinityLists = useGetAffinityLists();
  const createAffinityList = useCreateAffinityList();
  // person list
  const [personsLists, setPersonsLists] = useState([]);
  const [newPersonList, toggleNewPersonList] = useToggleState(false);
  const [newPersonListName, setNewPersonListName, overwriteNewPersonListName] =
    useInputState("");
  // Org list
  const [orgLists, setOrgLists] = useState([]);
  const [newOrgList, toggleNewOrgList] = useToggleState(false);
  const [newOrgListName, setNewOrgListName, overwriteNewOrgListName] =
    useInputState("");
  const [dataReceived, setDataReceived] = useState(false);

  const fetchLists = () => {
    getAffinityLists()
      .then((data) => {
        setPersonsLists(data.persons_lists);
        setOrgLists(data.org_lists);

        // When loading existing integration, replace ID in state with the listitem containing more info
        if (preselected) {
          const pList = data.persons_lists.find(
            (item) => item.id === selectedPersonsList
          );
          const oList = data.org_lists.find(
            (item) => item.id === selectedOrgList
          );
          setSelectedPersonsList(pList);
          setSelectedOrgList(oList);
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  // Get lists from affinity
  useEffect(() => {
    if (!personsLists.length && !orgLists.length && !dataReceived) {
      fetchLists();
      setDataReceived(true);
    }
  }, []);

  // Untoggle assignment option if user had previously deactivated it
  useEffect(() => {
    if (preselected) {
      if (!selectedOrgList) {
        overwriteImportOrgs(false);
      }
      if (!selectedPersonsList) {
        overwriteImportPersons(false);
      }
    }
  }, []);

  // Set orglist to null if deactivated
  useEffect(() => {
    if (!importOrgs) {
      setSelectedOrgList(null);
    }
  }, [importOrgs]);

  // Set person list to null if deactivated
  useEffect(() => {
    if (!importPersons) {
      setSelectedPersonsList(null);
    }
  }, [importPersons]);

  useEffect(() => {
    if (!importPersons && !importOrgs) {
      setError("You need to import either persons or organizations");
    } else {
      setError(null);
    }
  }, [importPersons, importOrgs]);

  // Create new list for persons in affinity account
  const createNewPersonList = () => {
    createAffinityList("person", newPersonListName).then((data) => {
      fetchLists();
      setSelectedPersonsList(data.list);
      toggleNewPersonList();
      overwriteNewPersonListName("");
    });
  };

  // Create new list for organisations in affinity account
  const createNewOrgList = () => {
    createAffinityList("org", newOrgListName).then((data) => {
      fetchLists();
      setSelectedOrgList(data.list);
      toggleNewOrgList();
      overwriteNewOrgListName("");
    });
  };

  return (
    <div className="space-y-5">
      {/* Persons start */}
      <div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={importPersons}
            />
          </div>
          <div className="ml-3 text-sm leading-6" onClick={toggleImportPersons}>
            <label className="font-medium text-gray-900 flex gap-2 items-center">
              Import Persons
            </label>
          </div>
        </div>
        {importPersons && (
          <div className="flex gap-2 items-end">
            <div className="w-1/2">
              <StyledSelect
                selected={selectedPersonsList}
                setSelected={setSelectedPersonsList}
                choices={personsLists}
                placeholder="Not assigned to any Affinity list"
              />
            </div>
            {!newPersonList ? (
              <button
                onClick={toggleNewPersonList}
                className="inline-flex justify-center rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-500 hover:bg-indigo-200 disabled:bg-gray-50 disabled:text-gray-300 disabled:border-none"
              >
                Create new list
              </button>
            ) : (
              <div className="flex gap-2 items-end">
                <InputWithLabel
                  placeholder="Enter List Name"
                  value={newPersonListName}
                  onChange={setNewPersonListName}
                />
                <button
                  onClick={createNewPersonList}
                  type="button"
                  className="rounded bg-transparent p-1.5 text-gray-500 hover:bg-gray-50"
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  onClick={toggleNewPersonList}
                  className="rounded bg-transparent p-1.5 text-gray-500 hover:bg-gray-50"
                >
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Organisation start */}
      <div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={importOrgs}
            />
          </div>
          <div className="ml-3 text-sm leading-6" onClick={toggleImportOrgs}>
            <label className="font-medium text-gray-900">
              Import Organizations
            </label>
          </div>
        </div>
        {importOrgs && (
          <div className="flex gap-2 items-end">
            <div className="w-1/2">
              <StyledSelect
                selected={selectedOrgList}
                setSelected={setSelectedOrgList}
                choices={orgLists}
                placeholder="Not assigned to any Affinity list"
              />
            </div>
            {!newOrgList ? (
              <button
                onClick={toggleNewOrgList}
                className="inline-flex justify-center rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-500 hover:bg-indigo-200 disabled:bg-gray-50 disabled:text-gray-300 disabled:border-none"
              >
                Create new list
              </button>
            ) : (
              <div className="flex gap-2 items-end">
                <InputWithLabel
                  placeholder="Enter List Name"
                  value={newOrgListName}
                  onChange={setNewOrgListName}
                />
                <button
                  onClick={createNewOrgList}
                  type="button"
                  className="rounded bg-transparent p-1.5 text-gray-500 hover:bg-gray-50"
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  onClick={toggleNewOrgList}
                  className="rounded bg-transparent p-1.5 text-gray-500 hover:bg-gray-50"
                >
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
