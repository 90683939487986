import React from "react";
import { AuthProvider } from "../context/AuthContext";
import { Route, Routes } from "react-router-dom";
import AdminOutlet from "../utils/AdminOutlet";
import AdminLayout from "../components/Layout/Admin/AdminLayout";
import AdminDashboard from "../views/Admin/AdminDashboard";
import Prompts from "../views/Admin/Prompts";

export default function AdminRoutes() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AdminOutlet />} path="" exact>
          <Route element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="prompts" element={<Prompts />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}
