import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import TextAreaWithLabel from "../Forms/TextAreaWithLabel";
import useInputState from "../../hooks/useInputState";
import useAxios from "../../hooks/useAxios";
import { Link } from "react-router-dom";

export default function FeedbackModal({ open, toggleOpen }) {
  const [feedback, setFeedback] = useInputState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const api = useAxios();

  const sendFeedbback = () => {
    setError(null);
    setLoading(true);

    if (feedback.length < 200) {
      setError("Please reach at least 200 characters in your feedback");
      setLoading(false);
      return;
    }

    api
      .post("send-beta-feedback/", { message: feedback })
      .then((res) => {
        setSent(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={toggleOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {!sent ? (
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="mt-3 text-left sm:mt-3">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900"
                      >
                        How was your experience with InProfiler?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          As our product is stil young, we value your feedback a
                          lot. As a token of our appreciation, we will credit
                          your account with 50 additional free lead imports.
                        </p>
                      </div>
                      <div className="mt-5">
                        <TextAreaWithLabel
                          rows={6}
                          placeholder="Tell us about your opinion on InProfiler ..."
                          value={feedback}
                          onChange={setFeedback}
                          disabled={loading}
                        />
                        {!feedback ? (
                          <div></div>
                        ) : feedback.length < 200 ? (
                          <p className="text-gray-500 text-sm mt-1">
                            Reach at least 200 characters
                          </p>
                        ) : (
                          <p className="text-gray-500 text-sm mt-1">
                            Keep going if you have more to share!
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {error && (
                    <p className="p-2 bg-red-50 rounded-lg my-2 text-red-700 text-sm">
                      {error}
                    </p>
                  )}
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-indigo-400"
                      onClick={sendFeedbback}
                      disabled={loading}
                    >
                      {loading ? "Loading" : "Send Feedback"}
                    </button>
                  </div>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="mt-3 text-left sm:mt-3">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900"
                      >
                        Thank you for your feedback!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Your account has been credited with 50 additional lead
                          imports
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 border border-indigo-200 hover:bg-gray-50"
                      onClick={toggleOpen}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
