import React, { useState, useEffect, useContext } from "react";
import { SubscriptionContext } from "../../../../context/SubscriptionContext";
import useToggleState from "../../../../hooks/useToggleState";
import {
  useGetAffinityIntegration,
  useUpdateAffinityIntegration,
  useDeleteAffinityIntegration,
  useToggleAffinityIntegration,
} from "../../../../api/AffinityAPI";
import {
  inprofilerOrganisationFields,
  inprofilerPersonFields,
} from "./constants/initialMapping";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteModal from "../../../UtilityComponents/ConfirmDeleteModal";
import IntegrationTypeSetting from "./SettingsSections/IntegrationTypeSetting";
import AffinityListsSetting from "./SettingsSections/AffinityListsSetting";
import PersonalFieldsMapping from "./SettingsSections/PersonalFieldsMapping";
import CompanyFieldsMapping from "./SettingsSections/CompanyFieldsMapping";
import Header from "./SettingsSections/Header";
import FooterBar from "./SettingsSections/FooterBar";
import AutoSyncSetting from "./SettingsSections/AutoSyncSetting";
import ConnectAccount from "./SettingsSections/ConnectAccount";
import IntegrationDoesNotExist from "./SettingsSections/IntegrationDoesNotExist";
import DefaultFieldsSetting from "./SettingsSections/DefaultFieldsSetting";

export default function AffinitySettings() {
  // hooks
  const { accountUser, getAccountSettings } = useContext(SubscriptionContext);
  const navigate = useNavigate();
  const getAffinityIntegration = useGetAffinityIntegration();
  const updateAffinityIntegration = useUpdateAffinityIntegration();
  const toggleAffinityIntegration = useToggleAffinityIntegration();
  const deleteAffinityIntegration = useDeleteAffinityIntegration();
  // status
  const [loading, setLoading] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [error, setError] = useState();
  const [saved, setSaved] = useState(false);
  const [initiated, setInitiated] = useState(false);
  // Integration
  const [integration, setIntegration] = useState();
  const [integrationDoesNotExist, setIntegrationDoesNotExist] = useState(false);
  // Settings fields
  const [integrationType, setIntegrationType] = useState();
  const [enableAutoSync, toggleEnableAutoSync, overwriteEnableAutoSync] =
    useToggleState();
  const [minimumLeadScore, setMinimumLeadScore] = useState();
  // Entities
  const [importPersons, toggleImportPersons, overwriteImportPersons] =
    useToggleState(true);
  const [importOrgs, toggleImportOrgs, overwriteImportOrgs] =
    useToggleState(true);
  // Lists
  const [selectedOrgList, setSelectedOrgList] = useState();
  const [selectedPersonsList, setSelectedPersonsList] = useState();
  // Person mapping
  const [personFieldMapping, setPersonFieldMapping] = useState([]);
  const [additionalPersonFieldOptions, setAdditionalPersonFieldOptions] =
    useState(inprofilerPersonFields);
  // Org Mappings
  const [orgFieldMapping, setOrgFieldMapping] = useState([]);
  const [additionalOrgFieldOptions, setAdditionalOrgFieldOptions] = useState(
    inprofilerOrganisationFields
  );
  // Defaults
  const [defaultFields, setDefaultFields] = useState([]);
  // Deacitvate delete
  const [showDeactivationModal, toggleShowDeactivationModal] =
    useToggleState(false);
  const [showDeleteModal, toggleShowDeleteModal] = useToggleState(false);

  const getIntegration = () => {
    setLoading(true);
    getAffinityIntegration()
      .then((data) => {
        setIntegration(data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setIntegrationDoesNotExist(true);
        } else {
          setError(err.response.data.message);
        }
        setLoading(false);
      });
  };

  function initStateWithFieldMappings(fields, savedMappings, type) {
    /*
      Initiates the state of the mappings using the saved mapping from the db
    */
    let result = savedMappings[type].map((mapping) => {
      // Find the corresponding field in the default set
      const field = fields.find(
        (field) => field.sourceFieldName === mapping.sourceFieldName
      );

      if (field) {
        return {
          ...mapping,
          fieldProperties: field.fieldProperties,
        };
      }
    });

    return result;
  }

  useEffect(() => {
    if (!integration) {
      getIntegration();
      getAccountSettings();
    }
  }, []);

  useEffect(() => {
    if (integration && !saved) {
      overwriteEnableAutoSync(integration.auto_sharing_active);
      setMinimumLeadScore(integration.auto_sharing_min_score);
      setIntegrationType(integration.integration_type);
      setSelectedOrgList(integration.org_list_id);
      setSelectedPersonsList(integration.persons_list_id);
      setPersonFieldMapping(
        initStateWithFieldMappings(
          inprofilerPersonFields,
          integration.field_mappings,
          "persons"
        )
      );
      setOrgFieldMapping(
        initStateWithFieldMappings(
          inprofilerOrganisationFields,
          integration.field_mappings,
          "organizations"
        )
      );
      setDefaultFields(integration.field_mappings.defaults);
      overwriteImportOrgs(integration.import_orgs);
      overwriteImportPersons(integration.import_persons);

      setInitiated(true);
    }
  }, [integration]);

  const saveChanges = () => {
    setError(null);
    setAwaitingResponse(true);

    const removeFieldProperties = (mapping) =>
      mapping
        .filter((field) => field.targetField !== undefined)
        .map(({ fieldProperties, ...rest }) => rest);

    const personMapping = removeFieldProperties(personFieldMapping);
    const orgMapping = removeFieldProperties(orgFieldMapping);

    const fieldMappingBody = {
      persons: personMapping,
      organizations: orgMapping,
      defaults: defaultFields,
    };

    updateAffinityIntegration({
      id: integration.id,
      importPersons,
      importOrgs,
      personsListId: selectedPersonsList ? selectedPersonsList.id : null,
      orgListId: selectedOrgList ? selectedOrgList.id : null,
      fieldMappings: fieldMappingBody,
      enableAutoSync,
      minimumLeadScore,
      integrationType,
    })
      .then((data) => {
        setIntegration(data);
        setSaved(true);
        setAwaitingResponse(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setAwaitingResponse(false);
      });
  };

  const toggleIntegrationActivation = () => {
    toggleAffinityIntegration(integration.id)
      .then((data) => {
        setIntegration(data);
        if (!data.active) {
          getAccountSettings();
          toggleShowDeactivationModal();
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const deleteIntegration = () => {
    deleteAffinityIntegration(integration.id)
      .then((data) => {
        getAccountSettings();
        navigate("/app/settings/integrations");
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  if (loading || !integration || !initiated) {
    return <div></div>;
  }

  if (integrationDoesNotExist) {
    return <IntegrationDoesNotExist />;
  }

  return (
    <>
      <Header
        integration={integration}
        toggleShowDeactivationModal={toggleShowDeactivationModal}
        toggleShowDeleteModal={toggleShowDeleteModal}
        toggleIntegrationActivation={toggleIntegrationActivation}
        accountUser={accountUser}
      />
      <div className="divide-y">
        {/* API Key */}
        <ConnectAccount
          integration={integration}
          getIntegration={getIntegration}
          accountUser={accountUser}
        />
        {/* Lead Sync */}
        <AutoSyncSetting
          enableAutoSync={enableAutoSync}
          toggleEnableAutoSync={toggleEnableAutoSync}
          minimumLeadScore={minimumLeadScore}
          setMinimumLeadScore={setMinimumLeadScore}
          integrationId={integration && integration.id}
          accountUser={accountUser}
        />
        {/* Integration Type */}
        <IntegrationTypeSetting
          integrationType={integrationType}
          setIntegrationType={setIntegrationType}
          accountUser={accountUser}
        />
        {/* Lists */}
        <AffinityListsSetting
          integrationId={integration && integration.id}
          selectedOrgList={selectedOrgList}
          setSelectedOrgList={setSelectedOrgList}
          selectedPersonsList={selectedPersonsList}
          setSelectedPersonsList={setSelectedPersonsList}
          setLoading={setLoading}
          setError={setError}
          preselected
          accountUser={accountUser}
          importPersons={importPersons}
          toggleImportPersons={toggleImportPersons}
          overwriteImportPersons={overwriteImportPersons}
          importOrgs={importOrgs}
          toggleImportOrgs={toggleImportOrgs}
          overwriteImportOrgs={overwriteImportOrgs}
        />
        {/* Person Mapping */}
        <PersonalFieldsMapping
          setLoading={setLoading}
          setError={setError}
          personFieldMapping={personFieldMapping}
          setPersonFieldMapping={setPersonFieldMapping}
          additionalPersonFieldOptions={additionalPersonFieldOptions}
          accountUser={accountUser}
        />
        {/* Company Mapping */}
        <CompanyFieldsMapping
          orgFieldMapping={orgFieldMapping}
          setOrgFieldMapping={setOrgFieldMapping}
          additionalOrgFieldOptions={additionalOrgFieldOptions}
          setLoading={setLoading}
          setError={setError}
          accountUser={accountUser}
        />
        {/* Default Fields */}
        <DefaultFieldsSetting
          defaultFields={defaultFields}
          setDefaultFields={setDefaultFields}
          accountUser={accountUser}
          setError={setError}
        />
      </div>
      <FooterBar
        error={error}
        saved={saved}
        saveChanges={saveChanges}
        awaitingResponse={awaitingResponse}
        accountUser={accountUser}
      />
      {/* Deactivate */}
      <ConfirmDeleteModal
        open={showDeactivationModal}
        toggleOpen={toggleShowDeactivationModal}
        heading="Deactivate Affinity Integration?"
        text="No leads will be synced while your integration is deactivated but the integration and its settings won't be deleted."
        confirmCopy="Deactivate"
        handleConfirm={toggleIntegrationActivation}
      />
      {/* Delete */}
      <ConfirmDeleteModal
        open={showDeleteModal}
        toggleOpen={toggleShowDeleteModal}
        heading="Delete Affinity Integration?"
        text="The integration will be permanently deleted and your leads will be no longer synced to Affinity. Consider deactivating your Integration instead, in order not to lose your preferences."
        handleConfirm={deleteIntegration}
      />
    </>
  );
}
