import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import Logo from "../../img/logo.png";
import {
  ChevronRightIcon,
  EnvelopeIcon,
  XMarkIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  useGetUserAccountsAndInvites,
  useAcceptInvite,
  useRejectInvite,
  useSelectAccount,
} from "../../api/UserAPI";
import { getInitials } from "../../helpers";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function SelectAccount() {
  const { logoutUser } = useContext(AuthContext);
  const { setCurrentAccountId } = useContext(SubscriptionContext);
  const [accounts, setAccounts] = useState();
  const [invites, setInvites] = useState();
  const [invitedBy, setInvitedBy] = useState();
  const [error, setError] = useState();
  const getUserAccountsAndInvites = useGetUserAccountsAndInvites();
  const acceptInvite = useAcceptInvite();
  const rejectInvite = useRejectInvite();
  const selectAccount = useSelectAccount();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const inviterAccount = urlParams.get("invitedBy");
    setInvitedBy(inviterAccount);
  }, [location]);

  useEffect(() => {
    if (!accounts && !invites) {
      setError(null);
      getUserAccountsAndInvites()
        .then((data) => {
          setAccounts(data.accounts);
          setInvites(data.invites);
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setError(err.response.data.message);
        });
    }
  }, []);

  const handleAcceptInvite = (token, accountName) => {
    setError(null);
    acceptInvite(token)
      .then((res) => {
        localStorage.removeItem("subscription");
        localStorage.removeItem("account");
        localStorage.removeItem("accountUser");
        localStorage.removeItem("userActivity");
        navigate(`/app/accounts/join?account=${accountName}`);
      })
      .catch((err) => setError(err.response.data.message));
  };

  const handleRejectInvite = (token) => {
    setError(null);
    rejectInvite(token)
      .then((res) => {
        setInvites((previousInvites) =>
          previousInvites.filter((invite) => invite.token !== token)
        );
      })
      .catch((err) => setError(err.response.data.message));
  };

  const handleSelectAccount = (accountId) => {
    setError(null);
    selectAccount(accountId)
      .then((res) => {
        localStorage.removeItem("subscription");
        localStorage.removeItem("account");
        localStorage.removeItem("accountUser");
        localStorage.removeItem("userActivity");
        setCurrentAccountId(accountId);
        navigate("/app");
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.message);
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-top lg:justify-center py-12 sm:px-6 lg:px-8 bg-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex gap-3 items-center w-full justify-center mb-10">
            <img className="h-12 w-auto" src={Logo} alt="InProfiler" />
            <span className="text-3xl font-bold text-indigo-600">
              InProfiler
            </span>
          </div>
          <h2 className="text-center text-3xl font-bold leading-9 tracking-tight text-gray-900 ">
            {invitedBy ? `Join ${invitedBy}'s account` : "Choose an account"}
          </h2>
        </div>
        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[680px]">
          <div className="max-h-[400px] overflow-scroll px-5 py-3">
            {error && (
              <div className="rounded-lg bg-red-100 p-5">
                <p className="text-red-800">{error}</p>
              </div>
            )}
            <Link
              to="/app/accounts/create?action=addAccount"
              className="rounded-lg bg-indigo-50 p-5 flex justify-between items-center hover:bg-indigo-100 cursor-pointer"
            >
              <p className="text-indigo-600 font-semibold">
                Create new account
              </p>
              <PlusIcon className="h-5 w-5 text-indigo-600 font-semibold" />
            </Link>
            {invites &&
              invites.map((invite) => (
                <div
                  className="flex my-4 p-5 border rounded-lg shadow-sm items-center justify-between bg-white"
                  key={invite.id}
                >
                  <div className="flex gap-4 items-center">
                    <span className="rounded-full bg-gray-100 h-10 w-10 p-3 flex justify-center items-center">
                      <EnvelopeIcon />
                    </span>
                    <div>
                      <h3 className="text-md font-semibold text-gray-900">
                        {invite.account}
                      </h3>
                      <p className="text-gray-500 text-sm">Invitation</p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleRejectInvite(invite.token)}
                      className="rounded-lg bg-gray-400 p-2 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      onClick={() =>
                        handleAcceptInvite(invite.token, invite.account)
                      }
                      className="flex justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              ))}
            {accounts &&
              accounts.map((account) => (
                <div
                  className="flex my-4 p-5 border rounded-lg shadow-sm items-center justify-between bg-white hover:bg-gray-50 cursor-pointer"
                  key={account.id}
                  onClick={() => handleSelectAccount(account.id)}
                >
                  <div className="flex gap-4 items-center">
                    <span className="rounded-full bg-gray-100 h-10 w-10 p-3 flex justify-center items-center">
                      {getInitials(account.name)}
                    </span>
                    <h3 className="text-md font-semibold text-gray-900">
                      {account.name}
                    </h3>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                </div>
              ))}
          </div>
        </div>
        <div className="absolute bottom-0 right-0 mr-8 mb-8 mx-auto">
          <span
            className="font-semibold text-gray-500 hover:text-gray-600 cursor-pointer"
            onClick={logoutUser}
          >
            Logout
          </span>
        </div>
      </div>
    </>
  );
}
