import React, { useState, useEffect } from "react";
import StyledSelect from "../../../../../UtilityComponents/StyledSelect";

export default function MappingItem({
  field,
  affinityFields,
  index,
  handleFieldset,
}) {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (affinityFields) {
      let preFilledField = null;
      if (field.targetField) {
        preFilledField = affinityFields.find(
          (item) => item.id === field.targetField.id
        );
        // preFilledField = field.targetField;
      } else if (field.fieldProperties.suggestedFieldName) {
        preFilledField = affinityFields.find(
          (item) => item.name === field.fieldProperties.suggestedFieldName
        );
      }
      if (preFilledField) {
        setSelected(preFilledField);
      }
    }
  }, [affinityFields]);

  useEffect(() => {
    if (selected) {
      handleFieldset(index, selected);
    }
  }, [selected]);

  return (
    <div className="w-1/2">
      <StyledSelect
        choices={affinityFields}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
}
