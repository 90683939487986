import React from "react";
import { getInitials } from "../../../helpers";
import PersonSummary from "./PersonSummary";

export default function RequestData({ lead, leadType }) {
  return (
    <div>
      {leadType === "shared" && (
        <div className="p-4 bg-gray-50">
          <p className="text-sm text-gray-500">Lead Owner: {lead.owner_name}</p>
        </div>
      )}
      <div className="px-8 py-8 flex flex-col space-y-5 md:flex-row md:justify-between md:items-center">
        <div className="flex gap-4 items-start md::items-center">
          {lead.picture ? (
            <img
              className="h-14 w-14 flex-none rounded-full bg-gray-50 shrink"
              src={lead.picture}
              alt=""
            />
          ) : (
            <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-400">
              <span className="text-lg font-medium leading-none text-white">
                {getInitials(lead.name)}
              </span>
            </span>
          )}
          <div>
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              {lead.name}
            </h3>
            <p className="mt-1 max-w-md text-sm leading-6 text-gray-500">
              {lead.headline}
            </p>
          </div>
        </div>
        <a
          href={`https://linkedin.com/in/${lead.public_identifier}/`}
          target="_blank"
          rel="noreferrer"
          className="shrink-0 flex justify-center rounded-md bg-indigo-600 ml-5 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Visit Profile
        </a>
      </div>
      <div className="border-t border-gray-100 py-4 px-8">
        <dl className="divide-y divide-gray-100">
          {lead.custom_message && (
            <div className="px-0 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Lead Message
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {lead.custom_message}
              </dd>
            </div>
          )}
          {lead.person_occupation && (
            <div className="px-0 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Occupation
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {lead.person_occupation}
              </dd>
            </div>
          )}
          {lead.person_summary && <PersonSummary text={lead.person_summary} />}
          {lead.person_country && (
            <div className="px-0 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Country
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {lead.person_country}
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}
