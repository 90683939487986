import { Link } from "react-router-dom";

const navigation = [
  { name: "Contact", to: "/contact" },
  { name: "Privacy", to: "/privacy" },
];

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.to}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="font-base text-sm">{item.name}</span>
            </Link>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024 InProfiler. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
