import React from "react";
import useToggleState from "../../../hooks/useToggleState";

export default function PersonSummary({ text }) {
  const [showMore, toggleShowMore] = useToggleState(false);
  return (
    <div className="px-0 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium leading-6 text-gray-900">
        Profile Summary
      </dt>
      {text.length < 350 || showMore ? (
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {text}{" "}
          {showMore && (
            <span
              onClick={toggleShowMore}
              className="text-blue-500 font-semibold hover:text-blue-600 cursor-pointer"
            >
              Show less
            </span>
          )}
        </dd>
      ) : (
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {`${text.slice(0, 350)} ...`}{" "}
          <span
            onClick={toggleShowMore}
            className="text-blue-500 font-semibold hover:text-blue-600 cursor-pointer"
          >
            Show more
          </span>
        </dd>
      )}
    </div>
  );
}
